import {
  AddressAccessorial,
  AccessorialsType,
  AddressBook,
  AddressBookModel,
  addressBookStore,
  editAddress,
  fetchAddressBookById,
  postAddress,
  setAddressBookStore,
} from '@store/customers/customerDetails/addressBookTab';
import AddressAutoComplete from '@components/Autocomplete/AddressAutoComplete';
import { Accessor, createSignal, For, onMount, Setter, Show } from 'solid-js';
import { createForm } from '@felte/solid';
import { validator } from '@felte/validator-yup';
import { get, omit, sortBy } from 'lodash';
import { Box, Grid } from '@suid/material';
import {
  Button,
  Card,
  Notification,
  TimePicker,
  ToastType,
  Typography,
} from '@components';
import {
  CheckboxInput,
  PhoneInput,
  SelectField,
  TextInput,
} from '@components/forms';
import { DateTime } from 'luxon';
import { useParams } from '@solidjs/router';
import { SelectChangeEvent } from '@suid/material/Select';
import { AgGridSolidRef } from '@ag-grid-community/solid';
import { handleToast, printLog } from '@utils/utils';
import { ProductFormSkeleton } from '@components/Product/components';
import { fetchAccessorials } from '@store/ltl/actions';
import { accessorialStore } from '@store/ltl/store';
import { createStore, produce } from 'solid-js/store';
import { FABInput } from '@components/FABInput';

import { addOrEditAddressStyle as classes } from './classes';
import { addressBookSchema } from './validation';
import { countryList } from '../constants';
import { defaultOriginItem } from './AddressBookGrid';

export type IntialAddressFormValues = {
  name: string;
  description: string | null;
  address1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  countryCode: string;
  contactName: string | null;
  contactPhone: string | null;
  openTimeFrom: string;
  openTimeTo: string;
  specialInstructions: string | null;
  drivingDirections: string | null;
  internalNote: string | null;
  isDefaultAddress: boolean;
  customerId: number;
};

export type AddressDetails = {
  address1?: string;
  city?: string;
  county?: string;
  state?: string;
  zipCode?: string;
  country?: 'US' | 'CA' | 'MX';
};

type AddOrEditAddressBookProps = {
  modalId?: string;
  handleClose: () => void;
  edit: Accessor<boolean>;
  setEdit: Setter<boolean>;
  selectedRow: Accessor<AddressBookModel | null>;
  customerId?: number;
  gridRef: Accessor<AgGridSolidRef | null>;
  setSelectedRow: Setter<AddressBookModel | null>;
  triggerAddUpdateFn: Accessor<'add' | 'update' | 'delete' | 'none'>;
  setTriggerAddUpdateFn: Setter<'add' | 'update' | 'delete' | 'none'>;
};

const AddOrEditAddressBook = (props: AddOrEditAddressBookProps) => {
  const params = useParams();
  const [accessorials, setAccessorials] = createStore<AccessorialsType>({
    pickup: [],
    delivery: [],
  });
  const [isSaveAndNew, setIsSaveAndNew] = createSignal(false);
  const [checked, setChecked] = createSignal('');
  const [isLoading, setIsLoading] = createSignal(false);
  const [defaultOriginRow, setDefaultOriginRow] =
    createSignal<AddressBookModel | null>(null);

  const {
    form,
    errors,
    setIsDirty,
    isSubmitting,
    setIsSubmitting,
    setFields,
    setData,
    reset,
    data,
  } = createForm<IntialAddressFormValues>({
    initialValues: {
      name: '',
      description: null,
      address1: '',
      addressLine2: '',
      city: '',
      state: '',
      zip: '',
      countryCode: '',
      contactName: null,
      contactPhone: null,
      openTimeFrom: '',
      openTimeTo: '',
      specialInstructions: null,
      drivingDirections: null,
      internalNote: null,
      isDefaultAddress: false,
      customerId: Boolean(props.customerId)
        ? Number(props.customerId)
        : Number(params.id),
    },
    extend: validator({ schema: addressBookSchema }),
    onSubmit: async (values) => {
      const cb = (message: string) => {
        handleToast(ToastType.Success, message);
        reset();
        setAddressBookStore('addOrEditErrorMessage', '');
      };
      setIsSubmitting(true);
      try {
        const commonPayload = {
          ...values,
          addressLine1: values.address1,
        };
        const id = props.selectedRow()?.id;
        if (props.edit() && Boolean(id)) {
          const payload = {
            ...commonPayload,
            id,
            createdOn: props.selectedRow()?.createdOn,
            openTimeFrom: formatTimeToPayload(commonPayload.openTimeFrom),
            openTimeTo: formatTimeToPayload(commonPayload.openTimeTo),
            customerAddressAccessorials: [
              ...getCheckedAccessorials(accessorials.pickup),
              ...getCheckedAccessorials(accessorials.delivery),
            ] as AddressAccessorial[],
          };
          const res = await editAddress({ ...payload, cb } as AddressBook);
          if (res) {
            props.setSelectedRow(res);
            props.setTriggerAddUpdateFn('update');
            if (Boolean(isSaveAndNew())) {
              props.setEdit(false);
              props.setSelectedRow(null);
              setChecked('');
              initialSetAccessorials();
              reset();
            } else {
              props.handleClose();
            }
          }
        } else {
          const addPayload = {
            ...commonPayload,
            openTimeFrom: commonPayload.openTimeFrom
              ? formatTimeToPayload(commonPayload.openTimeFrom)
              : null,
            openTimeTo: commonPayload.openTimeTo
              ? formatTimeToPayload(commonPayload.openTimeTo)
              : null,
            customerAddressAccessorials: [
              ...getCheckedAccessorials(accessorials.pickup),
              ...getCheckedAccessorials(accessorials.delivery),
            ] as AddressAccessorial[],
          };
          const res = await postAddress({ ...addPayload, cb } as AddressBook);
          if (res) {
            props.setSelectedRow(res.value);
            props.setTriggerAddUpdateFn('add');
            if (Boolean(isSaveAndNew())) {
              props.setEdit(false);
              props.setSelectedRow(null);
              setChecked('');
              initialSetAccessorials();
              reset();
            } else props.handleClose();
          }
        }
      } catch (error) {
        const errorMessage =
          error instanceof Error ? error.message : String(error);
        throw new Error(`Failed to Add Address: ${errorMessage}`);
      } finally {
        setIsSubmitting(false);
      }
    },
    onError: (errors) => {
      printLog(errors);
    },
  });

  const initialSetAccessorials = () => {
    setAccessorials({
      pickup: accessorials.pickup.map((x) => ({
        ...x,
        checked: false,
        operationType: 'None',
      })),
      delivery: accessorials.delivery.map((x) => ({
        ...x,
        checked: false,
        operationType: 'None',
      })),
    });
  };

  const formatTimeToPayload = (timeVal: string) => {
    return DateTime.fromISO(timeVal).toFormat('HH:mm:ss');
  };

  const formatTime = (timeVal: string) => {
    if (!timeVal) return '';
    const dateString = DateTime.now().toISODate();
    const res = DateTime.fromISO(`${dateString}T${timeVal}`).toISO();
    return res;
  };
  const getAddressBookDetailsById = async (id: string) => {
    setIsLoading(true);
    const address = await fetchAddressBookById(id);
    if (Boolean(address)) {
      setData('name', get(address, 'name'));
      setData('description', get(address, 'description'));
      setData('address1', get(address, 'addressLine1'));
      setData('addressLine2', get(address, 'addressLine2') as string);
      setData('city', get(address, 'city'));
      setData('state', get(address, 'state'));
      setData('zip', get(address, 'zip'));
      setData('countryCode', get(address, 'countryCode'));
      setData('contactName', get(address, 'contactName') as string);
      setData('contactPhone', get(address, 'contactPhone'));
      setData(
        'openTimeFrom',
        formatTime(get(address, 'openTimeFrom', '')) ?? '',
      );
      setData('openTimeTo', formatTime(get(address, 'openTimeTo', '')) ?? '');
      setData('internalNote', get(address, 'internalNote') as string);
      setData(
        'specialInstructions',
        get(address, 'specialInstructions') as string,
      );
      setData('drivingDirections', get(address, 'drivingDirections') as string);
      setData('isDefaultAddress', get(address, 'isDefaultAddress') as boolean);
      const pickupAccessorials = address.customerAddressAccessorials.filter(
        (x) => x.accessorialType === 1,
      );

      const deliveryAccessorials = address.customerAddressAccessorials.filter(
        (x) => x.accessorialType === 2,
      );
      getAccessorialsInfo('pickup', pickupAccessorials);
      getAccessorialsInfo('delivery', deliveryAccessorials);
    }
    setIsLoading(false);
  };

  function getAccessorialsInfo(
    type: string,
    accessorialList?: AddressAccessorial[],
  ) {
    const pickupVals: AddressAccessorial[] = [];
    const deliveryVals: AddressAccessorial[] = [];

    accessorialStore.items.forEach((x) => {
      const item = {
        checked: false,
        accessorialId: x.id,
        accessorial: x,
        operationType: 'None',
      };

      if (Boolean(x.isPickup)) {
        pickupVals.push({ ...item, accessorialType: 1 });
      }

      if (Boolean(x.isDelivery)) {
        deliveryVals.push({ ...item, accessorialType: 2 });
      }
    });
    if (accessorialList) {
      let updatedAccessorials: AddressAccessorial[] = [];
      switch (type) {
        case 'pickup':
          updatedAccessorials = accessorials.pickup.map(
            (x: AddressAccessorial) => {
              const matchingAccessorial = accessorialList.find(
                (a) => a.accessorialId === x.accessorialId,
              );
              if (matchingAccessorial) {
                return {
                  ...x,
                  ...matchingAccessorial,
                  checked: true,
                  operationType: 'None',
                };
              }
              return x;
            },
          );
          setAccessorials(
            'pickup',
            sortBy(updatedAccessorials, 'accessorial.name'),
          );
          break;
        case 'delivery':
          updatedAccessorials = accessorials.delivery.map(
            (x: AddressAccessorial) => {
              const matchingAccessorial = accessorialList.find(
                (a) => a.accessorialId === x.accessorialId,
              );
              if (matchingAccessorial) {
                return {
                  ...x,
                  ...matchingAccessorial,
                  checked: true,
                  operationType: 'None',
                };
              }
              return x;
            },
          );
          setAccessorials(
            'delivery',
            sortBy(updatedAccessorials, 'accessorial.name'),
          );
          break;
        default:
          break;
      }
    } else {
      setAccessorials('pickup', sortBy(pickupVals, 'accessorial.name'));
      setAccessorials('delivery', sortBy(deliveryVals, 'accessorial.name'));
    }
  }

  const getCheckedAccessorials = (accessorials: AddressAccessorial[]) => {
    return accessorials
      .filter((x) => x.checked || x.operationType === 'Delete')
      .map((x) => omit(x, 'checked'));
  };
  onMount(async () => {
    const id = props.selectedRow()?.id;
    if (accessorialStore.items.length === 0) {
      await fetchAccessorials();
    }
    getAccessorialsInfo('Default');
    if (props.edit() && Boolean(id)) {
      await getAddressBookDetailsById(id!);
    }
  });

  const setAddressFields = (
    field: keyof IntialAddressFormValues,
    value: string | boolean,
  ) => {
    setIsDirty(true);
    setFields(field, value);
  };

  const handleGooglePlacesSelect = (place: AddressDetails) => {
    const {
      address1 = '',
      city = '',
      country = '',
      state = '',
      zipCode = '',
    } = place;
    setAddressFields('address1', address1);
    setAddressFields('city', city);
    setAddressFields('state', state.slice(0, 2));
    setAddressFields('zip', zipCode);

    const countryCodes = {
      US: 'USA',
      CA: 'CAN',
      MX: 'MEX',
    };
    setAddressFields(
      'countryCode',
      countryCodes[country as 'US' | 'CA' | 'MX'],
    );
  };

  const setAddressDefaultText = () => {
    const defaultOriginItemFromProps = defaultOriginItem().find(
      (item: AddressBookModel) => item.isDefaultAddress === true,
    );
    setDefaultOriginRow(defaultOriginItemFromProps as AddressBookModel);
    const selectedAddress = props.selectedRow();
    if (!selectedAddress) {
      if (checked() === 'Yes') {
        const message = (
          <Typography variant="body2" class="text-red-500">
            {Boolean(defaultOriginRow())
              ? `Clicking save will change the default origin from ${defaultOriginRow()
                  ?.addressLine1} ${defaultOriginRow()
                  ?.city}, ${defaultOriginRow()?.state} ${defaultOriginRow()
                  ?.zip} to ${data().address1} ${data().city}, ${
                  data().state
                } ${data().zip}`
              : `Clicking Save will default origin to ${data().address1} ${
                  data().city
                }, ${data().state} ${data().zip}`}
          </Typography>
        );
        return message;
      }
      return null;
    }

    if (checked() === 'No' && selectedAddress.isDefaultAddress) {
      return (
        <Typography variant="body2" class="text-red-500">
          Clicking save will remove {data().address1} {data().city},{' '}
          {data().state} {data().zip} as the default origin
        </Typography>
      );
    } else if (checked() === 'Yes' && !selectedAddress.isDefaultAddress) {
      return (
        <Typography variant="body2" class="text-red-500">
          {defaultOriginRow()
            ? `Clicking save will change the default origin from ${defaultOriginRow()
                ?.addressLine1} ${defaultOriginRow()
                ?.city}, ${defaultOriginRow()?.state} ${defaultOriginRow()
                ?.zip} to ${data().address1} ${data().city}, ${data().state} ${
                data().zip
              }`
            : `Clicking Save will default origin to ${data().address1} ${
                data().city
              }, ${data().state} ${data().zip}`}
        </Typography>
      );
    }

    return null;
  };

  const handleAccessorialChange = (
    type: 'pickup' | 'delivery' | 'shipment',
    i: Accessor<number>,
  ) => {
    if (type === 'pickup' || type === 'delivery') {
      setAccessorials(
        type,
        produce((items) => {
          items.forEach((item, index) => {
            if (index === i()) {
              item.checked = !item.checked;
              switch (item.operationType) {
                case 'None':
                  if (item.checked) {
                    item.operationType = 'Insert';
                  } else {
                    item.operationType = 'Delete';
                  }
                  break;
                case 'Add':
                  item.operationType = 'None';
                  break;
                case 'Delete':
                  item.operationType = 'None';
                  break;
                default:
              }
            }
          });
        }),
      );
    }
  };

  return (
    <form ref={form} class={classes.rowFormGrid}>
      <Show when={!isLoading()} fallback={<ProductFormSkeleton />}>
        <Box sx={{ flexGrow: 1 }}>
          <Show when={addressBookStore.addOrEditErrorMessage}>
            <Grid container item xs={12} spacing={1} class={classes.errorBox}>
              <Grid item xs={12}>
                <Notification
                  type="error"
                  text={addressBookStore.addOrEditErrorMessage}
                />
              </Grid>
            </Grid>
          </Show>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="body1">Location Details</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid item container spacing={1} rowSpacing={1}>
                <Grid item xs={12}>
                  <TextInput
                    name="name"
                    label="Location Name"
                    error={errors().name}
                    value={data().name}
                    onChange={(itm: string) => setAddressFields('name', itm)}
                    maxLength={150}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    name="description"
                    label="Location Description"
                    error={errors().description}
                    value={data().description ?? ''}
                    onChange={(itm: string) =>
                      setAddressFields('description', itm)
                    }
                    maxLength={100}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1">Address</Typography>
                </Grid>
                <Grid item xs={12}>
                  <AddressAutoComplete
                    label="Address1"
                    placeholder="Enter Address"
                    value={data().address1}
                    onChange={(itm) => {
                      setAddressFields('address1', itm);
                    }}
                    onItemSelect={(item) => {
                      handleGooglePlacesSelect(item);
                    }}
                    zIndex="9999"
                    error={errors().address1}
                    maxLength={100}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    label="Address2"
                    placeholder="Address line 2"
                    error={errors().addressLine2}
                    value={data().addressLine2}
                    onChange={(itm: string) =>
                      setAddressFields('addressLine2', itm)
                    }
                    maxLength={100}
                  />
                </Grid>
                <Grid item container spacing={1}>
                  <Grid item xs={4}>
                    <TextInput
                      label="City"
                      placeholder="City"
                      value={data().city}
                      onChange={(itm: string | number | Event | null) =>
                        setAddressFields('city', itm as string)
                      }
                      error={errors().city}
                      maxLength={50}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextInput
                      label="State"
                      placeholder="State"
                      value={data().state}
                      onChange={(itm: string) =>
                        setAddressFields('state', itm.slice(0, 2))
                      }
                      error={errors().state}
                      maxLength={2}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextInput
                      label="Zip"
                      placeholder="Pincode"
                      value={data().zip}
                      onChange={(value: string | number | Event | null) =>
                        setAddressFields('zip', value as string)
                      }
                      error={errors().zip}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SelectField
                      label="USA/CAN/MEX"
                      placeholder="Select Country"
                      menuItems={countryList}
                      value={data().countryCode}
                      onChange={(e: SelectChangeEvent) =>
                        setAddressFields('countryCode', e.target.value)
                      }
                      error={errors().countryCode}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1">Contact</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    label="Name"
                    placeholder="Name"
                    name="contactName"
                    value={data().contactName as string}
                    onChange={(itm: string) =>
                      setAddressFields('contactName', itm)
                    }
                    error={errors().contactName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PhoneInput
                    label="Phone"
                    placeholder="Phone Number"
                    value={data().contactPhone as string}
                    onChange={(value) =>
                      setAddressFields('contactPhone', value)
                    }
                    error={errors().contactPhone}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1">Hours</Typography>
                </Grid>
                <Grid item xs={6}>
                  <TimePicker
                    label="From"
                    value={data().openTimeFrom}
                    onChange={(newTime) =>
                      setAddressFields('openTimeFrom', newTime)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TimePicker
                    label="To"
                    value={data().openTimeTo}
                    onChange={(newTime) =>
                      setAddressFields('openTimeTo', newTime)
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} rowSpacing={1}>
              <Grid item container spacing={1}>
                <Grid item xs={12}>
                  <TextInput
                    multiline
                    rows={5}
                    label="Location Instructions"
                    name="specialInstructions"
                    placeholder="Enter Location Instructions"
                    value={data().specialInstructions ?? ''}
                    onChange={(itm: string) =>
                      setAddressFields('specialInstructions', itm)
                    }
                    error={errors().specialInstructions}
                    maxLength={2000}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Card startTitle="Accessorials" expanded={false}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Box class={'flex flex-col gap-1 '}>
                          <Typography variant="body1">Pickup</Typography>
                          <For each={accessorials.pickup}>
                            {(pickup, i) => {
                              return (
                                <Grid item>
                                  <FABInput
                                    label={pickup.accessorial.name!}
                                    checked={pickup.checked}
                                    onClick={() =>
                                      handleAccessorialChange('pickup', i)
                                    }
                                  />
                                </Grid>
                              );
                            }}
                          </For>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box class={'flex flex-col gap-1 '}>
                          <Typography variant="body1">Delivery</Typography>
                          <For each={accessorials.delivery}>
                            {(delivery, i) => {
                              return (
                                <Grid item>
                                  <FABInput
                                    label={delivery.accessorial.name!}
                                    checked={delivery.checked}
                                    onClick={() =>
                                      handleAccessorialChange('delivery', i)
                                    }
                                  />
                                </Grid>
                              );
                            }}
                          </For>
                        </Box>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    multiline
                    rows={5}
                    label="Driving Directions"
                    name="drivingDirections"
                    placeholder="Enter Driving Directions"
                    value={data().drivingDirections ?? ''}
                    onChange={(itm: string) =>
                      setAddressFields('drivingDirections', itm)
                    }
                    error={errors().drivingDirections}
                    maxLength={2000}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    multiline
                    rows={5}
                    label="Internal Notes"
                    name="internalNote"
                    placeholder="Enter Internal Notes"
                    value={data().internalNote ?? ''}
                    onChange={(itm: string) =>
                      setAddressFields('internalNote', itm)
                    }
                    error={errors().internalNote}
                    maxLength={2000}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CheckboxInput
                    label="Set as a Default Origin"
                    name="isDefaultAddress"
                    checked={data().isDefaultAddress}
                    onChange={(value) => {
                      setAddressFields('isDefaultAddress', value as boolean);
                      value ? setChecked('Yes') : setChecked('No');
                    }}
                  />
                  <Grid item xs={12}>
                    {setAddressDefaultText()}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              gap={2}
              displayRaw="flex"
              alignItems="center"
              justifyContent="end"
            >
              <Button
                isLoading={isSaveAndNew() && isSubmitting()}
                label="Save And Add New"
                variant="contained"
                type="submit"
                onClick={() => {
                  setIsSaveAndNew(true);
                }}
                sx={{ background: '#468DB5', color: '#FFF' }}
                disabled={isSubmitting()}
              />
              <Button
                isLoading={!isSaveAndNew() && isSubmitting()}
                label="Save"
                type="submit"
                variant="contained"
                onClick={() => {
                  setIsSaveAndNew(false);
                }}
                disabled={isSubmitting()}
              />
            </Grid>
          </Grid>
        </Box>
      </Show>
    </form>
  );
};

export default AddOrEditAddressBook;
