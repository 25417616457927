﻿import {
  getFalveyPricingQuoteData,
  getLTLAccessorials,
  getQuotes,
  getSavedQuotes,
} from '@store/ltl/services';
import { produce } from 'solid-js/store';
import {
  AccessorialState,
  FalveyInsuranceQuote,
  FalveyQuotesPayload,
  LTLQuotingResultState,
  LTLRateQuoteRequestState,
  SavedQuotesState,
} from '@store/ltl/types';
import {
  setAccessorialStore,
  setLtlQuoteState,
  setLTLQuotingResultStore,
  setLTLRateQuoteRequest,
  setSavedQuoteStore,
} from '@store/ltl/store';
import { LTLRateQuoteRequest, LTLRateQuoteResponse } from '@store/ltl/v3types';

export const fetchAccessorials = async () => {
  try {
    const resp = await getLTLAccessorials();
    setAccessorialStore(
      produce((draft: AccessorialState) => {
        draft.items = resp;
        draft.isError = false;
        draft.isLoading = false;
      }),
    );
  } catch (e) {
    setAccessorialStore(
      produce((draft: AccessorialState) => {
        draft.isError = true;
        draft.errorMessage = 'Failed loading LTL Accessorials';
        draft.isLoading = false;
      }),
    );
  }
};

export const fetchSavedQuotes = async () => {
  try {
    setSavedQuoteStore('isLoading', true);
    const resp = await getSavedQuotes();
    setSavedQuoteStore(
      produce((draft: SavedQuotesState) => {
        draft.items = resp;
        draft.isLoading = false;
      }),
    );
  } catch (e) {
    setSavedQuoteStore(
      produce((draft: SavedQuotesState) => {
        draft.isError = true;
        draft.errorMessage = 'Failed loading saved quotes';
        draft.isLoading = false;
      }),
    );
  } finally {
    setSavedQuoteStore('isLoading', false);
  }
};

export const fetchQuotes = async (request: LTLRateQuoteRequest) => {
  setLTLQuotingResultStore('isLoading', true);
  const resp = await getQuotes(request);
  if (Boolean(resp)) {
    setLTLQuotingResultStore(
      produce((draft: LTLQuotingResultState) => {
        draft.items = resp as LTLRateQuoteResponse[];
        draft.isLoading = false;
        draft.isError = false;
      }),
    );
    setLTLRateQuoteRequest(
      produce((draft: LTLRateQuoteRequestState) => {
        draft.items = request;
        draft.isLoading = false;
      }),
    );
  } else {
    setLTLQuotingResultStore(
      produce((draft: LTLQuotingResultState) => {
        draft.isLoading = false;
        draft.items = [];
        draft.isError = true;
      }),
    );
  }
};

export const getLTLFalveyPricingQuotes = async (
  partialPayload: Partial<FalveyQuotesPayload>,
  hideToastr?: boolean,
) => {
  setLtlQuoteState('isLoading', true);
  const payload: Partial<FalveyQuotesPayload> = {
    shipmentTypeID: '139015',
    currency: 'USD',
    shippingMerchandiseDescription: '',
    ...partialPayload,
  };
  try {
    const resp = await getFalveyPricingQuoteData(
      payload as FalveyQuotesPayload,
      hideToastr,
    );
    setLtlQuoteState(
      produce((draft) => {
        draft.falveyPricingQuote = resp as FalveyInsuranceQuote;
        draft.isError = false;
        draft.errorMessage = '';
      }),
    );
  } catch (error) {
    setLtlQuoteState(
      produce((draft) => {
        draft.isError = true;
        draft.falveyPricingQuote = undefined;
        draft.errorMessage = 'Failed to get Falvey pricing quotes';
      }),
    );
  } finally {
    setLtlQuoteState('isLoading', false);
  }
};

export const clearLtlQuoteResults = () => {
  setLTLQuotingResultStore(
    produce((draft: LTLQuotingResultState) => {
      draft.items = [];
    }),
  );
};
