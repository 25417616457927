import { Button, Typography } from '@components';
import {
  columnsToolPanel,
  filtersToolPanel,
} from '@components/Grid/components/constants';
import { useParams } from '@solidjs/router';
import {
  CustomerPaymentHistoryItem,
  fetchCustomerPaymentHistory,
} from '@store/customers/customerDetails';
import { dateFormatter } from '@store/loadboard';
import { dateComparator } from '@store/loadboard/utils';
import { Close } from '@suid/icons-material';
import { Box, List, ListItemText, Stack } from '@suid/material';
import { atgLogo } from '@views/ltl';
import { ColDef, ExcelExportParams } from '@ag-grid-community/core';
import AgGridSolid, { AgGridSolidRef } from '@ag-grid-community/solid';
import { Component, createSignal, Show } from 'solid-js';
import { ExcelSheetIcon } from '@assets';
import { DateTime } from 'luxon';
import { formatAmount } from '@utils/utils';
import { isEmpty } from 'lodash';

import cls from './classes';

const filterParams = {
  buttons: ['apply', 'reset'],
  suppressAndOrCondition: true,
};

const columnDefs: ColDef[] = [
  {
    field: 'orderId',
    headerName: 'Order#',
    filter: 'agNumberColumnFilter',
    filterParams,
    enableRowGroup: true,
  },
  {
    field: 'description',
    headerName: 'Payment Type',
    filter: 'agTextColumnFilter',
    filterParams,
    enableRowGroup: true,
  },
  {
    field: 'referenceNumber',
    headerName: 'Payment No.',
    filter: 'agTextColumnFilter',
    filterParams,
    enableRowGroup: true,
    rowGroup: true,
  },
  {
    field: 'amount',
    headerName: 'Applied',
    filter: 'agNumberColumnFilter',
    filterParams,
    enableRowGroup: true,
    valueFormatter: (params) => {
      return formatAmount(params.value as number);
    },
  },
  {
    field: 'order_Amount',
    headerName: 'Order Total',
    filter: 'agNumberColumnFilter',
    filterParams,
    enableRowGroup: true,
    valueFormatter: (params) => {
      return formatAmount(params.value as number);
    },
  },
  {
    field: 'createdDate',
    headerName: 'Payment Date',
    valueFormatter: dateFormatter,
    filter: 'agDateColumnFilter',
    rowGroup: true,
    enableRowGroup: true,
    filterParams: {
      ...filterParams,
      comparator: dateComparator,
    },
    sort: 'desc',
  },
];

const PaymentHistory: Component = () => {
  const params = useParams();
  let gridRef: AgGridSolidRef | null = null;

  const [infoBoxOpen, setInfoBoxOpen] = createSignal(true);
  const [paymentHistoryData, setPaymentHistoryData] = createSignal<
    CustomerPaymentHistoryItem[]
  >([]);

  const createGroupHeaderText = (params: {
    node: {
      level: number;
      key: string;
      allLeafChildren: Record<string, unknown>[];
    };
  }) => {
    const groupLevel = params.node.level;
    let customText;

    switch (groupLevel) {
      case 0:
        customText = `Payment Date ${DateTime.fromISO(params.node.key).toFormat(
          'MM/dd/yyyy',
        )}`;
        break;
      case 1:
        customText = `Payment No. ${params.node.key}, Orders ${
          params.node.allLeafChildren.length
        }, Applied ${formatAmount(
          params.node.allLeafChildren.reduce(
            (acc, v) => acc + (v as { data: { amount: number } }).data.amount,
            0,
          ),
        )}`;
        break;
      default:
        customText = 'Grouped by:';
    }
    return customText;
  };

  return (
    <Stack class={cls.paymentHistory}>
      <Show when={infoBoxOpen()}>
        <Stack
          class={cls.paymentHistoryInstructions}
          px={2}
          py={1}
          spacing={0.5}
          position="relative"
        >
          <Close onClick={() => setInfoBoxOpen(false)} class={cls.closeIcon} />
          <Typography
            variant="body1"
            component="p"
            class={cls.paymentHistoryInstructionsParagraph}
          >
            Issues can be found in the following rows :
          </Typography>
          <List class={cls.paymentHistoryInstructionsList}>
            <ListItemText class={cls.paymentHistoryInstructionsListItemText}>
              <Typography
                variant="body2"
                component="span"
                class={cls.paymentHistoryInstructionsListItem}
              >
                Payments for orders invoiced prior to 9/1/2017 either won't be
                reflected on this report or could be flawed
              </Typography>
            </ListItemText>
            <ListItemText class={cls.paymentHistoryInstructionsListItemText}>
              <Typography
                variant="body2"
                component="span"
                class={cls.paymentHistoryInstructionsListItem}
              >
                Payment information here is based on payments applied in ATGFR8;
                total check amounts may not be accurate depending on application
              </Typography>
            </ListItemText>
            <ListItemText class={cls.paymentHistoryInstructionsListItemText}>
              <Typography
                variant="body2"
                component="span"
                class={cls.paymentHistoryInstructionsListItem}
              >
                Unapplied funds and credits aren't displayed here
              </Typography>
            </ListItemText>
          </List>
          <Typography
            variant="body1"
            component="p"
            class={cls.paymentHistoryInstructionsParagraph}
          >
            If you notice any discrepancies, please contact the AR department.
          </Typography>
        </Stack>
      </Show>
      <Box
        displayRaw="flex"
        alignItems="center"
        justifyContent="end"
        backgroundColor="#F8F8F8"
        border={1}
        borderColor="#BABFC7"
        p={2}
      >
        <Button
          startIcon={<img src={ExcelSheetIcon} alt="Excel Sheet" />}
          variant="contained"
          label="Export to Excel"
          onClick={() => {
            const params: ExcelExportParams = {
              fileName: 'CustomerPayments.xlsx',
              skipRowGroups: false,
              processRowGroupCallback: createGroupHeaderText,
            };
            gridRef?.api.exportDataAsExcel(params);
          }}
        />
      </Box>
      <Box class="ag-theme-alpine flex-1" position="relative">
        <AgGridSolid
          ref={(ref) => (gridRef = ref)}
          overlayLoadingTemplate={atgLogo}
          columnDefs={columnDefs}
          rowData={paymentHistoryData()}
          gridOptions={{
            groupDisplayType: 'groupRows',
            defaultColDef: {
              flex: 1,
              filter: true,
            },
            pagination: true,
            suppressPaginationPanel: false,
            sideBar: {
              toolPanels: [columnsToolPanel, filtersToolPanel],
            },
            groupRowRendererParams: {
              innerRenderer: createGroupHeaderText,
            },
            onGridReady: async (gridParams) => {
              gridParams.api.showLoadingOverlay();
              const res = await fetchCustomerPaymentHistory(params.id);
              if (res.isSuccess && !isEmpty(res.value)) {
                setPaymentHistoryData(res.value);
                // Use columnApi to set grouping order explicitly
                gridParams.api.setRowGroupColumns([
                  'createdDate',
                  'referenceNumber',
                ]);
                gridParams.api.hideOverlay();
              } else {
                gridParams.api.showNoRowsOverlay();
              }
            },
          }}
        />
      </Box>
    </Stack>
  );
};

export default PaymentHistory;
