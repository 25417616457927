import { Button, DialogBox, ToastType, Typography } from '@components';
import { openDialogBox } from '@store/dialogBox';
import { Box, Grid } from '@suid/material';
import { Component, Show, createSignal } from 'solid-js';
import { Delete, Save } from '@suid/icons-material';
import { DELETE_FACTORING_COMPANY_DIALOG_ID } from '@views/order/constants';
import {
  deleteFactoringCompanyDetails,
  factoringCompanyDetailsStore,
  saveNewFactoringCompantDetails,
  setFactoringCompanyDetailsStore,
  updateFactoringCompanyDetails,
} from '@store/factoringCompany/factoringCompanyDetails';
import { handleToast, isAdmin, isSuperAdmin } from '@utils/utils';
import { useNavigate } from '@solidjs/router';
import { size } from 'lodash';
import { FactoringCompany } from '@store/carriers';

import cls from './classes';
import { handleFactoringCompanyDetailsValidation } from './utils';

export const FactoringCompanyDetailsHeader: Component = () => {
  const [saveLoading, setSaveLoading] = createSignal(false);

  const navigate = useNavigate();

  const handleDeleteFactoringCompany = async () => {
    const res = await deleteFactoringCompanyDetails(
      factoringCompanyDetailsStore.factoringCompanyDetails.id,
    );
    if (Boolean(res)) {
      handleToast(ToastType.Success, 'Factoring Company deleted successfully');
      navigate('/factoringCompany');
    }
  };

  const handleSaveOrUpdateFactoringCompanyDetails = async () => {
    setSaveLoading(true);
    if (handleFactoringCompanyDetailsValidation() ?? false) {
      const { creationMode, factoringCompanyDetails } =
        factoringCompanyDetailsStore;
      const updatedContact = factoringCompanyDetails.contacts?.map(
        (contact) => {
          return {
            ...contact,
            id: contact.operationType === 'Insert' ? 0 : contact.id,
          };
        },
      );

      const factoringCompanyPayload = {
        ...factoringCompanyDetails,
        contacts: updatedContact,
        operationType: creationMode ? 'Insert' : 'Update',
      };

      const res = creationMode
        ? await saveNewFactoringCompantDetails(
            factoringCompanyPayload as FactoringCompany,
          )
        : await updateFactoringCompanyDetails(
            factoringCompanyPayload.id,
            factoringCompanyPayload as FactoringCompany,
          );
      handleResponse(res as FactoringCompany, creationMode);
    }
    setSaveLoading(false);
  };

  const handleResponse = (res: FactoringCompany, creationMode: boolean) => {
    if (Boolean(res)) {
      handleToast(
        ToastType.Success,
        creationMode
          ? 'Factoring Company saved successfully'
          : 'Factoring Company updated successfully',
      );
      setFactoringCompanyDetailsStore('factoringCompanyDetails', res);
      creationMode && navigate('/factoringCompany');
    }
  };

  return (
    <Grid
      container
      alignItems="center"
      backgroundColor="white"
      class={cls.headerContainer}
      spacing={2}
    >
      <Grid item xs={12} md={6}>
        <Box>
          <Typography variant="h4" class="truncate w-[500px]">
            {factoringCompanyDetailsStore.creationMode
              ? 'New Factoring Company'
              : factoringCompanyDetailsStore.factoringCompanyDetails.name}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box class={cls.customerActionBtns}>
          <Show when={isSuperAdmin() || isAdmin()}>
            <Button
              startIcon={<Save />}
              variant="contained"
              label="SAVE"
              type="submit"
              isLoading={saveLoading()}
              onClick={handleSaveOrUpdateFactoringCompanyDetails}
              disabled={saveLoading()}
            />
          </Show>
          <Show
            when={
              (isSuperAdmin() || isAdmin()) &&
              size(
                factoringCompanyDetailsStore.factoringCompanyDetails.carriers,
              ) === 0 &&
              !factoringCompanyDetailsStore.creationMode
            }
          >
            <Button
              startIcon={<Delete />}
              variant="contained"
              label="Delete Factoring Company"
              type="button"
              onClick={() => openDialogBox(DELETE_FACTORING_COMPANY_DIALOG_ID)}
              class={cls.headerDeleteBtn}
            />
            <DialogBox
              id={DELETE_FACTORING_COMPANY_DIALOG_ID}
              title="Are you sure you want to delete this Factoring Company?"
              onSubmitText="Delete"
              onSubmit={handleDeleteFactoringCompany}
            />
          </Show>
        </Box>
      </Grid>
    </Grid>
  );
};
