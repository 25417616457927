import { Button } from '@components';
import { closeModal } from '@store/modals';
import { orderStore, carrierStore } from '@store/orders';
import WarningIcon from '@suid/icons-material/Warning';
import { createEffect, createSignal } from 'solid-js';
export const SafetyWarningModal = () => {
  const [trackingVerbage, setTrackingVerbage] = createSignal('');
  createEffect(() => {
    if (
      Boolean(
        carrierStore.carrier[orderStore.activeTab.index]
          ?.requireMacropointTracking,
      )
    ) {
      setTrackingVerbage('Track your truck via Macropoint.');
    }
    if (
      !Boolean(
        carrierStore.carrier[orderStore.activeTab.index]
          ?.requireMacropointTracking,
      ) &&
      Boolean(
        carrierStore.carrier[orderStore.activeTab.index]
          ?.shipperVerifyMCDotNumber,
      )
    ) {
      setTrackingVerbage(
        'Not required, but we recommend to track your truck via MacroPoint.',
      );
    }
  });
  return (
    <div class="flex gap-x-4">
      <div class="mt-4">
        <WarningIcon sx={{ color: '#b00020', fontSize: '50px' }} />
      </div>
      <div>
        <div class="text-2xl font-semibold text-armstrong-primary mt-2">
          Safety Warning
        </div>
        <div class="text-base mt-2">
          <b>{carrierStore.carrier[orderStore.activeTab.index]?.name}</b> has
          been flagged as a potential fraud risk. You may proceed with this
          carrier by agreeing to and following the safety measures below, or you
          can choose to assign a different carrier to your load.
        </div>
        <div class="mt-2 ml-4">
          <ul class=" text-base list-none">
            <li class="relative pl-6 before:content-['•'] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:text-lg">
              The Shipper should verify the truck being loaded displays the MC#,
              DOT#, and the company name that Armstrong dispatched.
            </li>
            <li class=" relative pl-6 before:content-['•'] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:text-lg">
              {trackingVerbage()}
            </li>
            <li class="relative pl-6 before:content-['•'] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:text-lg">
              Please make sure you are using verified contact information.
            </li>
            <li class="relative pl-6 before:content-['•'] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:text-lg">
              Please make sure your office has direct communication with the
              driver.
            </li>
            <li class="relative pl-6 before:content-['•'] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:text-lg">
              Photos of the driver's ID and tractor/trailer's license plates
              should be taken.
            </li>
          </ul>
        </div>
        <div class="my-4">
          Verified VINS can be found in Highway to verify what the shipper finds
          on the truck. If you need additional help, please contact{' '}
          <span class="font-semibold text-armstrong-blue">
            carriers@armstrongtransport.com
          </span>
        </div>
        <div class="float-right">
          <Button
            variant="contained"
            label="I Agree"
            onClick={() => closeModal('safetyWarning')}
            class="!bg-armstrong-primary"
          />
        </div>
      </div>
    </div>
  );
};
