import { dateComparator, dateFormatter } from '@store/loadboard/utils';
import { ColDef, ValueFormatterParams } from '@ag-grid-community/core';
import { formatAmount } from '@utils/utils';
const commonProps: ColDef = {
  enableRowGroup: true,
  resizable: true,
  autoHeight: true,
  cellStyle: {
    padding: '5px 10px',
    'word-break': 'break-word',
    'white-space': 'normal',
    'line-height': '1.6 ',
    'align-items': 'center !important',
    cursor: 'pointer',
  },
};

const commonFilterParams = {
  suppressAndOrCondition: true,
  filterOptions: ['startsWith'],
  buttons: ['apply', 'reset'],
};

const createGroupValueFormatter =
  (prefix: string) => (params: ValueFormatterParams<unknown, Date>) => {
    if (params.node?.rowGroupColumn?.getColId() === params.colDef.field) {
      if (prefix === 'Date') {
        return `${prefix}: ${dateFormatter(params)}`;
      }
      if (prefix === 'Value') {
        return `${prefix}: ${formatAmount(
          Number(params.value),
          {},
          '-',
          true,
        )}`;
      }
      return `${prefix}: ${params.value}`;
    }
    return typeof params.value === 'string'
      ? params.value
      : typeof params.value === 'number' && prefix === 'Value'
        ? formatAmount(Number(params.value), {}, '-', true)
        : '';
  };

const baseColumnDefs: ColDef[] = [
  {
    field: 'office',
    minWidth: 120,
    filter: 'agTextColumnFilter',
    filterParams: commonFilterParams,
  },
  {
    field: 'loadNumber',
    minWidth: 100,
    maxWidth: 150,
    filter: 'agTextColumnFilter',
    filterParams: commonFilterParams,
    valueFormatter: createGroupValueFormatter('LoadNumber'),
  },
  {
    field: 'loadStatus',
    minWidth: 100,
    maxWidth: 150,
    filter: 'agTextColumnFilter',
    filterParams: commonFilterParams,
    valueFormatter: createGroupValueFormatter('LoadStatus'),
  },
  {
    field: 'carrier',
    minWidth: 100,
    filter: 'agTextColumnFilter',
    filterParams: commonFilterParams,
    valueFormatter: createGroupValueFormatter('Carrier'),
  },
  {
    field: 'customer',
    minWidth: 100,
    filter: 'agTextColumnFilter',
    filterParams: commonFilterParams,
    valueFormatter: createGroupValueFormatter('Customer'),
  },
  {
    field: 'agent',
    minWidth: 100,
    filter: 'agTextColumnFilter',
    filterParams: commonFilterParams,
  },
  {
    field: 'date',
    minWidth: 100,
    maxWidth: 120,
    filter: 'agDateColumnFilter',
    valueFormatter: createGroupValueFormatter('Date'),
    comparator: dateComparator,
    filterParams: {
      comparator: dateComparator,
      buttons: ['apply', 'reset'],
      suppressAndOrCondition: true,
    },
  },
  {
    field: 'status',
    minWidth: 100,
    maxWidth: 150,
    filter: 'agTextColumnFilter',
    filterParams: commonFilterParams,
    valueFormatter: createGroupValueFormatter('Status'),
  },
  {
    field: 'claimDescription',
    headerName: 'Description',
    minWidth: 380,
    filter: 'agTextColumnFilter',
    filterParams: commonFilterParams,
    valueFormatter: createGroupValueFormatter('Description'),
  },
  {
    field: 'claimValue',
    headerName: 'Value',
    minWidth: 100,
    maxWidth: 150,
    filter: 'agNumberColumnFilter',
    valueFormatter: createGroupValueFormatter('Value'),
    filterParams: {
      suppressAndOrCondition: true,
      buttons: ['apply', 'reset'],
    },
  },
];
export const adminColDefs: ColDef[] = baseColumnDefs.map((col) => {
  if (col.field === 'office') {
    return {
      ...col,
      rowGroup: true,
      hide: true,
      sort: 'asc',
      valueFormatter: createGroupValueFormatter('Office'),
      ...commonProps,
    };
  }
  if (col.field === 'agent') {
    return {
      ...col,
      rowGroup: false,
      sort: undefined,
      hide: false,
      valueFormatter: createGroupValueFormatter('Agent'),
      ...commonProps,
    };
  }
  return { ...col, ...commonProps };
});
export const colDefs: ColDef[] = baseColumnDefs.map((col) => {
  if (col.field === 'agent') {
    return {
      ...col,
      rowGroup: true,
      hide: true,
      sort: 'asc',
      ...commonProps,
    };
  }
  if (col.field === 'office') {
    return {
      ...col,
      rowGroup: false,
      hide: true,
      sort: undefined,
      ...commonProps,
    };
  }
  return { ...col, ...commonProps };
});
export const capacityColDefs: ColDef[] = baseColumnDefs.map((col) => {
  if (col.field === 'customer') {
    return { ...col, hide: true, ...commonProps };
  }
  return { ...col, ...commonProps };
});
