import {
  Button,
  DatePicker,
  NewAutoComplete,
  Notification,
  Typography,
} from '@components';
import { FABInput } from '@components/FABInput';
import { createForm } from '@felte/solid';
import { validator } from '@felte/validator-yup';
import { getEffectiveIsEnabledForUserByFeatureName } from '@store/Global/service';
import { fetchCustomerLTLData } from '@store/customers/customerDetails/LTL';
import {
  fetchAccessorials,
  getLTLFalveyPricingQuotes,
} from '@store/ltl/actions';
import {
  accessorialStore,
  fetchQuotes,
  ltlQuoteState,
  ltlQuotingResultStore,
  ltlRateQuoteRequest,
  setLtlQuoteState,
  setLTLQuotingResultStore,
} from '@store/ltl/store';
import {
  CustomerAutoCompleteObject,
  FeatureFlagCustomer,
  IAddress,
  ILocationSearch,
  fetchFeatureFlagGetCustomerFeatures,
  orderStore,
} from '@store/orders';
import { Check } from '@suid/icons-material';
import {
  Box,
  FormControlLabel,
  Grid,
  List,
  Radio,
  RadioGroup,
  Stack,
} from '@suid/material';
import { deepUnwrap } from '@utils/formUtils';
import { remappedCountryCode } from '@utils/ltlUtils';
import { printError } from '@utils/utils';
import { DEFAULT_LOAD_ITEM, HAZMAT_ID } from '@views/ltl/constants';
import { LTLQuotingSchema } from '@views/ltl/validation';
import { compact, omit, sortBy } from 'lodash';
import { DateTime } from 'luxon';
import { Accessor, For, Show, createSignal, onMount } from 'solid-js';
import { createStore, produce } from 'solid-js/store';

import { LTlFormClasses as cls } from './classes';
import { LTLLoadItems } from './components';
import LTLQuotingLoadingSkeleton from './components/LTLQuotingLoadingSkeleton';
import { checkValidZipCode } from './helper';
import { ItemAccessorial, LTLQuotingState } from './types';
type qAccessorial = {
  checked: boolean;
  accessorialId: string;
  accessorialType: number;
  accessorial: ItemAccessorial;
};

export type AccessorialsType = {
  pickup: qAccessorial[];
  delivery: qAccessorial[];
  shipment: qAccessorial[];
  hazmat: ItemAccessorial;
};

export type ltlFormProps = {
  setActiveView: (x: string) => void;
};

export const LTLForm = (props: ltlFormProps) => {
  const [accessorials, setAccessorials] = createStore<AccessorialsType>({
    pickup: [],
    delivery: [],
    shipment: [],
    hazmat: {} as ItemAccessorial,
  });

  const [selectedShipment, setSelectedShipment] = createSignal<string | null>(
    null,
  );
  const [customerFeatureFlagInfo, setCustomerFeatureFlagInfo] =
    createSignal<FeatureFlagCustomer>({
      customerCommentVisibility: false,
      suggestedClass: false,
    });
  const [loading, setLoading] = createSignal(false);

  const [suggestedClassSettingEnabled, setSuggestedClassSettingEnabled] =
    createSignal(false);

  function mapCountryCode(country: string | undefined | null): string {
    switch (country) {
      case 'CAN':
        return 'CA';
      case 'MEX':
        return 'MX';
      case 'USA':
        return 'US';
      default:
        return country ?? '';
    }
  }
  function getCityStateCountryZipInfo(type: string, isRequote = false) {
    const isOrigin = type === 'Pickup';
    const prefix = isOrigin ? 'origin' : 'destination';
    const orderInfo = orderStore.order;
    let address: Partial<IAddress> = {};
    if (isRequote) {
      address = {
        city: isOrigin
          ? ltlQuoteState.quote?.originCity ?? ''
          : ltlQuoteState.quote?.destinationCity ?? '',
        state: isOrigin
          ? ltlQuoteState.quote?.originState ?? ''
          : ltlQuoteState.quote?.destinationState ?? '',
        country: isOrigin
          ? mapCountryCode(ltlQuoteState.quote?.originCountry)
          : mapCountryCode(ltlQuoteState.quote?.destinationCountry),
        zip: isOrigin
          ? ltlQuoteState.quote?.originZip ?? ''
          : ltlQuoteState.quote?.destinationZip ?? '',
      };
      setZipCode(prefix, address);
      return;
    }
    orderInfo.loads[orderStore.activeTab.index]?.stops?.forEach((stop) => {
      if (
        (isOrigin && Boolean(stop.origin)) ||
        (isOrigin && Boolean(stop.pickUp))
      ) {
        address = {
          city: stop.city ?? '',
          state: stop.state ?? '',
          country: stop.countryCode ?? 'USA',
          zip: stop.zip,
        };
      } else if (
        (!isOrigin && Boolean(stop.destination)) ||
        (!isOrigin && Boolean(stop.dropOff))
      ) {
        address = {
          city: stop.city ?? '',
          state: stop.state ?? '',
          country: stop.countryCode ?? 'USA',
          zip: stop.zip,
        };
      }
    });
    setZipCode(prefix, address);
  }

  function getAccessorialsInfo(type: string) {
    const pickupVals: qAccessorial[] = [];
    const deliveryVals: qAccessorial[] = [];
    const shipmentVals: qAccessorial[] = [];
    const hazmatVals = accessorialStore.items.find(
      (a) => a.id.toLowerCase() === HAZMAT_ID.toLowerCase(),
    ) as ItemAccessorial;

    accessorialStore.items.forEach((x) => {
      const item = {
        checked: false,
        accessorialId: x.id,
        accessorial: x,
      };

      if (Boolean(x.isPickup)) {
        pickupVals.push({ ...item, accessorialType: 1 });
      }

      if (Boolean(x.isDelivery)) {
        deliveryVals.push({ ...item, accessorialType: 2 });
      }

      if (x.isGlobal && x.name !== 'Hazmat') {
        shipmentVals.push({ ...item, accessorialType: 0 });
      }
    });

    //NOTE: LOOK INTO THIS
    setAccessorials('hazmat', hazmatVals);

    switch (type) {
      case 'Pickup':
        pickupVals.forEach((x: qAccessorial) => {
          if (
            ltlQuoteState.quote?.ltlQuote?.pickupAccessorials!.includes(
              x.accessorial.name!,
            ) ??
            false
          ) {
            x.checked = true;
          }
        });
        setAccessorials('pickup', sortBy(pickupVals, 'accessorial.name'));
      case 'Delivery':
        deliveryVals.forEach((x: qAccessorial) => {
          if (
            ltlQuoteState.quote?.ltlQuote?.deliveryAccessorials!.includes(
              x.accessorial.name!,
            ) ??
            false
          ) {
            x.checked = true;
          }
        });
        setAccessorials('delivery', sortBy(deliveryVals, 'accessorial.name'));
      case 'Shipment':
        shipmentVals.forEach((x: qAccessorial) => {
          if (
            ltlQuoteState.quote?.ltlQuote?.shipmentAccessorials!.includes(
              x.accessorial.name!,
            ) ??
            false
          ) {
            x.checked = true;
          }
        });
        shipmentVals.sort((a, b) => {
          const numA = parseInt(a.accessorial.name!.split(' ')[2]);
          const numB = parseInt(b.accessorial.name!.split(' ')[2]);
          return numA - numB;
        });
        setAccessorials('shipment', shipmentVals);
        break;
      default:
    }
  }

  function mapRequoteInformation() {
    const { quote } = ltlQuoteState;
    if (quote) {
      const { customerId, customerName, loadItems, pickupDate, ltlQuote } =
        quote;
      const { ltlInsurance, shipmentAccessorials } = ltlQuote || {};
      if (customerId !== undefined && customerName !== undefined) {
        setFields('customerId', customerId);
        setFields('customerName', customerName);
      }

      if (loadItems !== undefined) {
        setFields(
          'loadItems',
          loadItems.map((x) => ({
            ...x,
            quantity: Number(x.quantity),
            lengthInch: Number(x.lengthInch),
            widthInch: Number(x.widthInch),
            heightInch: Number(x.heightInch),
            item: x.type ?? x.item,
            type: x.type ?? x.item,
            weight: Number(x.weight),
            pieces: Number(x.pieces),
            class: String(x.class),
            loadItemHazmatDetail: {
              ...x.loadItemHazmatDetail,
              unNumber: x.loadItemHazmatDetail?.unNumber ?? '',
            },
          })),
        );
      }

      if (Boolean(pickupDate)) {
        setFields('pickupDate', new Date(pickupDate));
      }

      const finalDeclaredValue = ltlInsurance?.shippingSumInsured ?? 0;
      if (Boolean(finalDeclaredValue)) {
        setFields('declaredValue', finalDeclaredValue);
      }

      if ((shipmentAccessorials ?? []).length > 0) {
        setSelectedShipment(shipmentAccessorials[0]);
      }
      getCityStateCountryZipInfo('Pickup', true);
      getCityStateCountryZipInfo('Dropoff', true);
    } else {
      const orderInfo = orderStore.order;
      const { customerId, customerName } = orderInfo;
      const items = orderInfo.loads[orderStore.activeTab.index]?.items;
      let pickupDate = new Date();
      if (orderInfo.loads[orderStore.activeTab.index]?.stops !== undefined) {
        pickupDate = new Date(
          orderInfo.loads[orderStore.activeTab.index]?.stops?.[0]?.stopDate ??
            new Date(),
        );
      }
      setFields('customerId', customerId);
      if (customerName !== undefined) {
        setFields('customerName', customerName);
      }

      if (items !== undefined) {
        setFields(
          'loadItems',
          items.map((x) => ({
            ...x,
            quantity: Number(x.quantity),
            lengthInch: Number(x.lengthInch),
            widthInch: Number(x.widthInch),
            heightInch: Number(x.heightInch),
            item: x.type ?? x.item,
            type: x.type ?? x.item,
            weight: Number(x.weight),
            pieces: Number(x.pieces),
            class: String(x.class),
            loadItemHazmatDetail: {
              ...x.loadItemHazmatDetail,
              unNumber: x.loadItemHazmatDetail?.unNumber ?? '',
            },
          })),
        );
      }
      if (Boolean(pickupDate)) {
        setFields('pickupDate', new Date(pickupDate));
      }
      getCityStateCountryZipInfo('Pickup');
      getCityStateCountryZipInfo('Dropoff');
    }
  }
  onMount(async () => {
    setLoading(true);
    //we only need 1 call to accessorial and we need it here, if we do it elsewhere, we can get multiple calls
    if (accessorialStore.items.length === 0) {
      await fetchAccessorials();
    }
    const resp = await getEffectiveIsEnabledForUserByFeatureName('Falvey');
    const isShortQuoteEnabled = await getEffectiveIsEnabledForUserByFeatureName(
      'UseShortQuoteGroupLocator',
    );
    setLtlQuoteState('isFalveyInsuranceFlow', Boolean(resp));
    setLtlQuoteState('isShortQuoteEnabled', Boolean(isShortQuoteEnabled));
    setTimeout(() => {
      getAccessorialsInfo('Pickup');
      getAccessorialsInfo('Delivery');
      getAccessorialsInfo('Shipment');
      getAccessorialsInfo('Hazmat');

      setLoading(false);
    });
    mapRequoteInformation();
  });

  const handleGetRates = async (value: LTLQuotingState) => {
    const unWrappedAccessorials = deepUnwrap(accessorials);

    const getCheckedAccessorials = (accessorials: qAccessorial[]) => {
      return accessorials.map((x) => {
        if (x.checked) {
          const x1 = omit(x, 'checked');
          return x1;
        }
      });
    };

    const payload = {
      customerName: value.customerName !== null ? value.customerName : '',
      customerId: value.customerId ?? 0,
      destinationCity: value.destinationCity,
      destinationCountry: value.destinationCountry,
      destinationZip: value.destinationZip,
      destinationState: value.destinationState,
      originZip: value.originZip,
      originCity: value.originCity,
      originCountry: value.originCountry,
      originState: value.originState,
      pickupDate:
        DateTime.fromJSDate(value.pickupDate).toISO({
          suppressMilliseconds: true,
          includeOffset: false,
        }) ?? '',
      loadItems: value.loadItems,
      isStackable: value.isStackable,
      isLinearFeetOverridden: value.isLinearFeetOverridden,
      linearFeet: value.linearFeet !== '' ? value.linearFeet : 0,
      accessorials: compact([
        ...getCheckedAccessorials(unWrappedAccessorials.pickup),
        ...getCheckedAccessorials(unWrappedAccessorials.delivery),
        ...getCheckedAccessorials(unWrappedAccessorials.shipment),
        value.loadItems.find((x) => x.hazmat) && {
          accessorialId: HAZMAT_ID,
          accessorialType: 0,
          accessorial: unWrappedAccessorials.hazmat,
        },
      ]),
    };

    // Fetch rates
    await fetchQuotes(payload);

    // If declared value exists and no error occurred, call getLTLFalveyPricingQuotes
    if (Boolean(value.declaredValue) && !ltlQuotingResultStore.isError) {
      const quoteParametersId =
        ltlQuotingResultStore.items[0].quoteParametersId; // Assume this exists in the store
      await getLTLFalveyPricingQuotes(
        {
          declaredValue: value.declaredValue as number,
          quoteParametersId,
        },
        true,
      );
    }

    setLTLQuotingResultStore('isLoading', false);

    if (!ltlQuotingResultStore.isError) {
      props.setActiveView('quote-result');
    }

    setLtlQuoteState('declaredValue', value.declaredValue ?? undefined);
  };

  const { form, setFields, data, errors } = createForm<LTLQuotingState>({
    initialValues: {
      ...ltlRateQuoteRequest,
      pickupDate: DateTime.now().toJSDate(),
      customerId: null,
      customerName: null,
      isStackable:
        ltlQuoteState.quote?.ltlQuote?.rateRequest.isStackable ?? false,
      isLinearFeetOverridden:
        ltlQuoteState.quote?.ltlQuote?.rateRequest.isLinearFeetOverridden ??
        false,
      linearFeet: ltlQuoteState.quote?.ltlQuote?.rateRequest.linearFeet ?? '0',
      declaredValue: null,
      loadItems: ltlQuoteState.quote?.loadItems ?? [DEFAULT_LOAD_ITEM],
    },
    extend: validator({ schema: LTLQuotingSchema }),
    onSubmit: async (values) => {
      values.loadItems = values.loadItems.map((li) => {
        return {
          ...li,
          weight: Number(li.weight),
        };
      });
      await handleGetRates(values);
    },
    // This can be used for debugging the validation process.
    // onError(error, context) {
    //   // eslint-disable-next-line no-console
    //   console.log('onError', error, context);
    // },
  });

  const onCustomerSelect = async (item: CustomerAutoCompleteObject) => {
    setFields('customerId', item.id);
    setFields('customerName', item.name);
    if (Boolean(item.defaultOriginZipCode)) {
      setFields('originZip', item.defaultOriginZipCode ?? '');
      setFields('originCity', item.defaultOriginCity ?? '');
      setFields('originCountry', item.defaultOriginCountryCode ?? 'USA');
      setFields('originState', item.defaultOriginState ?? '');
    }

    await fetchCustomerLTLData(item.id!.toString()).then((res) => {
      const settingValue =
        res?.ltlCustomerSettings.find(
          (s) => s.setting === 'EnableSuggestedClassInCustomerPortal',
        )?.value ?? false;
      setSuggestedClassSettingEnabled(settingValue);
    });

    try {
      await fetchFeatureFlagGetCustomerFeatures(item.id!).then((res) => {
        setCustomerFeatureFlagInfo(res);
      });
    } catch (error) {
      printError(error);
    }
  };

  const setZipCode = (
    type: 'origin' | 'destination',
    item: Partial<IAddress>,
  ) => {
    if (Boolean(item)) {
      setFields(`${type}City`, item.city ?? '');
      setFields(`${type}Country`, remappedCountryCode(item.country));
      setFields(`${type}State`, item.state ?? '');
      setFields(`${type}Zip`, item.zip ?? '');
    }
  };

  const clearZipCode = (type: 'origin' | 'destination') => {
    setFields(`${type}City`, '');
    setFields(`${type}Country`, '');
    setFields(`${type}State`, '');
    setFields(`${type}Zip`, '');
  };

  const handleAccessorialChange = (
    type: 'pickup' | 'delivery' | 'shipment',
    i: Accessor<number>,
  ) => {
    setAccessorials(
      type,
      produce((items) => {
        items.forEach((item, index) => {
          if (type === 'shipment') {
            item.checked = index === i();
          } else {
            if (index === i()) {
              item.checked = !item.checked;
            }
          }
        });
      }),
    );
  };

  const onInputOriginAndDestinationZip = (
    e: Event,
    type: 'origin' | 'destination',
  ) => {
    const value = (e.target as HTMLInputElement).value;
    !checkValidZipCode(value);
    setFields(`${type}City`, '');
    setFields(`${type}Country`, '');
    setFields(`${type}State`, '');
  };

  return (
    <form ref={form}>
      <Show when={!loading()} fallback={<LTLQuotingLoadingSkeleton />}>
        <Box p={1}>
          <Stack spacing={2}>
            <Stack spacing={1}>
              <Notification
                tableRowNotification
                sxProps={cls.quoteNotification}
                type="info"
                text={
                  <Typography variant="body1" component="p">
                    Quotes are contingent on actual ship date. For most accurate
                    pricing, please select a date closest to the actual shipment
                    date.
                  </Typography>
                }
              />
              <Typography variant="body1" component="p" class="!text-md">
                Fields marked with <span class={cls.mandatory}> *</span> are
                required.
              </Typography>
            </Stack>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <DatePicker
                    value={
                      DateTime.fromJSDate(data().pickupDate).toISO({
                        suppressMilliseconds: true,
                        includeOffset: false,
                      })!
                    }
                    handleChange={(value: string) => {
                      setFields(
                        'pickupDate',
                        DateTime.fromISO(value).toJSDate(),
                      );
                    }}
                    label="Expected Ship Date"
                    required
                    error={errors().pickupDate}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <NewAutoComplete
                    endpoint="Address/PostalCode/"
                    onItemSelect={(item) =>
                      setZipCode('origin', item as unknown as IAddress)
                    }
                    inputProps={{
                      maxLength: 7,
                    }}
                    forceAutoSelect
                    value={data().originZip}
                    id="autocomplete-field-origin"
                    label="Origin Zip"
                    version="v3"
                    responseFieldName="city"
                    error={errors().originZip}
                    name="originZip"
                    defaultValue=""
                    renderItem={(item) => {
                      const item2 = item as ILocationSearch;
                      return (
                        <Typography variant="body1" component="p">
                          {item2.city}, {item2.state} {item2.country}
                        </Typography>
                      );
                    }}
                    onClearValue={() => clearZipCode('origin')}
                    onInput={(e) => onInputOriginAndDestinationZip(e, 'origin')}
                    required
                  />
                  {data().originZip &&
                    data().originCity &&
                    data().originState && (
                      <Box class={cls.selectedValueBox}>
                        <Check class={cls.selectedValueIcon}></Check>
                        {`${data().originCity}, ${data().originState} ${
                          data().originCountry
                        }`}
                      </Box>
                    )}
                </Grid>
                <Grid item xs={12} md={3}>
                  <NewAutoComplete
                    endpoint="Address/PostalCode/"
                    onItemSelect={(item) =>
                      setZipCode('destination', item as unknown as IAddress)
                    }
                    inputProps={{
                      maxLength: 7,
                    }}
                    forceAutoSelect
                    value={data().destinationZip}
                    id="autocomplete-field-origin"
                    label="Destination Zip"
                    name="destinationZip"
                    version="v3"
                    defaultValue=""
                    responseFieldName="city"
                    renderItem={(item) => {
                      const item2 = item as ILocationSearch;
                      return (
                        <Typography variant="body1" component="p">
                          {item2.city}, {item2.state} {item2.country}
                        </Typography>
                      );
                    }}
                    error={errors().destinationZip}
                    onClearValue={() => clearZipCode('destination')}
                    onInput={(e) =>
                      onInputOriginAndDestinationZip(e, 'destination')
                    }
                    required
                  />
                  {data().destinationZip &&
                    data().destinationCity &&
                    data().destinationState && (
                      <Box class={cls.selectedValueBox}>
                        <Check class={cls.selectedValueIcon}></Check>
                        {`${data().destinationCity}, ${
                          data().destinationState
                        } ${data().destinationCountry}`}
                      </Box>
                    )}
                </Grid>
                <Grid item xs={12} md={3}>
                  <NewAutoComplete
                    responseFieldName="name"
                    endpoint="customer/autocomplete?term"
                    value={data().customerName ?? ''}
                    onItemSelect={(item) =>
                      onCustomerSelect(item as CustomerAutoCompleteObject)
                    }
                    onChange={(value) => {
                      setFields('customerName', value);
                      //remove customer id if customer name is not verified from drop down
                      setFields('customerId', null);
                    }}
                    onBlur={() => {
                      if (data().customerName === '') {
                        setFields('customerId', null);
                        setFields('declaredValue', 0);
                      }
                    }}
                    id="autocomplete-field-customername"
                    label="Customer (optional)"
                    defaultValue=""
                    onClearValue={() => {
                      setFields('customerName', '');
                      setFields('customerId', null);
                      setFields('declaredValue', 0);
                    }}
                  />
                  {Boolean(data().customerName) &&
                    Boolean(data().customerId) && (
                      <Box class={cls.selectedValueBox}>
                        <Check class={cls.selectedValueIcon}></Check>
                        {data().customerName}
                      </Box>
                    )}
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h6" class={cls.subTitle}>
                    Accessorials
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4.5}>
                  <Typography
                    variant="body1"
                    component="h2"
                    class={cls.subTitle2}
                  >
                    Pickup
                  </Typography>
                  <Box class={cls.fabInputContainer}>
                    <For each={accessorials.pickup}>
                      {(pickUp, i) => {
                        return (
                          <Grid item>
                            <FABInput
                              label={pickUp.accessorial.name!}
                              checked={pickUp.checked}
                              onClick={() =>
                                handleAccessorialChange('pickup', i)
                              }
                            />
                          </Grid>
                        );
                      }}
                    </For>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4.5}>
                  <Typography
                    variant="body1"
                    component="h2"
                    class={cls.subTitle2}
                  >
                    Delivery
                  </Typography>
                  <Box class={cls.fabInputContainer}>
                    <For each={accessorials.delivery}>
                      {(delivery, i) => {
                        return (
                          <Grid item>
                            <FABInput
                              label={delivery.accessorial.name!}
                              checked={delivery.checked}
                              onClick={() =>
                                handleAccessorialChange('delivery', i)
                              }
                            />
                          </Grid>
                        );
                      }}
                    </For>
                  </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Box>
                    <Box class={cls.shipmentSectionHeader}>
                      <Typography
                        variant="body1"
                        component="h2"
                        class={cls.shipmentTitle}
                      >
                        Shipment
                      </Typography>
                      <Typography
                        component="span"
                        variant="body1"
                        onClick={() => {
                          setSelectedShipment(null);
                          setAccessorials(
                            'shipment',
                            produce((loads) => {
                              loads.forEach((load) => {
                                load.checked = false;
                              });
                            }),
                          );
                        }}
                        class={cls.shipmentClearLink}
                      >
                        Clear
                      </Typography>
                    </Box>
                    <List class="!py-0">
                      <RadioGroup value={selectedShipment()}>
                        <For each={accessorials.shipment}>
                          {(shipment, i) => (
                            <FormControlLabel
                              control={
                                <Radio
                                  onClick={() => {
                                    handleAccessorialChange('shipment', i);
                                    setSelectedShipment(
                                      shipment.accessorial.name!,
                                    );
                                  }}
                                  onKeyDown={(e) => {
                                    handleAccessorialChange('shipment', i);
                                    setSelectedShipment(
                                      shipment.accessorial.name!,
                                    );
                                    if (e.key === 'Enter') {
                                      e.stopPropagation();
                                      e.preventDefault();
                                    }
                                  }}
                                />
                              }
                              label={shipment.accessorial.name}
                              value={shipment.accessorial.name!}
                            />
                          )}
                        </For>
                      </RadioGroup>
                    </List>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <LTLLoadItems
              setFields={setFields}
              customerFeatureFlagInfo={customerFeatureFlagInfo}
              data={data}
              errors={errors}
              suggestedClassSettingEnabled={suggestedClassSettingEnabled}
            />
            <Box class={cls.getRatesBtnContainer}>
              <Button
                type="submit"
                variant="contained"
                disabled={ltlQuotingResultStore.isLoading}
                isLoading={ltlQuotingResultStore.isLoading}
                label="Get Rates"
              />
            </Box>
          </Stack>
        </Box>
      </Show>
    </form>
  );
};
