import { createStore } from 'solid-js/store';

import { ClaimStoreType } from './types';

const [claimDataStore, setClaimDataStore] = createStore<ClaimStoreType>({
  isLoading: false,
  claimDetails: null,
});

export { claimDataStore, setClaimDataStore };
