import { handleToast } from '@utils/utils';
import { ToastType } from '@components';
import { produce } from 'solid-js/store';
import { DEFAULT_OFFICE_DETAIL } from '@views/office/officeDetails/constants';
import { userStore } from '@store/user';
import { getATGUserDataApi } from '@store/userDetails';
import { difference } from 'lodash';
import { userHasPermission } from '@views/order/components/carrier/stops/stopsEditor/utilFunctions';

import {
  addEditGroupApi,
  deleteOfficeDetailsGroupApi,
  activeUserApi,
  addRoleApi,
  createUserApi,
  fetchDocumentApi,
  fetchOfficeDetailApi,
  fetchRecruitersListApi,
  removeRoleApi,
} from './services';
import { officeDetailStore, setOfficeDetailStore } from './store';
import { Group, OfficeDetailType, User, UserCreateType } from './types';

export const fetchOfficeDetailDataAction = async (id: string) => {
  try {
    const data = await fetchOfficeDetailApi(id);
    const roles = data.roles.map((item) => ({
      label: item.name,
      value: item.name,
    }));
    setOfficeDetailStore(
      produce((draft) => {
        draft.officeDetail = data;
        draft.rolesOptions = roles;
      }),
    );
  } catch {
    setOfficeDetailStore('isLoading', false);
    handleToast(ToastType.Error, 'Fetching office detail failed');
  }
};

export const updateOfficeDetailsState = (
  updates: Partial<OfficeDetailType>,
) => {
  Object.entries(updates).forEach(([key, value]) => {
    setOfficeDetailStore('officeDetail', key as keyof OfficeDetailType, value);
  });
};

export const fetchRecruitersListAction = async () => {
  try {
    const data = await fetchRecruitersListApi();
    setOfficeDetailStore('Recruiters', data);
  } catch (error) {
    setOfficeDetailStore('isLoading', false);
    handleToast(ToastType.Error, 'Fetching Recruiters list failed');
  }
};

export const fetchDocumentAction = async (id: string) => {
  try {
    const data = await fetchDocumentApi(id);
    setOfficeDetailStore('officeDocuments', data);
  } catch (error) {
    setOfficeDetailStore('isLoading', false);
    handleToast(ToastType.Error, 'Fetching Document list failed');
  }
};

export const deleteOfficeDetailsGroup = async (id: number) => {
  try {
    await deleteOfficeDetailsGroupApi(id);
    setOfficeDetailStore(
      produce((state) => {
        state.officeDetail!.groups = state.officeDetail!.groups.filter(
          (f) => f.id !== Number(id),
        );
      }),
    );
    handleToast(ToastType.Success, 'Office Group deleted');
  } catch (error) {
    handleToast(ToastType.Error, 'Deleting office Group failed');
  }
};

export const addEditGroup = async (payload: Group) => {
  try {
    const { id } = payload;
    const res = await addEditGroupApi(payload);

    setOfficeDetailStore(
      produce((state) => {
        if (id) {
          const index = state.officeDetail!.groups.findIndex(
            (f) => f.id === id,
          );
          if (index !== -1) {
            state.officeDetail!.groups[index] = res.data as Group;
          }
        } else {
          state.officeDetail!.groups.push(res.data as Group);
        }
      }),
    );

    handleToast(ToastType.Success, 'Office Group saved');
  } catch (error) {
    handleToast(ToastType.Error, 'Adding office Group failed');
  }
};

const getUpdatedRoles = (
  currentRoles: string[],
  role: string,
  checked: boolean,
): string[] => {
  if (checked) {
    return currentRoles.includes(role) ? currentRoles : [...currentRoles, role];
  }
  return currentRoles.filter((r) => r !== role);
};

export const updateUserRoleAction = async (
  checked: boolean,
  id: number,
  role: string,
) => {
  try {
    checked ? await addRoleApi(id, role) : await removeRoleApi(id, role);

    const users = officeDetailStore.officeDetail?.users;
    if (!users || users.length === 0) return;
    const userIndex = users.findIndex((user) => user.id === id);
    if (userIndex === -1) return;
    const updatedRoles = getUpdatedRoles(users[userIndex].roles, role, checked);
    setOfficeDetailStore(
      'officeDetail',
      'users',
      userIndex,
      'roles',
      updatedRoles,
    );
  } catch (error) {
    handleToast(ToastType.Error, 'Error updating user role:');
  }
};

export const userActive = async (id: number, isActive: boolean) => {
  try {
    await activeUserApi(id, isActive);
    const users = officeDetailStore.officeDetail?.users;
    if (users && users.length > 0) {
      const userIndex = users.findIndex((user) => user.id === id);
      if (userIndex !== -1) {
        setOfficeDetailStore(
          'officeDetail',
          'users',
          userIndex,
          'active',
          isActive,
        );
      }
    }
  } catch (error) {
    handleToast(ToastType.Error, 'Failed to update the status');
  }
};

export const createUserAction = async (data: UserCreateType) => {
  try {
    const response = await createUserApi(data);
    setOfficeDetailStore(
      produce((detail) => {
        const userData = { ...response.data };
        userData.name = `${userData.firstName} ${userData.lastName}`;
        detail.officeDetail?.users.push(userData as User);
      }),
    );
  } catch (error) {
    handleToast(ToastType.Error, 'Error Creating user');
  }
};

export const resetOfficeDetail = async () => {
  await userStore.userPromise;
  const userId = userStore.user.id;
  const defaultData = {
    ...DEFAULT_OFFICE_DETAIL,
    recruitedByUserId: String(userId),
  };
  setOfficeDetailStore(
    produce((detail) => {
      detail.isLoading = false;
      detail.officeDetail = defaultData;
      detail.isError = false;
      detail.officeDetailErrors = null;
      detail.officeDocuments = [];
      detail.Recruiters = [];
    }),
  );
};

export const getOfficeATGDataPermissionsAction = async (id: string) => {
  const data = await getATGUserDataApi();
  let permissions;
  if (data) {
    if (id === '63') {
      permissions = data.user.permissions.all;
    } else {
      permissions = difference(
        data.user.permissions.all,
        data.user.permissions.admin,
      );
    }
    const formattedPermissions = permissions.map((item) => ({
      label: item,
      value: item,
      disabled: !userHasPermission('Edit Permissions'),
    }));
    setOfficeDetailStore('permissionsOptions', formattedPermissions);
  }
};
