import { Checkbox, FormControlLabel, FormLabel } from '@suid/material';
import { SxProps } from '@suid/system';
import * as ST from '@suid/types';
import { Component, JSXElement, mergeProps } from 'solid-js';

import FormErrorComponent from './FormErrorComponent';
type CheckboxFieldProps = {
  name?: string;
  label: string | JSXElement;
  checked?: boolean;
  defaultChecked?: boolean;
  onChange?: (checked: boolean) => void;
  color?: 'primary' | 'secondary' | 'default';
  required?: boolean;
  error?: string | string[] | null;
  disabled?: boolean;
  inputProps?: ST.InferPropsType<typeof Checkbox>['inputProps'];
  id?: string;
  sxProps?: SxProps;
  size?: 'small' | 'medium';
  disableStyles?: boolean;
  class?: string;
  labelWidth?: string;
  checkboxColour?: string;
};
export const CheckboxInput: Component<CheckboxFieldProps> = (props) => {
  props = mergeProps(
    {
      required: false,
    },
    props,
  );

  const handleCheckboxChange = (
    _: ST.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    props.onChange && props.onChange(checked);
  };

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            id={props.id}
            class={'atg-checkbox-input'}
            size={props.size || 'small'}
            inputProps={props.inputProps}
            checked={props.checked}
            onChange={handleCheckboxChange}
            disabled={props.disabled}
            sx={{
              ...(Boolean(props.disableStyles)
                ? {
                    '&.Mui-disabled': {
                      color: 'text-[#000000] opacity-40',
                      padding: '0px',
                    },
                  }
                : undefined),
              color: Boolean(props.checkboxColour)
                ? props.checkboxColour
                : '#000000',
              '&.Mui-checked': {
                color: '#026ea1',
              },
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
            }}
          />
        }
        label={
          <FormLabel
            sx={{ fontSize: '14px', color: '#000000' }}
            required={props.required}
            component="legend"
            error={Boolean(props.error)}
            class={`${
              Boolean(props.disableStyles)
                ? '!text-[#000000]'
                : Boolean(props.disabled)
                  ? 'text-[#000000] opacity-40'
                  : ''
            } ${props.size === 'small' ? '!text-[14px]' : ''}`}
          >
            {props.label}
          </FormLabel>
        }
        labelPlacement="end"
        sx={{
          ...props.sxProps,
          marginRight: '0px',
          '& .MuiFormControlLabel-label': {
            color: '#000000',
            width: props.labelWidth,
          },
        }}
        class={props.class}
      />
      <FormErrorComponent error={props.error} />
    </>
  );
};
