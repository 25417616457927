import { BasicModal, ToastType, Typography } from '@components';
import { SelectField, TextInput } from '@components/forms';
import { closeModal } from '@store/modals';
import {
  addFlagCommentV4,
  CommentViewModel,
  deleteFlagComment,
  DisputedOrderFlagT,
  disputeFlag,
  FlagType,
  FlagViewModel,
  orderStore,
  setFlagComments,
  updateLoadPropertyAtIndex,
} from '@store/orders';
import { userStore } from '@store/user';
import CheckIcon from '@suid/icons-material/Check';
import DoDisturbIcon from '@suid/icons-material/DoDisturb';
import InfoOutlinedIcon from '@suid/icons-material/InfoOutlined';
import SettingsIcon from '@suid/icons-material/Settings';
import { Box, Button, Stack } from '@suid/material';
import { SelectChangeEvent } from '@suid/material/Select';
import { handleToast } from '@utils/utils';
import { createSignal } from 'solid-js';
import { unwrap } from 'solid-js/store';
import { effect } from 'solid-js/web';

import cls from './classes';
import {
  flagImgs,
  flagTypesWithMessaging,
  getOptionsByRole,
  infoMsgObj,
} from './constants';
import { LoadComment } from './LoadComment';
import { SpecialFlagTypeEnum } from './types';

type PropsT = {
  modalId: string;
  flag?: FlagViewModel;
  isLTL?: boolean;
  onClose?: () => void;
  onChange?: (
    flag: FlagViewModel,
    action: 'update' | 'create' | 'delete',
  ) => void;
  onDispute?: (flag: FlagViewModel) => void;
  loading?: boolean;
};

/**
 * The new flag popup is a reusable component. Make sure that the `onClose`
 * and `onChange` handle every state change, such as resetting or changing
 * the `flag` to a different value.
 */
// eslint-disable-next-line complexity
export const FlagPopup = (props: PropsT) => {
  const [selectedFlag, setSelectedFlag] = createSignal(
    props.flag ?? {
      description: '',
      flagType: FlagType.None,
    },
  );
  const [isModified, setIsModified] = createSignal(false);
  const [isNeedsApproval, setNeedsApproval] = createSignal(
    props.flag !== undefined &&
      props.flag.id !== undefined &&
      selectedFlag().flagType === FlagType.NeedsApproval,
  );
  const [message, setMessage] = createSignal('');
  const [disputedOrderFlags, setDisputedOrderFlags] = createSignal<
    DisputedOrderFlagT[]
  >(props.flag?.disputedOrderFlags ?? []);
  const [comments, setComments] = createSignal(props.flag?.comments ?? []);

  effect(() => {
    setNeedsApproval(
      props.flag !== undefined &&
        props.flag.id !== undefined &&
        selectedFlag().flagType === FlagType.NeedsApproval,
    );
    setComments(props.flag?.comments ?? []);

    if (isModified()) {
      return;
    }

    setDisputedOrderFlags(props.flag?.disputedOrderFlags ?? []);

    if (
      (props.flag !== undefined &&
        (props.flag.flagType !== selectedFlag().flagType ||
          props.flag.description !== selectedFlag().description ||
          props.flag.id !== selectedFlag().id)) ||
      props.flag === undefined
    ) {
      setSelectedFlag(
        props.flag ?? {
          description: '',
          flagType: FlagType.None,
        },
      );
    }
  });

  const onCloseModal = () => {
    const flag = props.flag;
    const currentComments = comments();

    setIsModified(false);
    setMessage('');
    setNeedsApproval(false);
    closeModal(props.modalId);
    props.onClose?.();

    const loadIdx = orderStore.order.loads.findIndex(
      (l) => l.id === (flag?.loadId ?? 0),
    );

    if (loadIdx >= 0) {
      const newLoads = [...orderStore.order.loads];
      const newFlags = newLoads[loadIdx].flags ?? [];
      const flagIdx = newFlags.findIndex((f) => f.id === flag?.id);

      setFlagComments(loadIdx, flagIdx, currentComments);
    }
  };

  const onCloseOrDelete = () => {
    if (selectedFlag().id !== undefined) {
      props.onChange?.(selectedFlag(), 'delete');
    } else {
      onCloseModal();
    }
  };

  const onNeedsApprovalAction = (stillWorking = false) => {
    if (stillWorking) {
      onCloseModal();
    } else {
      onCloseOrDelete();
    }
  };

  const isDisputable = () => {
    if (props.flag === undefined) {
      return false;
    }

    if (props.flag.cleared ?? false) {
      return false;
    } else if (disputedOrderFlags().length === 0) {
      return true;
    }

    return disputedOrderFlags().some((flag) => {
      return flag.clearedDate !== undefined && flag.clearedDate !== null;
    });
  };

  const onDisputed = async () => {
    try {
      const res = await disputeFlag(props.flag?.id ?? 0, userStore.user.name);

      if (res) {
        const newComment = res;
        setDisputedOrderFlags((prev) => {
          const flags = unwrap(prev);

          return [
            ...flags,
            {
              clearedBy: undefined,
              clearedDate: undefined,
              disputedDate:
                newComment.timestamp?.toString() ?? new Date().toISOString(),
              flagId: props.flag?.id ?? 0,
              id: '',
              operationType: 'None',
            },
          ];
        });

        setComments((prevComments) => {
          return [
            {
              operationType: res.operationType,
              id: res.id,
              objectId: res.objectId,
              userId: res.userId,
              name: res.name,
              type: res.type,
              comment: res.comment,
              timestamp: res.timestamp,
              thumbnail: res.thumbnail,
              createdBy: res.createdBy,
            },
            ...prevComments,
          ];
        });

        const updatedFlag: FlagViewModel = {
          ...selectedFlag(),
          comments: comments(),
          commentCount: comments().length,
          disputedOrderFlags: disputedOrderFlags(),
        };

        props.onDispute?.(updatedFlag);

        onCloseModal();
      }
    } catch (error) {
      handleToast(ToastType.Error, (error as Error).message);
    }
  };

  const handleAddComment = async (
    e: KeyboardEvent & { currentTarget: HTMLDivElement; target: Element },
  ) => {
    if (e.key === 'Enter') {
      const comment = (await addFlagCommentV4(
        props.flag?.id ?? 0,
        message(),
      )) as unknown as CommentViewModel;

      const updatedFlags = [
        ...(orderStore.order.loads[orderStore.activeTab.index]?.flags ?? []),
      ].map((flag) => {
        if (flag.id === props.flag?.id) {
          return {
            ...flag,
            comments: [...(flag.comments || []), comment],
          };
        }

        return flag;
      });

      updateLoadPropertyAtIndex({ flags: updatedFlags });
      setComments((prev) => {
        return [...prev, comment];
      });
      setMessage('');
    }
  };

  return (
    <BasicModal
      id={props.modalId}
      title="Add Flag"
      footer={false}
      onClose={onCloseModal}
      showClose={false}
      width="50vw"
      modalStyles={{
        background: 'white',
        borderRadius: '5px',
      }}
    >
      <Box>
        {isNeedsApproval() ? (
          <Box class={cls.headerPopup} mb="10px">
            <InfoOutlinedIcon class="text-#0070A2-700 text-lg mr-2" />
            <span
              class={cls.flagType}
              innerHTML={infoMsgObj[selectedFlag().flagType]}
            ></span>
          </Box>
        ) : undefined}
        <Stack direction="row" class={cls.flagTypeName} spacing="20px">
          <Stack sx={{ width: 'calc(100% - 120px)' }}>
            <SelectField
              label="Flag Type *"
              menuItems={getOptionsByRole(
                'admin',
                Boolean(orderStore.order.needsApprovalFlag),
              )}
              value={selectedFlag().flagType}
              onChange={(e: SelectChangeEvent) => {
                setIsModified(true);
                setSelectedFlag({
                  ...selectedFlag(),
                  flagType: e.target.value as FlagType,
                });
              }}
              sxProps={{ width: '100%' }}
            />
            {(selectedFlag().flagType !== FlagType.None &&
              (props.flag === undefined || props.flag.id === undefined)) ||
            (props.flag !== undefined &&
              props.flag.id !== undefined &&
              selectedFlag().flagType !== FlagType.NeedsApproval) ? (
              <div class={cls.infoOutlinedIcon}>
                <InfoOutlinedIcon class={cls.infoOutlinedIconClass} />
                <span
                  class={cls.flagType}
                  innerHTML={infoMsgObj[selectedFlag().flagType]}
                ></span>
              </div>
            ) : undefined}
          </Stack>
          <Box>
            <Box
              class={`w-[100px] h-[${isNeedsApproval() ? 56 : 100}px] ${
                isNeedsApproval() ? '' : 'border border-gray-300 p-1 py-2'
              } rounded flex items-center justify-center`}
            >
              {(selectedFlag().flagType !== FlagType.None &&
                (props.flag === undefined || props.flag.id === undefined)) ||
              (props.flag !== undefined &&
                props.flag.id !== undefined &&
                selectedFlag().flagType !== FlagType.NeedsApproval) ? (
                <img src={flagImgs[selectedFlag().flagType]} alt="alt flag" />
              ) : undefined}
            </Box>
          </Box>
        </Stack>
        {selectedFlag().flagType === FlagType.MissingPaperwork ? (
          <Box marginTop="20px">
            <TextInput
              label="Email ID *"
              placeholder="Add an email address"
              value={selectedFlag().emailId}
              classes="w-full"
              onChange={(emailId) => {
                setSelectedFlag((prev) => {
                  return {
                    ...prev,
                    emailId,
                  };
                });
              }}
            />
          </Box>
        ) : undefined}
        <Box marginTop="20px">
          <TextInput
            multiline={true}
            rows={isNeedsApproval() ? 2 : 5}
            value={selectedFlag().description}
            label="Description"
            placeholder={'Enter Description here'}
            classes="w-full"
            error={
              (selectedFlag().description?.length ?? 0) > 4000
                ? 'Description should not be greater than 4000 characters.'
                : undefined
            }
            onChange={(value) => {
              setIsModified(true);
              setSelectedFlag({
                ...selectedFlag(),
                description: value as string,
              });
            }}
          />
        </Box>
        {props.flag !== undefined &&
        flagTypesWithMessaging[
          props.flag.flagType as unknown as SpecialFlagTypeEnum
        ] ? (
          <Box mt="18px">
            <Box>
              <Typography
                class="text-black !text-xl !font-medium  !leading-8 !tracking-[0.15px]"
                variant="h6"
                component="h2"
                sxProps={{ marginBottom: '10px' }}
              >
                Message History
              </Typography>
              <TextInput
                value={message()}
                label="Add a Comment"
                classes="w-full"
                onChange={(value) => {
                  setMessage(value as string);
                }}
                onKeyDown={(e: KeyboardEvent) => {
                  void handleAddComment(e);
                }}
              />
            </Box>
            <Box maxHeight="250px" overflow="auto" marginTop="10px">
              <Stack direction="column">
                {comments()
                  .toReversed()
                  .map((comment) => {
                    if (!Boolean(comment)) {
                      return <></>;
                    }

                    return (
                      <LoadComment
                        comment={comment}
                        onDelete={async () => {
                          const res = (await deleteFlagComment(
                            comment.id,
                          )) as unknown as {
                            isSuccess: boolean;
                          };

                          if (res.isSuccess) {
                            const idx = comments().findIndex(
                              (c) => c.id === comment.id,
                            );
                            setComments((prev) => {
                              const newComments = [...prev];
                              newComments.splice(idx, 1);
                              return newComments;
                            });
                          }
                        }}
                      />
                    );
                  })}
              </Stack>
            </Box>
          </Box>
        ) : undefined}
        <Box class="flex justify-end mt-14" gap={3}>
          {isNeedsApproval() ? (
            <>
              <Button
                variant="contained"
                color="success"
                onClick={() => onNeedsApprovalAction()}
              >
                <CheckIcon fontSize="small" class={cls.iconSpacing} />
                Ok To Process
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => onNeedsApprovalAction(true)}
              >
                <SettingsIcon fontSize="small" class={cls.iconSpacing} />
                Still Working
              </Button>
              {props.isLTL ?? false ? (
                <Button
                  variant="contained"
                  color="warning"
                  disabled={!isDisputable()}
                  onClick={onDisputed}
                >
                  <DoDisturbIcon fontSize="small" class={cls.iconSpacing} />
                  Dispute
                </Button>
              ) : undefined}
            </>
          ) : (
            <>
              <Button
                variant="contained"
                onClick={() => {
                  const action =
                    selectedFlag().id !== undefined ? 'update' : 'create';
                  props.onChange?.(selectedFlag(), action);
                  setIsModified(false);
                }}
                disabled={
                  selectedFlag().flagType === FlagType.None ||
                  (selectedFlag().description?.length ?? 0) > 4000 ||
                  (props.loading !== undefined && props.loading) ||
                  (selectedFlag().flagType === FlagType.MissingPaperwork &&
                    (selectedFlag().emailId === undefined ||
                      selectedFlag().emailId === ''))
                }
              >
                {selectedFlag().id !== undefined
                  ? 'Update Flag'
                  : 'Create Flag'}
              </Button>
              {/* (Editable && !IsNew) || FlagType == '@(Globals.OrderFlags.BillingNote)' || FlagType == '@(Globals.OrderFlags.BillingHold)' */}
              {(selectedFlag().editable ?? false) &&
              props.flag !== undefined ? (
                <Button
                  onClick={onCloseOrDelete}
                  variant="contained"
                  color="error"
                >
                  {selectedFlag().id !== undefined ? 'Remove Flag' : 'Cancel'}
                </Button>
              ) : undefined}
            </>
          )}
          <Button onClick={onCloseModal}>Cancel</Button>
        </Box>
      </Box>
    </BasicModal>
  );
};
