import { apiClient } from '@api/apiClient';
import { handleToast } from '@utils/utils';
import { ToastType } from '@components';
import { createError } from '@utils/errorUtils';

import { CustomerContract } from './types';
import { V4ApiResponse } from '../types';

const fetchCustomerContracts = async (customerId: string, cb?: () => void) => {
  try {
    const response: V4ApiResponse<CustomerContract> = await apiClient.get(
      `/Customer/Contracts/${customerId}?toCurrency=NAN`,
    );
    if ('value' in response) {
      return response.value;
    }
  } catch (error) {
    const e = createError(error, 'Failed to fetch customer contracts');
    handleToast(ToastType.Error, e.message);
  } finally {
    cb && cb();
  }
};

const updateCustomerContractCall = async (
  customerContract: CustomerContract,
) => {
  try {
    await apiClient.post('/Customer/contracts', customerContract);
    handleToast(ToastType.Success, 'Customer contracts updated successfully');
  } catch (error) {
    const e = createError(error, 'Failed to update customer contracts');
    handleToast(ToastType.Error, e.message);
  }
};

export { fetchCustomerContracts, updateCustomerContractCall };
