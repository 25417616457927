const carrierInsuranceOptions = [
  {
    label: 'General Liability',
    key: 'carrierGeneralLiability',
    optionType: 'amount',
  },
  {
    label: 'Auto Liability',
    key: 'carrierAutoLiability',
    optionType: 'amount',
  },
  {
    label: 'BIPD',
    key: 'bipd',
    optionType: 'amount',
  },
  {
    label: 'Primary Cargo',
    key: 'primaryCargo',
    optionType: 'cargo',
  },
  {
    label: 'Refrigerated Cargo',
    key: 'refrigeratedCargo',
    optionType: 'cargo',
  },
  {
    label: 'Pollution/Environmental',
    key: 'carrierEnv',
    optionType: 'pollution',
  },
  {
    label: 'Workers Compensation',
    key: 'workersComp',
    optionType: 'worker',
  },
  {
    label: 'Cyber',
    key: 'carrierCyber',
    optionType: 'amount',
  },
];

const brokerInsuranceOptions = [
  {
    label: 'General Liability',
    key: 'generalLiability',
    optionType: 'amount',
  },
  {
    label: 'Auto Liability',
    key: 'generalAuto',
    optionType: 'amount',
  },
  {
    label: 'Contingent Cargo',
    key: 'contingentCargo',
    optionType: 'cargo',
  },
  {
    label: 'Pollution/Environmental',
    key: 'generalEnv',
    optionType: 'pollution',
  },
  {
    label: 'Cyber',
    key: 'generalCyber',
    optionType: 'amount',
  },
];

const temperatureControlledOptions = [
  {
    label: 'Customer requires all loads to have a seal',
    key: 'requiresSeal',
  },
  {
    label: 'Customer requires a temperature recorder',
    key: 'requiresTemperatureRecorder',
  },
];

const otherCarrierRequirementsOptions = [
  {
    label: 'Reefer download capability',
    key: 'reeferDownload',
  },
  {
    label: 'Co-Brokers Permitted',
    key: 'coBrokersPermitted',
  },
  {
    label: 'SATISFACTORY Rating only',
    key: 'satisfactoryRating',
  },
  {
    label: 'Driver must speak English',
    key: 'driverSpeaksEnglish',
  },
  {
    label: 'GPS/Tracking',
    key: 'gpsTracking', //check
  },
  {
    label: 'CARB Compliant',
    key: 'carbCompliant', //check
  },
  {
    label: 'C-TPAT Certified',
    key: 'ctpatCertified', //check
  },
  {
    label: 'SmartWay Certified',
    key: 'smartWayCertified',
  },
];

const carrierBrokerLiabilityOptions = [
  {
    label: 'Broker must assume liability of a carrier',
    key: 'brokerAssumesCarrierLiability',
  },
  {
    label: 'Carrier must assume full liability',
    key: 'carrierAssumesFullLiability',
  },
];

const brokerResponsibilityOptions = [
  {
    label: 'Provide POD within 24 hours',
    key: 'brokerPODWithin24Hours',
  },
  {
    label: 'Schedule appointments',
    key: 'brokerScheduleAppointments',
  },
  {
    label: 'Provide updates in transit',
    key: 'brokerProvideUpdatesInTransit',
  },
  {
    label: 'GPS/Tracking',
    key: 'brokerGPSTracking',
  },
];

const accessorialOptions = [
  {
    label: 'Rate Tender',
    key: 'rateTender',
  },
  {
    label: 'Signed BOL Required',
    key: 'signedBOLRequired',
  },
  {
    label: 'Receipts',
    key: 'receipts',
  },
  {
    label: 'Rate change approvals',
    key: 'rateChangeApprovals',
  },
  {
    label: 'Accessorial Sheet Provided',
    key: 'accessorialSheetProvided',
  },
];

const indemnificationOptions = [
  {
    label: 'Broker shall defend, indemnify and hold harmless contracting party',
    key: 'brokerIndemnification',
  },
];

const termAndPaymentTextAreaOptions = [
  {
    label: 'Governing Law State of',
    key: 'governingLawState',
  },
  {
    label: 'Proposed Rate Confirmation Language',
    key: 'proposedRateConfirmationLanguage',
  },
  {
    label: 'Other Language',
    key: 'otherLanguage',
  },
];

const claimAndConcealedDamageOptions = [
  {
    label: 'Shipper may file claim within',
    key: 'shipperFileTimeframe',
  },
  {
    label: 'Damage must be reported in',
    key: 'damageReportTimeframe',
  },
  {
    label: 'Concealed damage must be reported in',
    key: 'concealedDamageReportTimeframe',
  },
  {
    label: 'Claims must be paid to customer in',
    key: 'claimsPaidTimeframe',
  },
  {
    label: 'Time for notification of refusal of shipment',
    key: 'refusalNotificationTimeframe',
  },
  {
    label: 'Claim value will be based on',
    key: 'claimValueBasis',
    onlySelect: true,
  },
];

const claimAndConcealedDamageRadioOptions = [
  {
    label:
      'Customer reserves the right to offset invoices in the event of a claim',
    key: 'customerRightOffsetInvoice',
  },
];

const timeFrameOptions = [
  { label: 'Immediately', value: 'Immediately' },
  { label: '30 Days', value: '30 Days' },
  { label: '60 Days', value: '60 Days' },
  { label: '90 Days', value: '90 Days' },
  { label: '120 Days', value: '120 Days' },
  { label: '9 Months', value: '9 Months' },
  { label: '1 Year', value: '1 Year' },
  { label: '2 Years', value: '2 Years' },
  { label: 'Not Specified', value: 'Not Specified' },
  { label: 'Other(please specify)', value: '_other' },
];

const claimValueBasisOptions = [
  { label: 'Invoice Amount', value: 'Invoice Amount' },
  { label: 'Replacement Cost', value: 'Replacement Cost' },
  { label: 'Actual Loss', value: 'Actual Loss' },
  { label: 'Retail Value', value: 'Retail Value' },
  { label: 'Not Specified', value: 'Not Specified' },
];

const workerCompensationOptions = [
  { label: '$500,000.00', value: '5000000' },
  { label: '$1,000,000.00', value: '1000000' },
  { label: 'Statutory Requirements', value: 'Statutory Requirements' },
  { label: 'Other amount (please specify)', value: '_other' },
];

const amountOptions = [
  { label: '$1,000,000.00', value: '1000000' },
  { label: '$2,000,000.00', value: '2000000' },
  { label: '$5,000,000.00', value: '5000000' },
  { label: 'Other amount (please specify)', value: '-1' },
];

const cargoOptions = [
  { label: '$100,000.00', value: '100000' },
  { label: '$250,000.00', value: '250000' },
  { label: '$500,000.00', value: '500000' },
  { label: 'Other amount (please specify)', value: '-1' },
];

const pollutionOptions = [
  { label: '$500,000.00', value: '500000' },
  { label: '$1,000,000.00', value: '1000000' },
  { label: '$5,000,000.00', value: '5000000' },
  { label: 'Other amount (please specify)', value: '-1' },
];

const contractTerms = [
  {
    label: '1 Year',
    value: '1 Year',
  },
  {
    label: '3 Years',
    value: '3 Years',
  },
  {
    label: '5 Years',
    value: '5 Years',
  },
  {
    label: 'Other (please specify)',
    value: '_other',
  },
];

const contractPaymentTerms = [
  { label: '30 Days', value: '30 Days' },
  { label: '60 Days', value: '60 Days' },
  { label: '90 Days', value: '90 Days' },
  { label: '120 Days', value: '120 Days' },
  { label: '180 Months', value: '180 Months' },
  { label: 'Other (please specify)', value: '_other' },
];

const countries = [
  { label: 'US', value: 'US' },
  { label: 'Canada', value: 'Canada' },
  { label: 'Mexico', value: 'Mexico' },
];

const entities = [
  { label: 'Shipper', value: 'Shipper' },
  { label: 'Co-Broker', value: 'Co-Broker' },
  { label: 'Frieght Forwarder', value: 'Frieght Forwarder' },
  { label: 'Other (please specify)', value: '_other' },
];

export {
  carrierInsuranceOptions,
  brokerInsuranceOptions,
  temperatureControlledOptions,
  otherCarrierRequirementsOptions,
  carrierBrokerLiabilityOptions,
  brokerResponsibilityOptions,
  accessorialOptions,
  indemnificationOptions,
  termAndPaymentTextAreaOptions,
  claimAndConcealedDamageOptions,
  claimAndConcealedDamageRadioOptions,
  timeFrameOptions,
  claimValueBasisOptions,
  workerCompensationOptions,
  cargoOptions,
  pollutionOptions,
  amountOptions,
  contractTerms,
  contractPaymentTerms,
  countries,
  entities,
};
