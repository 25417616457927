export default {
  totalAmount:
    '!bg-[#0000000F]  !font-bold !text-[#171b1A] px-3 rounded-sm py-2',
  totalLabel: '!text-[#171b1A] !font-bold  !rounded-sm !overflow-hidden',
};

export const additionalCustomerDetailsClasses = {
  container: 'flex flex-col items-center justify-between [&>*]:!mb-3',
  headerContainer: '!w-full flex items-center justify-around',
  infoTooltip: 'text-[24px] text-[#026EA1] cursor-pointer m-2',
};

export const creditTaxEulerClasses = {
  textFields: 'flex-1',
  creditContainer:
    'bg-[#fff] flex flex-wrap items-center justify-around shadow-md !mr-3 p-2 py-2 ',
};

export const graphContainerClasses = {
  graphContainer:
    'flex items-center justify-center h-[107px] w-[263px] bg-[#fff] !m-1 border-r-[1px] border-gray-200 last:!border-r-0 relative',
  mainContainer: 'flex items-center gap-2 flex-col',
  absoluteClass: 'absolute z-10 top-[10%] text-center',
  titleClass: 'text-lg font-medium',
  priceClass: 'text-3xl font-semibold mt-2',
  shadowClass: 'shadow-md mb-1',
  graphIconClass: '!absolute !bottom-0',
};

export const insuranceClasses = {
  textFields: '!w-[100%] max-w-[100%] p-2 h-[37px]',
  creditContaoiner:
    'bg-[#fff] flex flex-wrap items-center justify-around shadow-md !mr-3',
  dateField: '!w-[100%] max-w-[100%] p-2',
  tableBg: 'bg-[#fff]',
  tableRow:
    ' !border-t-[1px] !border-[#e0e0e0] !bg-[#fff] [&:nth-child(1)]:!border-none ',
  deleteIcon: 'text-[#B00020] cursor-pointer',
  tableFooter:
    'text-base font-normal font-weight-700 leading-20.02 tracking-0.17 text-black !bg-[#fff]',
  tableFooterRow: 'border-t-[1px]    border-[#e0e0e0] ',
  addIcon: 'text-[#0070A2] cursor-pointer',
  mainContainer: 'mr-3 ml-[2px] mt-3 !gap-5',
  messageTextArea: '!mt-3',
};

export const invoicingClasses = {
  textFields: '!w-[100%] max-w-[100%] p-2',
  invoiceContainer: 'mr-3 ml-[2px] mt-3 !gap-5',
  fieldsContainer: '!mt-3',
  checkboxContainer: 'flex gap-2 flex-wrap border border-[#c4c4c4] rounded-md',
  checkboxInnerContainer:
    'w-full border-b border-[#c4c4c4] last:!border-0 !py-[1px] !px-2',
};

export const outstandingInvoicesClasses = {
  buttonClass: '!bg-[#468DB5] !text-[#fff]',
  badgeClass:
    'bg-[#fff] w-[20px] h-[20px] rounded-full text-black text-center !ml-2',
  cardStartClass: '!flex !items-center !justify-center',
};

export const addressClasses = {
  textFields: '!w-full max-w-[100%] !mb-3',
  buttonClass: '!bg-[#468DB5] !text-[#fff]',
};
