import {
  apiClient,
  apiFileUploadClient,
  v3Client,
  v3FileUploadClient,
} from '@api/apiClient';
import { ToastType } from '@components';
import { SavedQuoteInfo } from '@store/ltl';
import { Comments, LTLCatalogs } from '@store/orders';
import { createError } from '@utils/errorUtils';
import { handleToast } from '@utils/utils';

import { setEdiConfigurationStore } from './store';
import {
  AddOrEditCommentPayload,
  AddProductDetails,
  Comment,
  CreateEditRequestPayload,
  CreditRequestPayload,
  CustomerClaimHistoryItem,
  CustomerEditHistoryItem,
  CustomerGeneralDocuments,
  CustomerPaymentHistoryItem,
  DocumentDetails,
  ediConfigurationType,
  Group,
  ICreditRequest,
  ICustomerDetails,
  ICustomerUserNameExists,
  UploadedAutoAcceptDeclineSheet,
  V4ApiResponse,
} from './types';

const handleCustomerError = (error: unknown, toastMessage: string) => {
  if (Array.isArray(error)) {
    const errorMessages = error.map((v) => {
      if (typeof v === 'string') {
        return v;
      } else if (typeof v === 'object' && v !== null && 'errorMessage' in v) {
        return (v as { errorMessage: string }).errorMessage;
      }
    });
    handleToast(ToastType.Error, errorMessages.join(', '));
  } else {
    handleToast(ToastType.Error, toastMessage);
  }
};

export const fetchCustomerData = async (customerId: string) => {
  try {
    const response: V4ApiResponse<ICustomerDetails> = await apiClient.get(
      `Customer/getcustomerdata/${customerId}`,
    );
    if (response.isSuccess) {
      return response.value;
    }
  } catch (error: unknown) {
    handleToast(ToastType.Error, 'Failed to fetch customer detail Data');
  }
};

export async function addCustomerGeneralComment(
  payload: AddOrEditCommentPayload,
) {
  try {
    const response: V4ApiResponse<Comments> = await apiClient.post(
      'Customer/Comment',
      payload,
    );
    return response;
  } catch (e: unknown) {
    const error = createError(e, 'Adding carrier comments failed');
    handleToast(ToastType.Error, error.message);
  }
}
export async function editCustomerGeneralComment(
  payload: AddOrEditCommentPayload,
  cb?: () => void,
) {
  try {
    const response = await v3Client.post(
      `Comment/Update/${payload.id}`,
      payload,
    );
    return response.data as unknown as Record<string, string | number>;
  } catch (error: unknown) {
    throw createError(error, 'Failed to edit Comment on Customer general tab');
  } finally {
    cb && cb();
  }
}

export async function deleteCustomerGeneralComment(commentId: number) {
  try {
    const response: V4ApiResponse<Comment> = await apiClient.delete(
      `Customer/Comment/${commentId}`,
    );
    return response.isSuccess;
  } catch (error: unknown) {
    const e = createError(error, 'Failed to delete comment');
    handleToast(ToastType.Error, e.message);
  }
}

export const getCustomerUserNameExists = async (
  payload: ICustomerUserNameExists,
) => {
  try {
    const response: V4ApiResponse<boolean> = await apiClient.get(
      'Customer/CustomerUserNameExists',
      {
        params: {
          username: payload.username,
          customerId: payload.customerId,
          customercontactId: payload.customercontactId,
        },
      },
    );
    return response;
  } catch (error: unknown) {
    const e = createError(error, 'Failed to check username exists');
    handleToast(ToastType.Error, e.message);
  }
};

export const fetchCustomerDocumentType = async () => {
  try {
    const response: V4ApiResponse<DocumentDetails[]> = await apiClient.get(
      'customer/getdocumenttype',
    );
    return response;
  } catch (error: unknown) {
    const e = createError(error, 'Failed to fetch customer document type');
    handleToast(ToastType.Error, e.message);
  }
};

export async function fetchCustomerEditHistory(id: string) {
  try {
    const response: V4ApiResponse<CustomerEditHistoryItem[]> =
      await apiClient.get(`customer/edithistory/${id}`);
    return response.value;
  } catch (error: unknown) {
    const e = createError(
      error,
      'Failed to fetch customer edit history. Please try again later',
    );
    handleToast(ToastType.Error, e.message);
    throw e;
  }
}

export async function getCustomerClaimsHistory(id: string) {
  try {
    const response: V4ApiResponse<CustomerClaimHistoryItem[]> =
      await apiClient.get(`/customer/claimhistory/${id}`);
    return response.value;
  } catch (error: unknown) {
    const e = createError(
      error,
      'Failed to fetch customer claim history. Please try again later.',
    );
    handleToast(ToastType.Error, e.message);
  }
}
export const fetchCustomerPaymentHistory = async (customerId: string) => {
  try {
    const response: V4ApiResponse<CustomerPaymentHistoryItem[]> =
      await apiClient.get(
        `Customer/CustomerPayments/${customerId}?Id=${customerId}`,
      );

    return response;
  } catch (error: unknown) {
    handleToast(ToastType.Error, 'Failed to fetch customer payment history');
    throw createError(error, 'Failed to fetch customer payment history');
  }
};

export const addProductDetails = async (payload: AddProductDetails) => {
  try {
    const response = await apiClient.post('LtlCatalog', payload);
    return response as unknown as AddProductDetails;
  } catch (error: unknown) {
    const e = createError(error, 'Failed to Add Product Details');
    handleToast(ToastType.Error, e.message);
  }
};

export const editProductDetails = async (payload: AddProductDetails) => {
  try {
    const response = await apiClient.put('LtlCatalog', payload);
    return response as unknown as AddProductDetails;
  } catch (error: unknown) {
    const e = createError(error, 'Failed to Edit Product Details');
    handleToast(ToastType.Error, e.message);
  }
};

export const deleteProductDetails = async (id: string) => {
  try {
    const res: {
      isSuccess: boolean;
    } = await apiClient.delete(`LtlCatalog/${id}`);
    if (Boolean(res.isSuccess)) {
      handleToast(ToastType.Success, 'Product Deleted Successfully');
      return res;
    }
    handleToast(ToastType.Error, 'Failed to delete product');
  } catch (error: unknown) {
    throw createError(error, 'Failed to Delete Product Details');
  }
};

export const fetchProductById = async (id: string) => {
  try {
    const response = await apiClient.get(`LtlCatalog/${id}`);
    return response as unknown as AddProductDetails;
  } catch (error: unknown) {
    handleToast(ToastType.Error, 'Failed to fetch product by id');
    throw createError(error, 'Failed to fetch product by id');
  }
};

export async function fetchCapacityGroups() {
  try {
    const response: V4ApiResponse<Group[]> = await apiClient.get(
      'group/capacitygroups',
    );
    return response;
  } catch (error: unknown) {
    const e = createError(error, 'Failed to fetch customer capacity groups');
    handleToast(ToastType.Error, e.message);
  }
}

export const fetchEdiConfiguration = async (customerId: string) => {
  try {
    const response: V4ApiResponse<ediConfigurationType> = await apiClient.get(
      `Edi/GetEDIConfigurationsForCustomer/${customerId}`,
    );
    return response;
  } catch (error: unknown) {
    const e = createError(error, 'Failed to fetch edi configuration');
    handleToast(ToastType.Error, e.message);
  }
};

export const fetchEdiProviders = async () => {
  try {
    const response: V4ApiResponse<string[]> = await apiClient.get(
      'EdiConfig/getproviders',
    );
    return response;
  } catch (error: unknown) {
    const e = createError(error, 'Failed to fetch edi providers');
    handleToast(ToastType.Error, e.message);
  }
};

export const sendEdiConfigurationData = async (
  data: unknown,
  cb?: () => void,
) => {
  try {
    const response: V4ApiResponse<ediConfigurationType> = await apiClient.post(
      'Edi/saveediconfigurations',
      data,
    );
    return response;
  } catch (error: unknown) {
    const e = createError(error, 'Failed to save edi configuration');
    handleToast(ToastType.Error, e.message);
  } finally {
    cb && cb();
  }
};
export const fetchCustomerSavedQuotes = async (customerId: string) => {
  try {
    const response = await v3Client.get(
      `LTL/SavedQuotesByCustomerId?customerId=${customerId}`,
    );
    const values = response as unknown as { data: SavedQuoteInfo[] };
    return values.data;
  } catch (error: unknown) {
    handleToast(ToastType.Error, 'Failed to fetch Customer Saved Quotes Data');
    throw createError(error, 'Failed to Fetch Customer Saved Quotes Data');
  }
};

export const uploadProductCatalog = async (id: string, file: File) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const response = await v3FileUploadClient.post(
      `LTLCatalog/Import/${id}`,
      formData,
    );
    return response as unknown as {
      success: boolean;
      message: string;
      data: {
        validEntities: LTLCatalogs[];
      };
    };
  } catch (error: unknown) {
    const e = createError(error, 'Failed to upload product catalog');
    handleToast(ToastType.Error, e.message);
  }
};

export const uploadQuoteFile = async (id: string, file: File) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const response = await v3FileUploadClient.post(
      `Quote/Import/${id}`,
      formData,
    );
    const resp = response as unknown as {
      success: boolean;
      message: string;
      data: {
        imported: string[];
        notImported: {
          name: string;
          reason: string;
        }[];
      };
    };
    if (resp.data.imported.length > 0) {
      handleToast(ToastType.Success, 'Quote file uploaded successfully');
      return true;
    } else if (resp.data.notImported.length > 0) {
      handleToast(ToastType.Error, resp.data.notImported[0].reason);
      return false;
    }
  } catch (error: unknown) {
    throw createError(error, 'Failed to upload quote file');
  }
};

export const deleteCollectionCommentsCall = async (commentId: number) => {
  try {
    const response: V4ApiResponse<Comment> = await apiClient.delete(
      `Customer/Comment/${commentId}`,
    );
    if ('isSuccess' in response) {
      handleToast(ToastType.Success, 'Collection comment deleted successfully');
      return true;
    }
  } catch (error: unknown) {
    handleToast(ToastType.Error, 'Failed to delete collection comment');
  }
};

export const addCollectionCommentCall = async (
  customerId: number,
  comment: string,
) => {
  try {
    const resp = await apiClient.post('Customer/Comment', {
      comment,
      objectId: customerId,
      type: 'Collections',
    });
    if ('isSuccess' in resp && 'value' in resp) {
      handleToast(ToastType.Success, 'Collection comment added successfully');
      return resp.value;
    }
  } catch (error: unknown) {
    handleToast(ToastType.Error, 'Failed to add collection comment');
  }
};

export const editCollectionComment = async (payload: {
  id: number;
  comment: string;
  objectId: number;
  type: string;
}) => {
  try {
    const resp: V4ApiResponse<Comment[]> = await apiClient.put(
      'Customer/Comment',
      payload,
    );
    return resp;
  } catch (error: unknown) {
    handleCustomerError(error, 'Failed to edit collection comment');
  }
};

export const creditIncreaseCall = async (payload: ICreditRequest) => {
  try {
    const res: V4ApiResponse<null> = await apiClient.post(
      'Request/CreditIncrease',
      payload,
    );
    return res;
  } catch (error: unknown) {
    const e = createError(error, 'Failed to increase credit');
    handleToast(ToastType.Error, e.message);
  }
};

export const updateCustomerDetailsCall = async (payload: ICustomerDetails) => {
  try {
    const res: V4ApiResponse<ICustomerDetails> = await apiClient.put(
      'Customer',
      payload,
    );

    if (Boolean(res.isSuccess)) {
      handleToast(ToastType.Success, 'Customer changes saved');
      return res.value;
    }
  } catch (error: unknown) {
    handleCustomerError(error, 'Failed to update customer details');
  }
};

export const createEdiRequestCall = async (
  payload: CreateEditRequestPayload,
) => {
  try {
    const response: { success: boolean } = await v3Client.post(
      'Request/EDISetup',
      payload,
    );
    return response;
  } catch (error: unknown) {
    throw createError(error, 'Failed to create edi request');
  }
};

export const customerCreditRequest = async (payload: CreditRequestPayload) => {
  try {
    const response: V4ApiResponse<null> = await apiClient.post(
      'Request/creditrequestresponse',
      payload,
    );
    return response;
  } catch (error: unknown) {
    const e = createError(error, 'Request Resolved failed manually');
    handleToast(ToastType.Error, e.message);
  }
};

export const fetchCustomerDetailsDocuments = async (customerId: string) => {
  try {
    const response: V4ApiResponse<CustomerGeneralDocuments[]> =
      await apiClient.get(`customer/getdocument/${customerId}`);
    return response;
  } catch (error: unknown) {
    const e = createError(error, 'Failed to fetch customer document data');
    handleToast(ToastType.Error, e.message);
  }
};

export const uploadCustomerDetailsDocument = async (
  customerId: string,
  file: File,
  type: 'Public' | 'Confidential' | 'Misc' | 'Logo',
) => {
  try {
    const formData = new FormData();
    formData.append('Files', file);
    formData.append('DocumentEntityType', '5');
    formData.append('EntityId', customerId.toString());
    formData.append('Folder', type);
    const res: V4ApiResponse<{
      results: { documentId: number; name: string; url: string }[];
    }> = await apiClient.post('customer/uploaddocument', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return res.value;
  } catch (error: unknown) {
    const e = createError(error, 'Failed to upload customer document');
    handleToast(ToastType.Error, e.message);
  }
};

export const deleteCustomerDetailsDocument = async (payload: {
  file: string;
  type: string;
}) => {
  try {
    const response: V4ApiResponse<null> = await apiClient.delete(
      'customer/deletedocument',
      {
        data: payload,
      },
    );
    return response.isSuccess;
  } catch (error: unknown) {
    const e = createError(error, 'Failed to delete customer document');
    handleToast(ToastType.Error, e.message);
  }
};

export const updateCustomerDefaultLogoUrl = async (payload: {
  defaultLogoUrl: string | null;
  id: number;
}) => {
  try {
    const response: V4ApiResponse<null> = await apiClient.put(
      `Customer/UpdateDefaultLogoUrl/${payload.id}`,
      payload,
    );
    return response;
  } catch (error: unknown) {
    const e = createError(error, 'Failed to update customer default logo url');
    handleToast(ToastType.Error, e.message);
  }
};

export async function fetchCustomerOfficeGroupsCall(officeId: number) {
  try {
    const response: V4ApiResponse<Group[]> = await apiClient.get(
      `Office/Groups/${officeId}?Id=${officeId}`,
    );
    return response;
  } catch (error: unknown) {
    const e = createError(error, 'There was a issue fetching office groups.');
    handleToast(ToastType.Error, e.message);
  }
}

export const updateCustomerComment = async (
  payload: Record<string, string | number | null>,
) => {
  try {
    const resp: V4ApiResponse<Comment[]> = await apiClient.put(
      'Customer/Comment',
      payload,
    );
    return resp.isSuccess;
  } catch (error: unknown) {
    const e = createError(error, 'Updating customer comments failed');
    handleToast(ToastType.Error, e.message);
  }
};

export const fetchEDIConfigIsTenderAutoResponseAuto990Decline = async (
  customerId: string,
) => {
  try {
    const response: V4ApiResponse<boolean> = await apiClient.get(
      `FeatureFlag/IsTenderAuto990AcceptDecline/${customerId}`,
    );
    return response;
  } catch (error: unknown) {
    const e = createError(
      error,
      'Failed to fetch customer tender auto response data',
    );
    handleToast(ToastType.Error, e.message);
  }
};

export const fetchUploadedAutoAcceptDeclineSheet = async (
  customerId: string,
) => {
  try {
    const response: V4ApiResponse<UploadedAutoAcceptDeclineSheet> =
      await apiClient.get(
        `EDI/GetUploadedAutoAcceptDeclineSheet/${customerId}`,
      );
    return response;
  } catch (error: unknown) {
    const e = createError(
      error,
      'Failed to fetch uploaded auto accept decline sheet',
    );
    handleToast(ToastType.Error, e.message);
  }
};

const handleError = (error: unknown, toastMessage: string) => {
  if (Array.isArray(error)) {
    const errorMessages = error.map((v) => {
      if (typeof v === 'string') {
        return v;
      } else if (typeof v === 'object' && v !== null && 'errorMessage' in v) {
        return (v as { errorMessage: string }).errorMessage;
      }
      return 'Unspecified/unknown error';
    });
    setEdiConfigurationStore('errorMessage', errorMessages.join(', '));
  } else {
    throw new Error(toastMessage);
  }
  handleToast(ToastType.Error, toastMessage);
};

export const uploadAutoAcceptDeclineSheet = async (
  customerId: string,
  file: File,
) => {
  try {
    const formData = new FormData();
    formData.append('formFile', file);
    const response: V4ApiResponse<null> = await apiFileUploadClient.post(
      `EDI/UploadAutoAcceptDeclineSheet/${customerId}`,
      formData,
    );
    return response;
  } catch (error: unknown) {
    handleError(error, 'Failed to upload auto accept decline sheet');
  }
};

export const toggleAutoAcceptDecline = async (
  customerId: string,
  enabled: boolean,
) => {
  try {
    const response: V4ApiResponse<null> = await apiClient.post(
      'edi/ToggleAutoAcceptDecline',
      {
        customerId,
        enabled,
      },
    );
    return response;
  } catch (error: unknown) {
    const e = createError(error, 'Failed to toggle auto accept decline');
    handleToast(ToastType.Error, e.message);
  }
};
