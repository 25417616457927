import { lazy } from 'solid-js';

const CarrierMatching = lazy(() => import('./CarrierMatching'));
const Offers = lazy(() => import('./Offers'));

export const CarrierMatchingRoute = {
  path: '/carrier/matching',
  component: CarrierMatching,
};

export const CarrierMatchingOffersRoute = {
  pathRep: '/carrier/matching/offers',
  pathManager: '/carrier/matching/manager',
  component: Offers,
};
