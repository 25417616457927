import { ColDef, IRichCellEditorParams } from '@ag-grid-community/core';
import { equipmentValues } from '@common/commonLists';
import FlagTypeCell from '@components/Grid/components/FlagTypeCell';
import LoadBoardStatusCell from '@components/Grid/components/LoadBoardStatusCell';
import RateConCellRenderer from '@components/Grid/components/RateConCellRenderer';
import RenderPostings from '@components/Grid/components/RenderPostings';
import { TrackingCellRenderer } from '@components/Grid/components/TrackingCell';
import { memoize } from 'lodash';
import { DateTime } from 'luxon';

import { ValuesOf } from './types';
import {
  currencyCellFormatter,
  dateComparator,
  dateFormatter,
  fullDateFormatterYear,
  fullDateFormatterYearNoTime,
  hourAndMinuteMatcherFromText,
  lastCheckDateFormatter,
  phoneNumberCellFormatter,
  weightValueCellFormatter,
} from './utils';

export { dateFormatter };

const currencyCellFormatterMemoized = memoize(currencyCellFormatter);

export const jsDateToDateString = (param: {
  value: Date | null | undefined;
}) => {
  const date = param.value;

  if (date !== null && date !== undefined) {
    const modDate = new Date(
      date.toString().includes('Z') ? date.toString() : date.toString() + 'Z',
    );
    const dt = DateTime.fromJSDate(modDate).toFormat(
      'EEE, LLL dd, yyyy h:mm a',
    );
    return dt;
  }
  return '';
};

export const nonClickableColumns = [
  'status',
  'postingId',
  'confirmationSigned',
];

const loadboardStatusArray = [
  'Assigned',
  'Unassigned',
  'Dispatched',
  'Loaded',
  'At Shipper',
  'At Receiver',
  'Out On Delivery',
  'In Transit',
  'Empty',
  'Can Get',
];

export const namedFilterParams = {
  filters: [
    {
      filter: 'agSetColumnFilter',
    },
    {
      filter: 'agTextColumnFilter',
      filterParams: {
        maxNumConditions: 10,
        numAlwaysVisibleConditions: 1,
      },
    },
  ],
};

export const mainLeadingColumns: ColDef[] = [
  {
    field: 'customerName',
    headerName: 'Customer',
    initialWidth: 200,
    resizable: true,
    hide: false,
    filter: 'agTextColumnFilter',
    filterParams: namedFilterParams,
    wrapText: true,
    cellClass: 'my-custom-cell-class', // Add this line
  },
  {
    field: 'carrierName',
    headerName: 'Carrier',
    initialWidth: 200,
    resizable: true,
    hide: false,
    filter: 'agTextColumnFilter',
    filterParams: namedFilterParams,
    wrapText: true,
    cellClass: 'my-custom-cell-class', // Add this line
  },
  {
    field: 'equipment',
    headerName: 'Equipment',
    initialWidth: 120,
    resizable: true,
    hide: false,
    filter: 'agSetColumnFilter',
    filterParams: {
      values: equipmentValues,
    },
    wrapText: true,
    cellClass: 'my-custom-cell-class', // Add this line
  },
  {
    field: 'stopsRatio',
    headerName: 'Stops',
    initialWidth: 120,
    resizable: true,
    hide: false,
    filter: 'agTextColumnFilter',
    wrapText: true,
    cellClass: 'my-custom-cell-class', // Add this line
  },
  {
    field: 'origin',
    headerName: 'Origin',
    initialWidth: 200,
    resizable: true,
    hide: false,
    filter: 'agTextColumnFilter',
    cellRenderer: 'underlinedCellRenderer',
    wrapText: true,
    cellClass: 'my-custom-cell-class', // Add this line
  },
  {
    field: 'originCity',
    headerName: 'Origin City',
    initialWidth: 150,
    resizable: true,
    hide: false,
    filter: 'agTextColumnFilter',
    cellRenderer: 'underlinedCellRenderer',
    wrapText: true,
    cellClass: 'my-custom-cell-class', // Add this line
  },
  {
    field: 'originState',
    headerName: 'Origin State',
    initialWidth: 150,
    resizable: true,
    hide: false,
    filter: 'agTextColumnFilter',
    cellRenderer: 'underlinedCellRenderer',
    wrapText: true,
    cellClass: 'my-custom-cell-class', // Add this line
  },
  {
    field: 'originCountry',
    headerName: 'Origin Country',
    initialWidth: 150,
    resizable: true,
    hide: false,
    filter: 'agTextColumnFilter',
    cellRenderer: 'underlinedCellRenderer',
    wrapText: true,
    cellClass: 'my-custom-cell-class', // Add this line
  },
  {
    field: 'destination',
    headerName: 'Destination',
    initialWidth: 200,
    resizable: true,
    hide: false,
    filter: 'agTextColumnFilter',
    cellRenderer: 'underlinedCellRenderer',
    wrapText: true,
    cellClass: 'my-custom-cell-class', // Add this line
  },
  {
    field: 'destinationCity',
    headerName: 'Destination City',
    initialWidth: 150,
    resizable: true,
    hide: false,
    filter: 'agTextColumnFilter',
    cellRenderer: 'underlinedCellRenderer',
    wrapText: true,
    cellClass: 'my-custom-cell-class', // Add this line
  },
  {
    field: 'destinationState',
    headerName: 'Destination State',
    initialWidth: 150,
    resizable: true,
    hide: false,
    filter: 'agTextColumnFilter',
    cellRenderer: 'underlinedCellRenderer',
    wrapText: true,
    cellClass: 'my-custom-cell-class', // Add this line
  },
  {
    field: 'destinationCountry',
    headerName: 'Destination Country',
    initialWidth: 150,
    resizable: true,
    hide: false,
    filter: 'agTextColumnFilter',
    cellRenderer: 'underlinedCellRenderer',
    wrapText: true,
    cellClass: 'my-custom-cell-class', // Add this line
  },
  {
    field: 'pickDate',
    headerName: 'Pickup',
    initialWidth: 120,
    resizable: true,
    hide: false,
    filter: 'agDateColumnFilter',
    cellRenderer: 'underlinedCellRenderer',
    wrapText: true,
    cellClass: 'my-custom-cell-class', // Add this line
  },
  {
    field: 'pickUpTime',
    headerName: 'Pickup Time',
    initialWidth: 120,
    resizable: true,
    hide: false,
    filter: 'agTextColumnFilter',
    wrapText: true,
    cellClass: 'my-custom-cell-class', // Add this line
  },
  {
    field: 'dropDate',
    headerName: 'Drop Off',
    initialWidth: 120,
    resizable: true,
    hide: false,
    filter: 'agDateColumnFilter',
    cellRenderer: 'underlinedCellRenderer',
    wrapText: true,
    cellClass: 'my-custom-cell-class', // Add this line
  },
  {
    field: 'dropOffTime',
    headerName: 'Dropoff Time',
    initialWidth: 120,
    resizable: true,
    hide: false,
    filter: 'agTextColumnFilter',
    wrapText: true,
    cellClass: 'my-custom-cell-class', // Add this line
  },
  {
    field: 'status',
    initialWidth: 180,
    resizable: true,
    hide: false,
    filter: 'agSetColumnFilter',
    filterParams: {
      values: loadboardStatusArray,
    },
    cellRenderer: LoadBoardStatusCell,
  },
  {
    field: 'owner',
    initialWidth: 200,
    resizable: true,
    hide: false,
    filter: 'agTextColumn',
    wrapText: true,
    cellClass: 'my-custom-cell-class', // Add this line
  },
  {
    field: 'assignedTo',
    initialWidth: 200,
    resizable: true,
    hide: false,
    filter: 'agTextColumnFilter',
    filterParams: namedFilterParams,
    wrapText: true,
    cellClass: 'my-custom-cell-class', // Add this line
  },
  {
    field: 'flagType',
    headerName: 'Flag',
    initialWidth: 110,
    resizable: true,
    hide: false,
    filter: 'agSetColumnFilter',
    cellRenderer: FlagTypeCell,
    wrapText: true,
    cellClass: 'my-custom-cell-class', // Add this line
  },
  {
    field: 'mode',
    initialWidth: 120,
    resizable: true,
    hide: false,
    filter: 'agSetColumnFilter',
    wrapText: true,
    cellClass: 'my-custom-cell-class', // Add this line
  },
  {
    field: 'office',
    initialWidth: 200,
    resizable: true,
    hide: false,
    filter: 'agTextColumnFilter',
    wrapText: true,
    cellClass: 'my-custom-cell-class', // Add this line
  },
  {
    field: 'confirmationSigned',
    initialWidth: 150,
    filter: false,
    headerName: 'Rate Con',
    cellRenderer: RateConCellRenderer,
    wrapText: true,
    cellClass: 'my-custom-cell-class', // Add this line
  },
  {
    field: 'hasTracking',
    minWidth: 80,
    width: 80,
    resizable: true,
    hide: false,
    headerName: 'Tracking',
    cellRenderer: TrackingCellRenderer,
    wrapText: true,
  },
  {
    field: 'lastCheckCallDate',
    headerName: 'Last Check Call Date',
    initialWidth: 200,
    resizable: true,
    filter: 'agMultiColumnFilter',
    filterParams: {
      filters: [
        {
          filter: 'agDateColumnFilter',
          filterParams: {
            comparator: dateComparator,
          },
        },
        {
          filter: 'agTextColumnFilter',
          filterParams: {
            maxNumConditions: 1,
            filterPlaceholder: 'Enter HH:mm time',
            trimInput: true,
            textMatcher: hourAndMinuteMatcherFromText,
          },
        },
      ],
    },
    valueFormatter: memoize(lastCheckDateFormatter),
    wrapText: true,
    cellClass: 'my-custom-cell-class', // Add this line
  },
  {
    field: 'lastCheckCallComment',
    headerName: 'Last Check Call Comment',
    initialWidth: 200,
    resizable: true,
    wrapText: true,
    cellClass: 'my-custom-cell-class', // Add this line
  },
  {
    field: 'nextCheckCallDateUtc',
    headerName: 'Next Check Call Date',
    initialWidth: 200,
    resizable: true,
    filter: 'agMultiColumnFilter',
    filterParams: {
      filters: [
        {
          filter: 'agDateColumnFilter',
          filterParams: {
            comparator: dateComparator,
          },
        },
        {
          filter: 'agTextColumnFilter',
          filterParams: {
            maxNumConditions: 1,
            filterPlaceholder: 'Enter HH:mm time',
            trimInput: true,
            textMatcher: hourAndMinuteMatcherFromText,
          },
        },
      ],
    },
    valueFormatter: memoize(fullDateFormatterYear),
    wrapText: true,
    cellClass: 'my-custom-cell-class', // Add this line
  },
  {
    field: 'pendingBids',
    initialWidth: 100,
    resizable: true,
    hide: false,
    filter: 'agNumberColumnFilter',
    wrapText: true,
    cellClass: 'my-custom-cell-class', // Add this line
  },
  {
    field: 'totalBids',
    initialWidth: 100,
    resizable: true,
    hide: false,
    filter: 'agNumberColumnFilter',
    wrapText: true,
    cellClass: 'my-custom-cell-class', // Add this line
  },
  {
    field: 'loadCreatedDateUtc',
    headerName: 'Load Created Date',
    initialWidth: 200,
    resizable: true,
    filter: 'agTextColumnFilter',
    valueFormatter: memoize(fullDateFormatterYearNoTime),
    wrapText: true,
    cellClass: 'my-custom-cell-class', // Add this line
  },
  {
    field: 'maxPrice',
    headerName: 'Max Rate',
    initialWidth: 125,
    resizable: true,
    filter: 'agNumberColumnFilter',
    valueFormatter: currencyCellFormatterMemoized,
    wrapText: true,
    cellClass: 'my-custom-cell-class', // Add this line
  },
  {
    field: 'minPrice',
    headerName: 'Starting Rate',
    initialWidth: 125,
    resizable: true,
    filter: 'agNumberColumnFilter',
    valueFormatter: currencyCellFormatterMemoized,
    wrapText: true,
    cellClass: 'my-custom-cell-class', // Add this line
  },
  {
    field: 'cargoValue',
    headerName: 'Cargo Value',
    initialWidth: 200,
    resizable: true,
    filter: 'agNumberColumnFilter',
    valueFormatter: currencyCellFormatterMemoized,
    wrapText: true,
    cellClass: 'my-custom-cell-class', // Add this line
  },
  {
    field: 'driverPhone',
    headerName: 'Driver Contact',
    initialWidth: 200,
    resizable: true,
    filter: 'agTextColumnFilter',
    valueFormatter: memoize(phoneNumberCellFormatter),
    wrapText: true,
    cellClass: 'my-custom-cell-class', // Add this line
  },
  {
    field: 'weightKg',
    headerName: 'Weight (kg)',
    initialWidth: 150,
    resizable: true,
    filter: 'agNumberColumnFilter',
    valueFormatter: memoize(weightValueCellFormatter),
    wrapText: true,
    cellClass: 'my-custom-cell-class', // Add this line
  },
  {
    field: 'weightLbs',
    headerName: 'Weight (lbs)',
    initialWidth: 150,
    resizable: true,
    filter: 'agNumberColumnFilter',
    valueFormatter: memoize(weightValueCellFormatter),
    wrapText: true,
    cellClass: 'my-custom-cell-class', // Add this line
  },
  {
    field: 'carrierPay',
    headerName: 'Carrier Pay',
    initialWidth: 200,
    resizable: true,
    filter: 'agNumberColumnFilter',
    valueFormatter: currencyCellFormatterMemoized,
    wrapText: true,
    cellClass: 'my-custom-cell-class', // Add this line
  },
  {
    field: 'customerPay',
    headerName: 'Customer Pay',
    initialWidth: 200,
    resizable: true,
    filter: 'agNumberColumnFilter',
    valueFormatter: currencyCellFormatterMemoized,
    wrapText: true,
    cellClass: 'my-custom-cell-class', // Add this line
  },
  {
    field: 'unfilteredLastCheckCallDate',
    headerName: 'Last Comment Date',
    initialWidth: 200,
    resizable: true,
    filter: 'agMultiColumnFilter',
    filterParams: {
      filters: [
        {
          filter: 'agDateColumnFilter',
          filterParams: {
            comparator: dateComparator,
          },
        },
        {
          filter: 'agTextColumnFilter',
          filterParams: {
            maxNumConditions: 1,
            filterPlaceholder: 'Enter HH:mm time',
            trimInput: true,
            textMatcher: hourAndMinuteMatcherFromText,
          },
        },
      ],
    },
    valueFormatter: memoize(fullDateFormatterYear),
    wrapText: true,
    cellClass: 'my-custom-cell-class', // Add this line
  },
  {
    field: 'unfilteredLastCheckCallComment',
    headerName: 'All Comments',
    initialWidth: 200,
    resizable: true,
    wrapText: true,
    cellClass: 'my-custom-cell-class', // Add this line
  },
];

// There cannot be duplicate column names here. If you move or add a column make sure
// it's not in here twice. It will cause problems with AG Grid.
const defaultColumns = [
  {
    field: 'postingId',
    headerName: 'Post',
    minWidth: 80,
    width: 80,
    resizable: true,
    hide: false,
    cellRenderer: RenderPostings,
    filter: false,
    wrapText: true,
    cellClass: 'my-custom-cell-class', // Add this line
  },
  {
    field: 'loadNumber',
    headerName: 'LoadNum',
    initialWidth: 120,
    resizable: true,
    hide: false,
    filter: 'agTextColumnFilter',
    // This is an example of how we can restrict allowed pivot functions for certain columns.
    allowedAggFuncs: ['count'],
    wrapText: true,
    cellClass: 'my-custom-cell-class', // Add this line
  },

  ...mainLeadingColumns,
];
const textColumns: [string, number, string?][] = [
  ['referenceNumber', 200],
  ['claim', 100],
  ['product', 150],
  ['miles', 100, 'agNumberColumnFilter'],
  ['sharedCapacity', 150],
  ['trackingNumber', 150],
  ['proNumber', 150],
  ['stopPickup', 150],
  ['stopDropoff', 150],
  ['my', 100],
  ['myCustomers', 100],
  ['accountManager', 200],
  ['salesManager', 200],
];

const namedFields: [string, number][] = [
  // ['assignedTo', 200],
  ['coveredBy', 200],
  // ['accountManager', 200],
  // ['customerName', 200],
  // ['carrierName', 200],
  // ['salesManager', 200],
  // ['equipment', 120],
];

export const calculateNewHeight = (
  comment: string | undefined | null,
  colWidth: number,
): number | undefined => {
  const height = 12;
  const lineHeight = height * 1.332;

  if (typeof comment !== 'string') {
    return undefined;
  }

  const charsPerLine = Math.ceil(colWidth / (height / 2));
  const newHeight = (comment.length / charsPerLine) * lineHeight + 12;

  if (newHeight < 42) {
    return undefined;
  }

  return newHeight;
};

export const autoHeightColumns = ['lastCheckCallComment', 'referenceNumber'];

export const columnDefs: ColDef[] = [
  ...defaultColumns,
  ...textColumns.map(([field, initialWidth, filter]): ColDef => {
    return {
      field,
      initialWidth,
      resizable: true,
      filter: filter ?? 'agTextColumnFilter',
      suppressNavigable: true,
      wrapText: true,

      cellClass: 'my-custom-cell-class', // Add this line
    };
  }),
  ...namedFields.map(([field, initialWidth]) => {
    return {
      field,
      initialWidth,
      resizable: true,
      filter: 'agMultiColumnFilter',
      filterParams: namedFilterParams,
    };
  }),
];

export const columnDefsMap = columnDefs.reduce(
  (prev, curr) => {
    if (curr.field === undefined) {
      return prev;
    }

    prev[curr.field] = curr;
    return prev;
  },
  {} as Record<string, ColDef>,
);

export type StatusTypesT = ValuesOf<typeof loadboardStatusArray>;
export const nextCheckCallByStatus: Record<
  StatusTypesT | 'None',
  StatusTypesT | '-'
> = {
  Assigned: 'Dispatched',
  Unassigned: '-',
  Dispatched: 'At Shipper',
  'At Shipper': 'Loaded',
  Loaded: 'In Transit',
  'In Transit': 'Out On Delivery',
  'At Receiver': 'Empty',
  'Out On Delivery': '-',
  Empty: '-',
  'Can Get': '-',
  None: '-',
  Void: '-',
};

export const TransitWarningColumnDefs: ColDef[] = [
  {
    field: 'loadNumber',
    minWidth: 120,
    resizable: true,
    hide: false,
    filter: 'agTextColumnFilter',
  },
  {
    field: 'customerName',
    minWidth: 180,
    resizable: true,
    hide: false,
    filter: 'agTextColumnFilter',
  },
  {
    field: 'carrierName',
    minWidth: 120,
    resizable: true,
    hide: false,
    filter: 'agTextColumnFilter',
  },
  {
    field: 'origin',
    minWidth: 120,
    resizable: true,
    hide: false,
    filter: 'agDateColumnFilter',
  },
  {
    field: 'destination',
    minWidth: 120,
    resizable: true,
    hide: false,
    filter: 'agDateColumnFilter',
  },
  {
    field: 'pickDate',
    minWidth: 120,
    resizable: true,
    hide: false,
    filter: 'agDateColumnFilter',
    valueFormatter: dateFormatter,
    comparator: dateComparator,
  },
  {
    field: 'dropDate',
    minWidth: 120,
    resizable: true,
    hide: false,
    filter: 'agDateColumnFilter',
    valueFormatter: dateFormatter,
    comparator: dateComparator,
  },
  {
    field: 'owner',
    minWidth: 120,
    resizable: true,
    hide: false,
    filter: 'agTextColumnFilter',
  },
  {
    field: 'status',
    minWidth: 120,
    resizable: true,
    hide: false,
    editable: true,
    cellEditor: 'agSelectCellEditor',
    cellEditorParams: {
      values: loadboardStatusArray,
    } as IRichCellEditorParams,
  },
  {
    field: 'flagType',
    minWidth: 120,
    resizable: true,
    hide: false,
    filter: 'agTextColumnFilter',
    cellRenderer: FlagTypeCell,
  },
];
