import {
  BasicModal,
  EmptyData,
  TableRowErrors,
  ToastType,
  Typography,
} from '@components';
import { DialogBox } from '@components/DialogBox';
import { CheckboxInput, SelectField, TextInput } from '@components/forms';
import { openDialogBox } from '@store/dialogBox';
import { openModal } from '@store/modals';
import {
  ILoadEditViewModel,
  LineItemViewModel,
  OperationTypeV4,
  orderStore,
  updateLoadPropertyAtIndex,
  validateFieldForOrder,
} from '@store/orders';
import {
  AddCircleOutlineOutlined,
  Delete,
  MonetizationOnOutlined,
} from '@suid/icons-material';
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@suid/material';
import { SelectChangeEvent } from '@suid/material/Select';
import {
  formatAmount,
  handleToast,
  isAdmin,
  isSuperAdmin,
  orderTableErrors,
} from '@utils/utils';
import { lineItemsTypesForCarrierPay } from '@views/order/constants';
import { Accessor, Index, Show, createEffect, createSignal } from 'solid-js';

import { LineItemsLockedModel } from '../models';
import { EFSModal } from './EFSModal';
import classes from './classes';
import {
  isManager,
  removeTableRow,
  userHasPermission,
} from './stops/stopsEditor/utilFunctions';

type Props = {
  load: ILoadEditViewModel;
  tabIndex: number;
};

export const CarrierPay = (props: Props) => {
  const [quantity, setQuantity] = createSignal<number>(0);
  const [rate, setRate] = createSignal<number>(0);
  const [type, setType] = createSignal<string>('');
  const [lineItemId, setLineItemId] = createSignal<number>(0);
  const [deleteindex, setDeleteindex] = createSignal<number | null>(null);

  createEffect(() => {
    if (
      orderStore.isCreate &&
      orderStore.order.loads[orderStore.activeTab.index].lineItems === undefined
    ) {
      addRow();
      onLineItemsChange('type', 'Line Haul', 0);
    }
  });

  const editLineItems = `editLineItems-${props.tabIndex}`;
  const efsModalId = 'EFSModal';

  const addRow = () => {
    if (orderStore.isReadOnly) {
      return;
    }

    const lineItems = props.load.lineItems ? [...props.load.lineItems] : [];
    lineItems.push({
      operationType: 'Insert',
      quantity: 1,
      type: '',
      description: '',
      rate: 0,
      estimate: false,
      //check later
      id: 0,
      readOnly: false,
      synched: false,
      adjustment: false,
      stopId: 0,
      status: 'Unsettled',
      pickUpId: undefined,
      dropOffId: undefined,
    });
    updateLoadPropertyAtIndex({ lineItems: lineItems });
  };

  const onLineItemsChange = (
    key: string,
    value: string | number | boolean,
    index: number,
  ) => {
    const lineItems = props.load.lineItems ? [...props.load.lineItems] : [];
    let opType = 'Insert' as OperationTypeV4;
    if (lineItems.length === 0) {
      return;
    }
    if (lineItems[index].id > 0) {
      opType = 'Update' as OperationTypeV4;
    }
    lineItems[index] = {
      ...lineItems[index],
      [key]: value,
      operationType: opType,
    };
    updateLoadPropertyAtIndex({ lineItems: lineItems });
  };

  const headers = [
    'Type',
    'Description',
    'Qty',
    'Rate',
    'EFS',
    'Est?',
    'Total',
  ];

  const cellStyles = {
    padding: '2px',
    borderBottom: '0px !important',
  };

  const computeTotalLineItems = () => {
    return props.load.lineItems && props.load.lineItems.length > 0
      ? props.load.lineItems
          .filter((v) => v.operationType !== 'Delete')
          .reduce(
            (acc, item) => acc + Number(item.rate) * Number(item.quantity),
            0,
          )
      : 0;
  };

  const getEfsRequestStyling = (lineItemId: number) => {
    const lineItem = orderStore.order.loads[
      orderStore.activeTab.index
    ].lineItems?.find((obj) => obj.id === lineItemId);
    let classString = '';
    if (lineItem?.status === 'Check Approved') {
      classString = classes.dollarIconApproved;
    }
    if (lineItem?.status === 'Requested') {
      classString = classes.dollarIconRequested;
    }
    if (
      lineItem?.status === 'Unsettled' ||
      lineItem?.status === 'Pending Sync'
    ) {
      classString = classes.dollarIcon;
    }
    if (lineItem?.readOnly ?? false) {
      classString = classString + ' cursor-not-allowed';
    }
    return classString;
  };

  const userHasEFSPermission = () => {
    return (
      isAdmin() ||
      isSuperAdmin() ||
      isManager() ||
      userHasPermission('Request EFS Checks') ||
      userHasPermission('Approve Checks')
    );
  };

  const userCanEFS = (lineItemId: number) => {
    const validStatuses = [
      'Loaded',
      'At Shipper',
      'Out On Delivery',
      'At Receiver',
    ];

    if (orderStore.order.loads[orderStore.activeTab.index].carrierId == null) {
      handleToast(ToastType.Error, 'There is no carrier attached to this load');
      return false;
    }
    const lineItem = orderStore.order.loads[
      orderStore.activeTab.index
    ].lineItems?.find((obj) => obj.id === lineItemId);
    if (
      validStatuses.indexOf(
        orderStore.order.loads[orderStore.activeTab.index].status ?? '',
      ) === -1
    ) {
      handleToast(
        ToastType.Error,
        'Status has to be Loaded, At Shipper, In Transit, Out On Delivery, or At Receiver to request check',
      );
      return false;
    }
    if (
      (lineItem?.status === 'Requested' ||
        lineItem?.status === 'Check Approved') &&
      lineItem.readOnly === false
    ) {
      handleToast(
        ToastType.Caution,
        'Check has already been requested or approved.',
      );
      return true;
    }

    return userHasEFSPermission();
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((item) => (
                <TableCell
                  sx={{
                    padding: 1,
                    fontWeight: 700,
                    fontSize: '12px',
                  }}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <Show
            when={
              props.load.lineItems?.filter(
                (item) => item.operationType !== 'Delete',
              ).length === 0
            }
          >
            <TableBody class={classes.emptyTable}>
              <TableRow>
                <TableCell colspan={headers.length + 1} style={{ border: 0 }}>
                  <EmptyData />
                </TableCell>
              </TableRow>
            </TableBody>
          </Show>
          <Show when={props.load.lineItems && props.load.lineItems.length > 0}>
            <TableBody class={classes.tableBg}>
              <Index each={props.load.lineItems ?? []}>
                {(item: Accessor<LineItemViewModel>, index) => (
                  <Show when={item().operationType !== 'Delete'}>
                    <TableRow
                      class={classes.tableRow}
                      sx={{
                        cursor: item().readOnly ? 'not-allowed' : 'default',
                      }}
                    >
                      <TableCell
                        sx={{
                          ...cellStyles,
                          maxWidth: '250px',
                        }}
                      >
                        <SelectField
                          label=""
                          placeholder=""
                          value={item().type as string}
                          menuItems={lineItemsTypesForCarrierPay}
                          onChange={(e: SelectChangeEvent) => {
                            onLineItemsChange('type', e.target.value, index);
                          }}
                          onBlur={() =>
                            validateFieldForOrder(
                              `loads[${props.tabIndex}]lineItems.${index}.type`,
                              `loads[${props.tabIndex}].lineItems[${index}].type`,
                            )
                          }
                          disabled={item().readOnly || orderStore.isReadOnly}
                          customWidth="300"
                          renderValue={(value) => {
                            return value;
                          }}
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          ...cellStyles,
                          minWidth: '130px',
                        }}
                      >
                        <TextInput
                          value={item().description as string}
                          onChange={(value) => {
                            onLineItemsChange(
                              'description',
                              String(value ?? ''),
                              index,
                            );
                          }}
                          classes={'bg-white rounded-lg'}
                          label=""
                          placeholder="Description"
                          disabled={
                            item().readOnly ||
                            item().type === '' ||
                            orderStore.isReadOnly
                          }
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          ...cellStyles,
                          minWidth: '50px',
                          maxWidth: '60px',
                        }}
                      >
                        <TextInput
                          label=""
                          type="number"
                          significantDigits={3}
                          value={item().quantity}
                          onChange={(value: string) => {
                            onLineItemsChange('quantity', value, index);
                          }}
                          onBlur={() =>
                            validateFieldForOrder(
                              `loads.${props.tabIndex}.lineItems.${index}.quantity`,
                              `loads[${props.tabIndex}].lineItems[${index}].quantity`,
                            )
                          }
                          error={
                            orderStore.orderFormError &&
                            orderStore.orderFormError[
                              `loads[${props.tabIndex}].lineItems[${index}].quantity`
                            ]
                          }
                          noErrorMessage
                          placeholder="Qty"
                          classes={'bg-white rounded-lg'}
                          disabled={item().readOnly || orderStore.isReadOnly}
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          ...cellStyles,
                          minWidth: '70px',
                          maxWidth: '115px',
                        }}
                      >
                        <TextInput
                          value={item().rate}
                          significantDigits={3}
                          // @ts-expect-error - pointless type mismatch
                          onChange={(value: string) => {
                            onLineItemsChange('rate', value, index);
                          }}
                          onBlur={() =>
                            validateFieldForOrder(
                              `loads.${props.tabIndex}.lineItems.${index}.rate`,
                              `loads[${props.tabIndex}].lineItems[${index}].rate`,
                            )
                          }
                          error={
                            orderStore.orderFormError &&
                            orderStore.orderFormError[
                              `loads[${props.tabIndex}].lineItems[${index}].rate`
                            ]
                          }
                          noErrorMessage
                          label=""
                          placeholder="Rate"
                          type="number"
                          classes={'bg-white rounded-lg'}
                          disabled={item().readOnly || orderStore.isReadOnly}
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          ...cellStyles,
                          textAlign: 'center',
                          padding: '0px',
                        }}
                      >
                        <Show when={item().id !== 0}>
                          <IconButton
                            disabled={!userHasEFSPermission()}
                            onClick={() => {
                              if (
                                userCanEFS(item().id) &&
                                !item().readOnly &&
                                !orderStore.isReadOnly
                              ) {
                                setQuantity(
                                  typeof item().quantity === 'number'
                                    ? Number(item().quantity)
                                    : 0,
                                );
                                setRate(
                                  typeof item().rate === 'number'
                                    ? Number(item().rate)
                                    : 0,
                                );
                                setType(item().type ?? '');
                                setLineItemId(item().id);
                                openModal(efsModalId);
                              }
                            }}
                          >
                            <MonetizationOnOutlined
                              class={getEfsRequestStyling(item().id)}
                            />
                          </IconButton>
                        </Show>
                      </TableCell>
                      <TableCell
                        sx={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <CheckboxInput
                          checked={
                            props.load.lineItems &&
                            props.load.lineItems[index]['estimate']
                          }
                          onChange={(value) => {
                            onLineItemsChange('estimate', value, index);
                          }}
                          label=""
                          disabled={item().readOnly || orderStore.isReadOnly}
                        />
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          class={`${classes.totalAmount} !font-normal`}
                        >
                          {formatAmount(
                            Number(item().quantity) * Number(item().rate),
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: '0px',
                        }}
                      >
                        <IconButton
                          disabled={item().readOnly || orderStore.isReadOnly}
                          onClick={() => {
                            setDeleteindex(index);
                            openDialogBox('deleteCarriePayRow');
                          }}
                        >
                          <Delete class={classes.deleteIcon} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    <TableRowErrors
                      columnsLength={headers.length}
                      tableErrors={orderTableErrors(
                        ['rate', 'quantity', 'type', 'description'],
                        `loads[${props.tabIndex}].lineItems[{index}].{field}`,
                        index,
                      )}
                    />
                  </Show>
                )}
              </Index>
            </TableBody>
          </Show>
          <TableFooter class={classes.tableFooter}>
            <TableRow class={classes.tableFooterRow}>
              <TableCell colspan={1}>
                <IconButton
                  class="!p-0"
                  onClick={addRow}
                  disabled={
                    (orderStore.order.loads[orderStore.activeTab.index]
                      ?.status === 'Paid' ||
                      orderStore.order.loads[orderStore.activeTab.index]
                        ?.status === 'Invoiced') &&
                    (!isAdmin() || !isSuperAdmin())
                  }
                >
                  <AddCircleOutlineOutlined class={classes.addIcon} />
                </IconButton>
              </TableCell>
              <TableCell colspan={4}></TableCell>
              <TableCell colspan={3}>
                <div class="flex items-center gap-2">
                  <div class="font-medium text-sm	text-black">Carrier Total</div>
                  <div class="font-medium text-sm	text-black px-3 rounded-sm py-2 bg-[#0000000F] ">
                    {formatAmount(computeTotalLineItems())}
                  </div>
                </div>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Show when={props.load.synched}>
        <Box class="mt-2">
          <span
            class={classes.editLineItems}
            onClick={() => openModal(editLineItems)}
          >
            Why can't I edit my Line Items?
          </span>
        </Box>
      </Show>
      <BasicModal
        id={editLineItems}
        width={'800px'}
        footer={false}
        header={false}
      >
        <LineItemsLockedModel />
      </BasicModal>
      <BasicModal id={efsModalId} footer={false} header={false}>
        <EFSModal
          quantity={quantity()}
          rate={rate()}
          type={type()}
          setRate={setRate}
          lineItemId={lineItemId()}
        />
      </BasicModal>
      <DialogBox
        id="deleteCarriePayRow"
        title="Are you sure you want to delete this row?"
        onSubmit={() => {
          removeTableRow(deleteindex()!, props.load, 'load', 'lineItems');
          setDeleteindex(null);
        }}
        onSubmitText="Delete"
      />
    </>
  );
};
