import { produce } from 'solid-js/store';
import { DateTime } from 'luxon';

import {
  fetchCarrierAdjustmentAccounts,
  fetchCarrierAdjustments,
  fetchCarrierDetails,
  fetchCarrierDocuments,
  fetchCarrierFuelSurcharge,
  fetchCarrierLoadHistory,
  saveNewCarrierAdjustments,
} from './services';
import {
  carrierDetailsStore,
  setCarrierDetailsStore,
  setCarrierLoadHistoryStore,
} from './store';
import { CarrierDetails, CarrierLoadHistory } from './types';

export const getCarrierDetails = async (carrierId: string) => {
  setCarrierDetailsStore('isLoading', true);
  const response = await fetchCarrierDetails(carrierId);
  //sort the insurances by insurance type
  response?.carrierInsurances.sort((a, b) => {
    if (a.insuranceType === b.insuranceType) {
      return a.internalStatus!.localeCompare(b.internalStatus!);
    }
    return a.insuranceType.localeCompare(b.insuranceType);
  });
  //set the internal status of the insurance
  response?.carrierInsurances.forEach((insurance) => {
    if (
      DateTime.fromISO(insurance.expirationDate!) <
      DateTime.now().startOf('day')
    ) {
      insurance.internalStatus = 'Expired';
    } else if (
      DateTime.fromISO(insurance.effectiveDate!) > DateTime.now().startOf('day')
    ) {
      insurance.internalStatus = 'Pending Active';
    } else {
      insurance.internalStatus = 'Active';
    }
  });
  if (response) {
    setCarrierDetailsStore(
      produce((draft) => {
        draft.carrierDetails = response;
        draft.isLoading = false;
        draft.isError = false;
        draft.selectedCountry = response.usaAddress.enabled ? 'US' : 'CA';
      }),
    );
  } else {
    setCarrierDetailsStore('isLoading', false);
  }
};

export const getCarrierAdjustments = async (carrierId: string) => {
  const response = await fetchCarrierAdjustments(carrierId);
  if (response) {
    setCarrierDetailsStore('carrierAdjustments', response.value);
  }
};

export const getCarrierAdjustmentAccounts = async () => {
  const response = await fetchCarrierAdjustmentAccounts();
  if (response) {
    setCarrierDetailsStore('carrierAdjustmentAccounts', response);
  }
};

export const getCarrierDocuments = async (carrierId: string) => {
  const response = await fetchCarrierDocuments(carrierId);
  if (Boolean(response)) setCarrierDetailsStore('carrierDocuments', response!);
};

export const getCarrierFuelSurcharge = async (carrierId: string) => {
  const response = await fetchCarrierFuelSurcharge(carrierId);
  if (Boolean(response))
    setCarrierDetailsStore('carrierFuelSurcharge', response!);
};

export const updateCarrierDetailsState = (updates: Partial<CarrierDetails>) => {
  Object.entries(updates).forEach(([key, value]) => {
    setCarrierDetailsStore(
      'carrierDetails',
      key as keyof CarrierDetails,
      value,
    );
  });
  if (carrierDetailsStore.carrierDetails?.operationType === 'None') {
    setCarrierDetailsStore('carrierDetails', 'operationType', 'Update');
  }
};

export const getCarrierLoadHistory = async (carrierId: string) => {
  setCarrierLoadHistoryStore('isLoading', true);
  const response = await fetchCarrierLoadHistory(carrierId);
  if (Boolean(response)) {
    setCarrierLoadHistoryStore(
      produce((draft) => {
        draft.items = response
          ?.slice()
          .reverse() as unknown as CarrierLoadHistory[];
        draft.isLoading = false;
        draft.isError = false;
      }),
    );
  } else {
    setCarrierLoadHistoryStore(
      produce((draft) => {
        draft.items = [];
        draft.isLoading = false;
        draft.isError = true;
      }),
    );
  }
};

export const saveNewCarrierDetailsAdjustment = async (
  carrierId: number,
  adjustment: Record<string, unknown>[],
) => {
  const response = await saveNewCarrierAdjustments(carrierId, adjustment);
  if (Boolean(response)) await getCarrierAdjustments(carrierId.toString());
};
