export const addressBookTabStyle = {
  container: 'min-h-[400px] h-[calc(100vh-210px)]',
};
export const addOrEditAddressStyle = {
  rowFormGrid: 'w-full',
  titleTypography:
    'text-black !text-xl !font-medium !leading-8 !tracking-[0.15px]',
  errorBox: '!mb-4',
};

export const addressBookGridStyle = {
  stackStyle: 'h-[100%]',
  addBtnBox:
    'w-[100%] flex justify-start p-2 border-gray-400 rounded-sm border-b-0 border bg-[#F8F8F8]',
  addBtn: 'flex items-center gap-1',
  gridStyle: 'ag-theme-alpine !flex-1',
};
