import { Button } from '@components/Button';
import CommonFileUpload from '@components/FileUpload/CommonFileUpload';
import { ToastType } from '@components/Toast';
import { customerUrl } from '@constants/urls';
import { useParams } from '@solidjs/router';
import { uploadProductCatalog } from '@store/customers/customerDetails';
import { closeModal } from '@store/modals';
import { Download } from '@suid/icons-material';
import { Box, Stack } from '@suid/material';
import { handleToast } from '@utils/utils';
import { AgGridSolidRef } from '@ag-grid-community/solid';
import { Accessor, Component, createSignal } from 'solid-js';

export type ImportCatalogItemProps = {
  gridRef: Accessor<AgGridSolidRef | null>;
};

export const ImportCatalogItem: Component<ImportCatalogItemProps> = (props) => {
  const [file, setFile] = createSignal<
    {
      name: string;
      size: number;
    }[]
  >([]);

  const { id } = useParams();

  const handleFileUpload = async (file: File) => {
    setFile((prev) => {
      return [
        ...prev,
        {
          name: file.name,
          size: file.size,
        },
      ];
    });
    const res = await uploadProductCatalog(id, file);
    if (res && res.success) {
      res.data.validEntities.forEach((entity) => {
        props.gridRef()?.api.applyServerSideTransaction({
          add: [entity],
        });
      });
      handleToast(ToastType.Success, 'Product catalog imported successfully');
      closeModal('importProductCatalog');
    } else {
      handleToast(
        ToastType.Error,
        res?.message ?? 'Failed to import product catalog',
      );
      setFile([]);
    }
  };

  return (
    <Stack spacing={2}>
      <CommonFileUpload
        height={5}
        maxFileSize={5 * 1024 * 1024}
        uploadedFiles={file()}
        acceptedFileTypes={['csv']}
        shouldUploadOnSelect
        apiCallBackFn={handleFileUpload}
        removeFile={(index) => {
          setFile((prev) => {
            const files = [...prev];
            files.splice(index, 1);
            return files;
          });
        }}
        fileRestrictionMessage='File should be in ".csv" format'
      />
      <Box displayRaw="flex" justifyContent="end">
        <Button
          startIcon={<Download />}
          label="Download Sample File"
          variant="contained"
          type="button"
          onClick={() => {
            window.open(
              customerUrl.productCatalogSampleCsvDownloadLink(),
              '_blank',
            );
          }}
        />
      </Box>
    </Stack>
  );
};
