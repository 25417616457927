import { createStore } from 'solid-js/store';

import { PricingState } from './types';

const [pricingStore, setPricingStore] = createStore<PricingState>({
  isLoading: false,
  isError: false,
  errorMessage: '',
  isPricingEnabled: false,
  isPricingEnabledError: false,
  items: [],
});

export { pricingStore, setPricingStore };
