import { createSignal, onCleanup, Component, JSX, onMount } from 'solid-js';

interface ClickAwayListenerProps {
  children: JSX.Element;
  onClickAway: () => void;
  innerRefClass?: string;
}

const ClickAwayListener: Component<ClickAwayListenerProps> = ({
  children,
  onClickAway,
  innerRefClass = '',
}) => {
  const [target, setTarget] = createSignal<HTMLElement | null>(null);

  function handleClick(event: MouseEvent) {
    if (target() && !target()!.contains(event.target as Node)) {
      onClickAway();
    }
  }

  function handleRef(ref: HTMLElement) {
    setTarget(ref);
  }

  onCleanup(() => {
    document.removeEventListener('click', handleClick);
  });

  onMount(() => {
    document.addEventListener('click', handleClick);
  });

  return (
    <div class={innerRefClass} ref={handleRef}>
      {children}
    </div>
  );
};

export default ClickAwayListener;
