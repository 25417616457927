import { CheckboxGroup, RadioInput } from '@components/forms';
import { officeDetailStore } from '@store/office/officeDetails';
import { Box, Divider, Grid, Typography } from '@suid/material';
import { Accessor, createMemo, Setter } from 'solid-js';

type RolesPermissionProps = {
  selectedPermissions: Accessor<string[]>;
  setSelectedPermissions: Setter<string[]>;
  selectedRole: Accessor<string[]>;
  setSelectedRole: Setter<string[]>;
};

export const RolesPermission = (props: RolesPermissionProps) => {
  const permissionArgs = createMemo(() => ({
    name: 'permissions',
    label: 'Permissions',
    value: props.selectedPermissions(),
    options: officeDetailStore.permissionsOptions,
    onChange: (selectedValues: string[]) => {
      props.setSelectedPermissions(selectedValues);
    },
    error: '',
  }));

  const handleRadioChange = (event: Event) => {
    const target = event.target as HTMLInputElement;
    props.setSelectedRole([target.value]);
  };

  return (
    <Grid container direction="row" spacing={3} p={2}>
      <Grid item xs={12} md={6} container direction="column" spacing={2}>
        <Typography variant="h6">Roles</Typography>
        <Box mb={2}>
          <Divider sx={{ mb: 2 }} />
          <RadioInput
            label=""
            direction="vertical"
            value={props.selectedRole()[0]}
            options={officeDetailStore.rolesOptions}
            onChange={handleRadioChange}
            labelClass="mb-[-6px] text-xs"
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={6} container direction="column" spacing={2}>
        <Typography variant="h6">Permissions</Typography>
        <Box mb={2}>
          <Divider sx={{ mb: 2 }} />
          <CheckboxGroup {...permissionArgs()} />
        </Box>
      </Grid>
    </Grid>
  );
};
