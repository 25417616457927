import { Button, ToastType, Typography } from '@components';
import { TextInput } from '@components/forms';
import { closeModal } from '@store/modals';
import {
  FlagType,
  FlagViewModel,
  IOrderViewModel,
  addFlag,
  orderStore,
  updateOrderState,
} from '@store/orders';
import { userStore } from '@store/user';
import { Lock, Note } from '@suid/icons-material';
import { Box, Grid } from '@suid/material';
import { handleToast } from '@utils/utils';
import { Component, createSignal } from 'solid-js';

import { billingModalClasses as cls } from './classes';

export type BillingHoldModalProps = {
  modalId: string;
};

const BillingHoldModal: Component<BillingHoldModalProps> = (
  props: BillingHoldModalProps,
) => {
  const [comment, setComment] = createSignal('');
  const [loading, setLoading] = createSignal(false);

  const onSetBilling = async (flagType: FlagType, errorMessage: string) => {
    try {
      setLoading(true);
      const payload: Partial<FlagViewModel> = {
        orderId: orderStore.order.id,
        setBy:
          flagType === FlagType.BillingHold ? userStore.user.name : undefined,
        description: comment(),
        flagType,
      };
      const response = await addFlag(payload);
      const newOrderState: Partial<IOrderViewModel> = {
        ...orderStore.order,
        flags: [...(orderStore.order.flags ?? []), response],
      };

      if (response.flagType === FlagType.NeedsApproval) {
        newOrderState.needsApprovalFlag = { ...response };
      }

      updateOrderState(newOrderState);
      handleToast(
        ToastType.Success,
        flagType === FlagType.BillingHold
          ? 'Billing Hold Added'
          : 'Billing Note Added',
      );
    } catch (error) {
      handleToast(ToastType.Error, errorMessage);
    } finally {
      closeModal(props.modalId);
      setLoading(false);
    }
  };

  const onSetBillingHold = () =>
    onSetBilling(FlagType.BillingHold, 'Failed to Add Billing Hold');

  const onSetBillingNote = () =>
    onSetBilling(FlagType.BillingNote, 'Failed to Add Billing Note');

  const onClose = () => {
    closeModal(props.modalId);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} px={1} pb={2} alignItems="center">
        <Grid item xs={11}>
          <Typography class={cls.modalTitle} variant="h6" component="h2">
            Billing Hold
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextInput
            multiline
            label="Comment"
            placeholder="Enter description here"
            rows={2}
            value={comment()}
            onChange={(value) => {
              setComment(value as string);
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} borderRight={1} my={2}>
          <Grid item container spacing={1} pr={2}>
            <Grid item xs={12}>
              <Typography variant="body1" component="p" class={cls.billTitle}>
                Set Billing Hold
              </Typography>
              <Typography variant="body1" component="p" class={cls.billDesc}>
                Billing Holds will not be processed until you release the hold.
                <span class="font-medium">
                  This Order will not be put into the billing queue to be
                  processed until this hold is released by you.
                </span>
              </Typography>
              <Typography
                variant="body1"
                component="p"
                class={cls.billDisclaimer}
              >
                Do not use this to communicate with Billing.
              </Typography>
            </Grid>
            <Grid item xs={12} displayRaw="flex" justifyContent="flex-end">
              <Button
                label="Set Billing Hold"
                variant="contained"
                class={`!bg-[#8d001a] ${
                  loading() || (!comment() && '!bg-[#c47f8c]')
                } `}
                startIcon={<Lock />}
                onClick={onSetBillingHold}
                isLoading={loading()}
                disabled={!comment() || loading()}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6} my={2}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="body1" component="p" class={cls.billTitle}>
                Set Billing Note
              </Typography>
              <Typography variant="body1" component="p" class={cls.billDesc}>
                If you need someone in Billing to edit or change something prior
                to processing, add a billing note with a message or
                instructions.
              </Typography>
              <Typography
                variant="body1"
                component="p"
                class={cls.billDisclaimer}
              >
                Do not use this to communicate with Billing.
              </Typography>
            </Grid>
            <Grid item xs={12} displayRaw="flex" justifyContent="flex-end">
              <Button
                label="Add Note for Billing"
                variant="contained"
                startIcon={<Note />}
                onClick={onSetBillingNote}
                isLoading={loading()}
                disabled={!comment() || loading()}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end">
        <Button
          variant="text"
          label="Cancel"
          disabled={loading()}
          onClick={onClose}
        />
      </Grid>
    </Box>
  );
};

export default BillingHoldModal;
