export const CustomerStatuses = [
  { label: 'Pending', value: 'Pending' },
  { label: 'In Review', value: 'In Review' },
  { label: 'Customer', value: 'Customer' },
  { label: 'Credit Hold', value: 'Credit Hold' },
  { label: 'Collection Hold', value: 'Collection Hold' },
  { label: 'Inactive', value: 'Inactive' },
  { label: 'In Collections', value: 'In Collections' },
  { label: 'Dead', value: 'Dead' },
  { label: 'Duplicate', value: 'Duplicate' },
  { label: 'Dormant', value: 'Dormant' },
];

export const CustomerPolicyType = [
  { label: 'EZCover', value: '022f2602-a27a-ea11-a94c-0003ff194fc1' },
  { label: 'Named', value: '032f2602-a27a-ea11-a94c-0003ff194fc1' },
  { label: 'CAP', value: '042f2602-a27a-ea11-a94c-0003ff194fc1' },
  { label: 'CAP+', value: '052f2602-a27a-ea11-a94c-0003ff194fc1' },
  { label: 'Cancelled', value: '062f2602-a27a-ea11-a94c-0003ff194fc1' },
];

export const CustomerSeasonalityList = [
  { label: 'Unknown', value: 'null' },
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];

export const CustomerStructureList = [
  { label: 'Unknown', value: 'null' },
  { label: 'Centralized', value: 'true' },
  { label: 'Decentralized', value: 'false' },
];

export const InvoiceMethodList = [
  { label: 'Post', value: 'Post' },
  { label: 'EDI', value: 'EDI' },
  { label: 'Email', value: 'Email' },
  { label: 'Fax', value: 'Fax' },
  { label: 'Third Party', value: 'Third Party' },
  { label: 'Canadian', value: 'Canadian' },
];

export const BillingTermList = [
  { label: 'Due Upon Receipt', value: 'Due Upon Receipt' },
  {
    label: 'One% Ten Days; Net 30 Days',
    value: 'One% Ten Days; Net 30 Days',
  },
  { label: 'Net 15', value: 'Net 15' },
  { label: 'Net 30', value: 'Net 30' },
  { label: 'Net 45', value: 'Net 45' },
  { label: 'Net 60', value: 'Net 60' },
  { label: 'Net 75', value: 'Net 75' },
  { label: 'Prepay', value: 'Prepay' },
];

export const CollectionTermList = [
  { label: 'Due Upon Receipt', value: 'Due Upon Receipt' },
  { label: 'Net 15', value: 'Net 15' },
  { label: 'Net 20', value: 'Net 20' },
  { label: 'Net 25', value: 'Net 25' },
  { label: 'Net 30', value: 'Net 30' },
  { label: 'Net 35', value: 'Net 35' },
  { label: 'Net 40', value: 'Net 40' },
  { label: 'Net 45', value: 'Net 45' },
  { label: 'Net 60', value: 'Net 60' },
  { label: 'Net 65', value: 'Net 65' },
  { label: 'Net 70', value: 'Net 70' },
  { label: 'Net 75', value: 'Net 75' },
  { label: 'Net 80', value: 'Net 75' },
  { label: 'Net 85', value: 'Net 85' },
  { label: 'Net 90', value: 'Net 90' },
  { label: 'Prepay', value: 'Prepay' },
];

export const trackingServiceList = [
  { label: 'Select an Option', value: 'null' },
  { label: 'Macropoint', value: '1' },
  { label: 'Project44', value: '3' },
];

export const countryList = [
  { label: 'USA', value: 'USA' },
  { label: 'CAN', value: 'CAN' },
  { label: 'MEX', value: 'MEX' },
];
export const customerSettingLabels: { [key: string]: string } = {
  EnableLTL: 'Enable LTL Quoting',
  AllowAutoDispatch: 'Allow carrier auto-dispatch',
  EnableCSP: 'Enable Customer Specific Pricing',
  EnablePrePro: 'Enable Pre-PRO on BOL',
  EnableAgentQuotesInCp: 'Enable customers to see agent quotes',
  EnableSuggestedClassInCustomerPortal: 'Enable Suggested Class',
};

export const emailAlertLabels: { [key: string]: string } = {
  EmailAgentIfNoQuoteResult: 'Customer gets 0 results returned when quoting',
  EmailAgentIfShipmentNotBuilt:
    'Customer does not finish building a shipment after selecting an LTL quote',
};
export const approvedByCreditList = [
  { label: 'Select an Option', value: 'null' },
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];

export const priorityList = [
  { label: 'Low', value: '0' },
  { label: 'Normal', value: '1' },
  { label: 'High', value: '3' },
  { label: 'Critical', value: '4' },
];

export const trackingVisibilityProviderList = [
  { label: 'Macropoint', value: '1' },
  { label: 'Project44', value: '3' },
  { label: 'FourKites', value: '4' },
  { label: 'Shippeo', value: '6' },
];
