import { Typography } from '@components';
import { WarningOutlined } from '@suid/icons-material';
import { Box, Grid, Stack } from '@suid/material';
import { Component } from 'solid-js';
import { ILoadEditViewModel } from '@store/orders';
import { routeClaimDetails } from '@utils/routeClaimDetails';
import { useNavigate } from '@solidjs/router';

import classes from './classes';

export type ClaimProps = {
  load: ILoadEditViewModel;
};

const Claim: Component<ClaimProps> = (props: ClaimProps) => {
  const navigate = useNavigate();
  return (
    <Stack
      class={classes.mainContainer}
      mb={2}
      onClick={() => {
        const claimData = props.load.claim;
        if (claimData) {
          const isNative = routeClaimDetails(claimData.loadId);
          isNative && navigate(`/claim/${claimData.loadId}`);
        }
      }}
    >
      <Box class={classes.topContainer} py={2}>
        <Box px={2}>
          <WarningOutlined class={classes.warninIcon} />
        </Box>
        <Stack spacing={1} pr={2}>
          <Typography
            component="div"
            variant="body1"
            class={classes.topContainerTitle}
          >
            Claim
          </Typography>
          <Typography
            component="div"
            variant="body1"
            class={classes.topContainerSubtitle}
          >
            {props.load.claim?.description}
          </Typography>
        </Stack>
      </Box>
      <Grid xs={12} container>
        <Grid xs={2} px={3} py={2}>
          <Stack spacing={1}>
            <Typography
              component="div"
              variant="body1"
              class={classes.bottomContainerTitle}
            >
              Claim Number
            </Typography>
            <Typography component="div" variant="body1">
              {props.load.claim?.claimNumber}
            </Typography>
          </Stack>
        </Grid>
        <Grid xs={2} px={3} py={2}>
          <Stack spacing={1}>
            <Typography
              component="div"
              variant="body1"
              class={classes.bottomContainerTitle}
            >
              Status
            </Typography>
            <Typography component="div" variant="body1">
              {props.load.claim?.status}
            </Typography>
          </Stack>
        </Grid>
        <Grid xs={2} px={3} py={2}>
          <Stack spacing={1}>
            <Typography
              component="div"
              variant="body1"
              class={classes.bottomContainerTitle}
            >
              Claim Filed Amount
            </Typography>
            <Typography component="div" variant="body1">
              {props.load.claim?.claimValue}
            </Typography>
          </Stack>
        </Grid>
        <Grid xs={2} px={3} py={2}>
          <Stack spacing={1}>
            <Typography
              component="div"
              variant="body1"
              class={classes.bottomContainerTitle}
            >
              Claim File Date
            </Typography>
            <Typography component="div" variant="body1">
              {props.load.claim?.claimDateFormatted}
            </Typography>
          </Stack>
        </Grid>
        <Grid xs={2} px={3} py={2}>
          <Stack spacing={1}>
            <Typography
              component="div"
              variant="body1"
              class={classes.bottomContainerTitle}
            >
              Occured Date
            </Typography>
            <Typography component="div" variant="body1">
              {props.load.claim?.occurredDateFormatted}
            </Typography>
          </Stack>
        </Grid>
        <Grid xs={2} px={3} py={2}>
          <Stack spacing={1}>
            <Typography
              component="div"
              variant="body1"
              class={classes.bottomContainerTitle}
            >
              Closed Date
            </Typography>
            <Typography component="div" variant="body1">
              {props.load.claim?.closedFormattedDate}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default Claim;
