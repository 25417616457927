import {
  GridOptions,
  RowClassParams,
  RowClickedEvent,
} from '@ag-grid-community/core';
import AgGridSolid from '@ag-grid-community/solid';
import { useNavigate } from '@solidjs/router';
import { TransitWarningColumnDefs } from '@store/loadboard';
import { Grid as MuiGrid, Stack } from '@suid/material';
import { routeOrderDetails } from '@utils/routeOrderDetails';

type PropsT = {
  items: Record<string, unknown>[];
  getRowStyle?: (params: RowClassParams) => { background?: string };
  onRowClicked?:
    | ((e: RowClickedEvent, col?: string | undefined) => void)
    | undefined;
  onRefreshGrid?: () => void;
  onUpdateItems: (newItem: string, col: string, loadId: number) => void;
};

export const TransitWarningGrid = (props: PropsT) => {
  const gridOptions: GridOptions = {
    defaultColDef: {
      editable: false,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
      flex: 1,
      minWidth: 100,
      cellDataType: false,
    },
    suppressScrollOnNewData: true,

    onGridReady(event) {
      event.api.addEventListener('cellEditingStopped', (e: unknown) => {
        const data = (e as { data: unknown }).data;
        props.onUpdateItems(
          (e as { newValue: string }).newValue,
          (e as { col: string }).col,
          (data as { loadId: number }).loadId,
        );
      });
    },
  };

  const navigate = useNavigate();

  return (
    <Stack class="h-[300px]">
      <MuiGrid class="ag-theme-alpine !flex-1 pt-4">
        <AgGridSolid
          class="!h-[calc(100% - 48px)]"
          columnDefs={TransitWarningColumnDefs}
          rowData={props.items}
          gridOptions={gridOptions}
          getRowStyle={props.getRowStyle}
          onRowClicked={(e) => {
            const orderId = (e.data as { orderId: number }).orderId;
            const isNative = routeOrderDetails(orderId);
            if (isNative) {
              navigate(`/order/details/${orderId}`);
            }
          }}
        />
      </MuiGrid>
    </Stack>
  );
};
