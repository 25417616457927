import {
  BasicModal,
  Button,
  Card,
  DatePicker,
  Map,
  Menu,
  TimeInput,
  ToastType,
  Typography,
  openToast,
} from '@components';
import { Coordinate } from '@components/Map/Map';
import Tooltip from '@components/Tooltip';
import { CheckboxInput, SelectField } from '@components/forms';
import { useParams } from '@solidjs/router';
import { nextCheckCallByStatus } from '@store/loadboard';
import { closeModal, openModal } from '@store/modals';
import {
  disableTracking,
  enableTracking,
  getStopCoordinates,
  getTrackingData,
  orderStore,
  postNextCheckCall,
  updateLoadPropertyAtIndex,
} from '@store/orders';
import { ILoadComments, TrackingData } from '@store/orders/types';
import { Info } from '@suid/icons-material';
import ArrowDropDownIcon from '@suid/icons-material/ArrowDropDown';
import { Box, Button as SButton, Stack } from '@suid/material';
import { timeZoneAbbreviation } from '@utils/dateFormat';
import { handleToast } from '@utils/utils';
import { loadStatusItems, trackingOptions } from '@views/order/constants';
import { Show, createEffect, createSignal } from 'solid-js';
import { DateTime } from 'luxon';

import { CustomerTracking } from '../../customerTracking';
import { getLoadColor } from '../../header/constants';
import { subTextStyle } from '../loads/styles';
import { CustomerUpdateModal } from './CustomerUpdateModal';
import { TrackingCard } from './TrackingCard';
import classes from './classes';
import styles from './styles';
import { trackingToComment } from './utils';

export interface IZip {
  Address: {
    Zip: string;
  };
}

export interface ICityState {
  Address: {
    City: string;
    State: string;
  };
}

export interface trackingProps {
  loading?: boolean;
}

export const Tracking = (props: trackingProps) => {
  const [nextCheckCallDateUtc, setNextCheckCallDateUtc] =
    createSignal<string>();

  const params = useParams();
  const [comment, setComment] = createSignal<ILoadComments>();
  const [hideTrackingComments, setHideTrackingComments] =
    createSignal<boolean>(false);
  const [checkCallEnabled, setCheckCallEnabled] = createSignal(
    orderStore.order.loads[orderStore.activeTab.index].mode !== 'LTL',
  );

  createEffect(() => {
    if (
      orderStore.order.loads[orderStore.activeTab.index]?.mode !== undefined
    ) {
      setCheckCallEnabled(
        orderStore.order.loads[orderStore.activeTab.index]?.mode !== 'LTL',
      );
    }
    if (
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      orderStore.order.loads[orderStore.activeTab.index] !== undefined &&
      orderStore.order.loads[orderStore.activeTab.index]
        .nextCheckCallDateUtc !== undefined
    ) {
      setNextCheckCallDateUtc(
        DateTime.fromISO(
          `${
            orderStore.order.loads[orderStore.activeTab.index]
              .nextCheckCallDateUtc
          }`,
          { zone: 'Etc/UTC' },
        )
          .toLocal()
          .toISO({
            includeOffset: false,
          }) ?? '',
      );
    }
  });

  const customerUpdateModalId = 'trackingCustomerUpdateModalId';

  const isCreate = !params.id;
  const serviceId =
    orderStore.order.loads[orderStore.activeTab.index]?.trackingService;

  createEffect(() => {
    const stops = orderStore.order.loads[
      orderStore.activeTab.index
    ]?.stops?.filter((stop) => {
      return stop.operationType !== 'Delete';
    });
    if (stops) {
      const zipArray: IZip[] = [];
      stops.forEach((stop) => {
        zipArray.push({ Address: { Zip: stop.zip } });
      });
      void getStopCoordinates(zipArray);
    }
  });

  const [activeTabIndex, setActiveTabIndex] = createSignal(
    orderStore.activeTab.index,
  );

  createEffect(() => {
    setActiveTabIndex(orderStore.activeTab.index);
  });

  const initialValues = {
    driverPhone:
      orderStore.order.loads[orderStore.activeTab.index]?.driverPhoneNumber,
    loadId: orderStore.order.loads[orderStore.activeTab.index]?.id,
    trackingStartDate: new Date().toISOString(),
    settings: {
      truckNumber: '',
      trailerNumber: '',
      timezone: timeZoneAbbreviation(),
      resend: true,
    },
    trackingStopWindows:
      orderStore.order.loads[orderStore.activeTab.index]?.stops?.map((val) => {
        return { Id: val.id, EDT: '', ETA: '' };
      }) || [],
    serviceId: serviceId === null ? 0 : serviceId,
  };

  const hasTrackingData = () => {
    const trackingData =
      orderStore.order.loads[orderStore.activeTab.index].loadTracking;
    if (
      trackingData !== undefined &&
      trackingData !== null &&
      trackingData.length > 0
    ) {
      return true;
    }
    return false;
  };

  const handleTracking = async (trackingOption: string) => {
    try {
      const { id } = orderStore.order.loads[orderStore.activeTab.index];
      if (trackingOption === 'Disable') {
        await disableTracking(id);
      } else if (trackingOption === 'Resend') {
        const resp = await enableTracking(
          initialValues as unknown as TrackingData,
        );

        if (resp) {
          handleToast(ToastType.Success, resp.message);
        }
      }
    } catch (error) {
      handleToast(ToastType.Error, (error as Error).message);
    }
  };

  const handleDateTimeChange = (value: string, source: 'time' | 'date') => {
    if (source === 'time') {
      setNextCheckCallDateUtc((prev = '') => prev.split('T')[0] + 'T' + value);
    } else {
      setNextCheckCallDateUtc(value);
    }
    updateLoadPropertyAtIndex({
      nextCheckCallDateUtc:
        DateTime.fromISO(nextCheckCallDateUtc() ?? '')
          .toUTC()
          .toISO({
            includeOffset: false,
          }) ?? '',
    });
  };

  return (
    <Card
      startTitle="Tracking"
      startTitleAction={
        <Menu
          // @ts-expect-error sauce
          menuItems={trackingOptions}
          menuButtonLabel={
            <Button
              label="Tracking Options"
              class={classes.trackingButton}
              endIcon={<ArrowDropDownIcon />}
              size="small"
              sx={{ lineHeight: '22px' }}
            />
          }
          onMenuItemClick={(item) => {
            void handleTracking(item);
          }}
          downArrowShowHide={false}
        />
      }
      endTitle={
        <Tooltip
          placement="left"
          text="You can double click on any Check Call to send your customer."
        >
          <Info class={classes.infoIcon} />
        </Tooltip>
      }
      loading={props.loading ?? orderStore.loading}
    >
      <div>
        <Stack direction="row" class="mb-2">
          <div class="flex-auto ">
            {/*This is a show trick to force the map to re-render when the tab is changed*/}
            <Show when={activeTabIndex() === orderStore.activeTab.index}>
              <Map
                center={[0, 0]}
                height={500}
                width={'100%'}
                zoom={3}
                coordinates={orderStore.mapCords}
                markerPositions={
                  getTrackingData()
                    .filter((tracking) => {
                      return (
                        tracking.latitude !== undefined && tracking.longitude
                      );
                    })
                    .map((tracking) => {
                      return {
                        lng: tracking.longitude as number,
                        lat: tracking.latitude as number,
                        city: tracking.city,
                        state: tracking.state,
                        locationTime: tracking.locationTime,
                      };
                    }) as unknown as Coordinate[]
                }
              />
            </Show>
          </div>
          <Show when={hasTrackingData()}>
            <Stack
              width="200px"
              direction="column"
              sx={{
                overflowY: 'auto',
                height: '500px',
                paddingLeft: '5px',
                paddingBottom: '2px',
              }}
              gap={0.25}
            >
              {orderStore.order.loads[orderStore.activeTab.index]
                .loadTracking &&
                getTrackingData().map((t) => {
                  return (
                    <Box
                      class="cursor-pointer"
                      onClick={() => {
                        setComment(trackingToComment(t));
                        openModal(customerUpdateModalId);
                      }}
                    >
                      <TrackingCard tracking={t} />
                    </Box>
                  );
                })}
            </Stack>
          </Show>
        </Stack>
        {checkCallEnabled() ? (
          <div>
            <div class="text-[#123b50] font-normal text-base font-roboto">
              Schedule Check Calls
            </div>
            <div class="flex flex-wrap items-center gap-2">
              <Box>
                <SelectField
                  backgroundColor={getLoadColor(
                    orderStore.order.loads[orderStore.activeTab.index].status,
                  )}
                  onChange={(e) => {
                    updateLoadPropertyAtIndex({
                      status: e.target.value,
                    });
                  }}
                  disableUnderline
                  label="Load Status"
                  variant="filled"
                  renderValue={(value) => {
                    return value;
                  }}
                  menuItems={loadStatusItems}
                  sxProps={styles.statusSelectStyle}
                  value={
                    orderStore.order.loads[orderStore.activeTab.index].status
                  }
                  size="small"
                  width="140px"
                />
              </Box>
              <Box>
                <Box
                  borderRadius="4px"
                  padding="4px 7px"
                  backgroundColor="#f0f0f0"
                  height="38px"
                  width="140px"
                >
                  <Typography
                    variant="body1"
                    component="div"
                    sxProps={{
                      ...subTextStyle,
                      textAlign: 'left',
                      marginBottom: '-6px',
                    }}
                  >
                    Next Check Call
                  </Typography>
                  <Box class="text-sm">
                    {
                      nextCheckCallByStatus[
                        orderStore.order.loads[orderStore.activeTab.index]
                          .status ?? 'None'
                      ]
                    }
                  </Box>
                </Box>
              </Box>
              <Box maxWidth="150px">
                {/*
                 * There's a weird reactivity issue here where the value of `isReadOnly` will never get
                 * passed to the `DatePicker`. So I added this `Show` trick to get around that for now.
                 */}

                <DatePicker
                  handleChange={(value) => handleDateTimeChange(value, 'date')}
                  value={nextCheckCallDateUtc()}
                  label="Next Call Date"
                  placeholder="MM/DD/YYYY"
                  size="small"
                />
              </Box>
              <Box width="120px">
                <TimeInput
                  handleChange={(value) => {
                    if (nextCheckCallDateUtc() === undefined) {
                      return;
                    }
                    handleDateTimeChange(value, 'time');
                  }}
                  value={nextCheckCallDateUtc()?.split('T')[1] ?? ''}
                  label="Next Call Time"
                  size="small"
                />
              </Box>
              <SButton
                sx={{ height: '37px' }}
                variant="outlined"
                color="primary"
                onClick={async () => {
                  const load =
                    orderStore.order.loads[orderStore.activeTab.index];
                  const localDate = load.nextCheckCallDateUtc;

                  if (localDate === undefined) {
                    openToast({
                      type: ToastType.Error,
                      position: 'top-right',
                      message: 'A valid date time is required',
                      duration: 2500,
                    });
                    return;
                  }

                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  if (load !== undefined) {
                    try {
                      const res = await postNextCheckCall(load.id, localDate);

                      if (!res) {
                        handleToast(
                          ToastType.Error,
                          'There was an issue updating the next check call date',
                        );
                      } else {
                        handleToast(ToastType.Success, 'Check call scheduled');
                      }
                    } catch (error) {
                      handleToast(ToastType.Error, (error as Error).message);
                    }
                  }
                }}
                disabled={orderStore.isReadOnly}
              >
                <span class="text-sm">SCHEDULE CHECK CALL</span>
              </SButton>
              <div class="ml-2">
                <CheckboxInput
                  size="small"
                  label="Hide Electronic Tracking Updates"
                  onChange={(v) => {
                    setHideTrackingComments(v);
                  }}
                  checked={hideTrackingComments()}
                />
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div class="text-[#123b50] font-normal text-base font-roboto ">
              Tracking Calls
            </div>
            <div class="flex items-center">
              <SelectField
                backgroundColor={getLoadColor(
                  orderStore.order.loads[orderStore.activeTab.index].status,
                )}
                onChange={(e) => {
                  updateLoadPropertyAtIndex({
                    status: e.target.value,
                  });
                }}
                disableUnderline
                label="Load Status"
                variant="filled"
                renderValue={(value) => {
                  return value;
                }}
                menuItems={loadStatusItems}
                sxProps={styles.statusSelectStyle}
                width="140px"
                value={
                  orderStore.order.loads[orderStore.activeTab.index].status
                }
              />
              <div class="ml-2">
                <CheckboxInput
                  size="small"
                  label="Hide Electronic Tracking Updates"
                  onChange={(v) => {
                    setHideTrackingComments(v);
                  }}
                  checked={hideTrackingComments()}
                />
              </div>
            </div>
          </div>
        )}
        <Show when={!isCreate}>
          <CustomerTracking
            activeCarrierTab={orderStore.activeTab.index}
            hideTrackingComments={hideTrackingComments()}
          />
        </Show>
      </div>
      <BasicModal
        id={customerUpdateModalId}
        title="Send Customer Update"
        footer={false}
        onClose={() => {
          closeModal(customerUpdateModalId);
        }}
        onBackdropClick={() => {
          closeModal(customerUpdateModalId);
        }}
        modalStyles={styles.basicModal}
      >
        <CustomerUpdateModal comment={comment()} excludeDate />
      </BasicModal>
    </Card>
  );
};
