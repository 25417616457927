export type Row = Record<string, string>;

export type Token = {
  token: string;
};

export type BaseApiResponseT<T> = {
  isSuccess: boolean;
  isFailure: boolean;
  value: T;
  error: {
    code: string;
    message: string;
  };
  errors: string[];
};

export type ContactProps = {
  name: string | undefined;
  phone1?: string | null;
  extension1?: string | null;
  email?: string | null | undefined;
  fax?: string | null;
  accountingGroup?: string;
  phone1Ext?: number | null;
  userName?: string | null;
  office?: string | null;
};

export type V3MessageData = {
  type: string;
};

export enum ReassignTypeEnum {
  CoveredBy = 'COVERED BY',
  AssignedTo = 'ASSIGNED TO',
}

export type CategoryT = {
  title: string;
  slug: string;
};

export enum OwnershipSource {
  Atg = 0,
  Vendor = 1,
}
