import { Checkbox, FormControlLabel, FormGroup } from '@suid/material';
import { Component, createEffect, createSignal, For } from 'solid-js';
import Tooltip from '@components/Tooltip';

import FormErrorComponent from './FormErrorComponent';

type CheckboxOption = {
  label: string;
  value: string;
  disabled?: boolean;
  description?: string;
};

export type CheckboxGroupFieldProps = {
  name: string;
  label: string;
  value?: string[];
  options: CheckboxOption[];
  onChange: (selectedValues: string[]) => void;
  error?: string | string[] | null;
};

export const CheckboxGroup: Component<CheckboxGroupFieldProps> = (props) => {
  const [selectedValues, setSelectedValues] = createSignal<string[]>(
    props.value || [],
  );

  const handleChange = (value: string) => {
    const newSelectedValues = selectedValues().includes(value)
      ? selectedValues().filter((v) => v !== value)
      : [...selectedValues(), value];
    setSelectedValues(newSelectedValues);
    props.onChange(newSelectedValues);
  };

  createEffect(() => {
    if (props.value && selectedValues() !== props.value) {
      setSelectedValues(props.value);
    }
  });

  return (
    <>
      <FormGroup>
        <For each={props.options}>
          {(option) =>
            Boolean(option.description) ? (
              <Tooltip
                text={option.description!}
                tootlTipClasses="z-10 !w-[100%]"
              >
                <FormControlLabel
                  value={option.value}
                  control={
                    <Checkbox
                      checked={selectedValues().includes(option.value)}
                      onChange={() => handleChange(option.value)}
                      disabled={option.disabled ?? false}
                    />
                  }
                  label={option.label}
                />
              </Tooltip>
            ) : (
              <FormControlLabel
                value={option.value}
                control={
                  <Checkbox
                    checked={selectedValues().includes(option.value)}
                    onChange={() => handleChange(option.value)}
                    disabled={option.disabled ?? false}
                  />
                }
                label={option.label}
              />
            )
          }
        </For>
      </FormGroup>
      <FormErrorComponent error={props.error} />
    </>
  );
};
