import { v3Client, v3FileUploadClient } from '@api/apiClient';
import { ToastType } from '@components';
import { createError } from '@utils/errorUtils';
import { handleToast } from '@utils/utils';
import { CommonFileUploadFiles } from '@components/FileUpload/CommonFileUpload';

import { ATGUserDataType, UserDetailsType } from './types';
import { setUserDetailStore, userDetailStore } from './store';

export const getUserDetailsApi = async (id: number) => {
  try {
    const response: unknown = await v3Client.get('/User/Details', {
      params: { id },
    });
    return response as UserDetailsType;
  } catch (e: unknown) {
    const error = createError(e, 'Unable to fetch user details');
    handleToast(ToastType.Error, error.message);
  }
};

export const fetchUserDetailDocumentsApi = async (id: string) => {
  try {
    const response: unknown = await v3Client.get(`/Document/User/${id}`);
    return response as CommonFileUploadFiles[];
  } catch (e: unknown) {
    const error = createError(e, 'Failed to fetch user documents list.');
    handleToast(ToastType.Error, error.message);
  }
};

export const uploadUserDetailDocumentApi = async (
  userId: number,
  file: File,
) => {
  try {
    const formData = new FormData();
    formData.append(`${file.name}`, file);
    const res = await v3FileUploadClient.post(
      `/Document/User/${userId}`,
      formData,
    );
    return res.data as unknown as {
      url: string;
    };
  } catch (e: unknown) {
    const error = createError(e, 'Document failed to upload');
    handleToast(ToastType.Error, error.message);
  }
};

export const deleteUserDetailDocumentApi = async (payload: FormData) => {
  try {
    const urlEncodedData = new URLSearchParams();
    for (const pair of payload.entries()) {
      urlEncodedData.append(pair[0], String(pair[1]));
    }
    await v3FileUploadClient.post('/Document/DeleteFile', urlEncodedData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  } catch (error: unknown) {
    throw new Error('Failed to delete user document');
  }
};

export const getATGUserDataApi = async () => {
  try {
    const response: unknown = await v3Client.get('/Data');
    return response as ATGUserDataType;
  } catch (e: unknown) {
    const error = createError(e, 'Failed to get roles and permissions info');
    handleToast(ToastType.Error, error.message);
  }
};

export const userProfilePhotoUploadApi = async (file: FormData) => {
  try {
    const response: { success: boolean; data: { photo: string } } =
      await v3FileUploadClient.post(
        `/User/UploadPhoto/${userDetailStore.userDetails.id}`,
        file,
      );
    if (response.success) {
      setUserDetailStore('userDetails', 'picture', response.data.photo);
      handleToast(
        ToastType.Success,
        'Profile Photo Updated. You look amazing.',
      );
      return true;
    }
  } catch (e: unknown) {
    const error = createError(e, 'Failed to upload image.');
    handleToast(ToastType.Error, error.message);
  }
};
