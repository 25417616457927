import Logout from '@suid/icons-material/Logout';
import PersonAdd from '@suid/icons-material/PersonAdd';
import Settings from '@suid/icons-material/Settings';
import {
  Box,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  IconButton,
} from '@suid/material';
import { createSignal } from 'solid-js';

import { Typography } from '../../Typography';

const avatarText = {
  color: 'white',
  fontSize: '14px',
};

export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = createSignal<null | HTMLElement>(null);
  const open = () => Boolean(anchorEl());
  const handleClose = () => setAnchorEl(null);

  const handleMyAccountClick = () => {
    window.location.href = '/user/details';
    handleClose();
  };

  return (
    <>
      <Box class="flex items-center text-center">
        <IconButton
          title="Account settings"
          onClick={(event) => setAnchorEl(event.currentTarget)}
          size="small"
          class="flex gap-2"
          aria-controls={open() ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open() ? 'true' : undefined}
        >
          <Avatar class="!h-7 !w-7">
            <Typography sxProps={avatarText} component="p" variant="body1">
              M
            </Typography>
          </Avatar>
          <Typography sxProps={avatarText} component="p" variant="body1">
            Username
          </Typography>
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl()}
        id="account-menu"
        open={open()}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
      >
        <MenuItem>
          <Avatar /> Profile
        </MenuItem>
        <MenuItem onClick={handleMyAccountClick}>
          <Avatar /> My account
        </MenuItem>
        <Divider />
        <MenuItem>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          Add another account
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}
