import { Delete, UploadFile } from '@suid/icons-material';
import { Box, Stack } from '@suid/material';

import { CommonFileUploadFiles } from './CommonFileUpload';

type PropsT = {
  file: CommonFileUploadFiles;
  onDelete: () => void;
};

export const FileCard = (props: PropsT) => {
  return (
    <Stack direction="row" p="10px 0">
      <Box
        displayRaw="flex"
        alignItems="center"
        justifyContent="center"
        width="50px"
      >
        <UploadFile />
      </Box>
      <Box flex={1} displayRaw="flex" alignItems="center">
        <Box fontWeight={700} fontSize="14px" lineHeight={1}>
          {props.file.name}
        </Box>
        <Box fontSize="14px" color="#444">
          {props.file.dateAdded}
        </Box>
      </Box>
      <Box
        displayRaw="flex"
        alignItems="center"
        justifyContent="center"
        width="50px"
      >
        <Delete
          class="text-[#B00020] cursor-pointer"
          onclick={props.onDelete}
        />
      </Box>
    </Stack>
  );
};
