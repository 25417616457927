import { LTLLoadItem } from '@store/ltl/types';
import { LTLWeightLimit } from '@views/ltl';

export type LTLItem = {
  lengthInch: number | null;
  widthInch: number | null;
  heightInch: number | null;
  weight?: number;
  quantity: number;
  uom: string;
  density: number;
  class: number;
  suggestedClass?: number | null;
};
// SHOULD WE GO AHEAD AND REMOVE THESE 2 FUNCTION??
export const calculateAdjustedCubicFeet = (model?: LTLLoadItem) => {
  if (model === undefined) return 0;
  if (
    model.lengthInch !== null &&
    model.widthInch !== null &&
    model.heightInch !== null &&
    model.quantity &&
    model.weight !== null
  ) {
    return (model.lengthInch * model.widthInch * model.heightInch) / 1728;
  }
  return 0;
};
// SHOULD WE ADD THIS TO THE CALCULATIONS BACKEND?
export const getTotalShipmentPCF = (lineItems?: LTLLoadItem[]) => {
  if (lineItems === undefined) return 0;
  let total = 0;
  lineItems.forEach((item) => {
    total += calculateAdjustedCubicFeet(item) * item.quantity;
  });
  if (total > 0 && getTotalWeight(lineItems) > 0) {
    const totalPCF = getTotalWeight(lineItems) / total;
    return totalPCF.toFixed(2);
  }
  return 0;
};

export const calculateDensity = (model?: LTLLoadItem) => {
  if (
    model === undefined ||
    model.weight === null ||
    Number(model.weight) === 0
  ) {
    return '0';
  }

  if (
    model.lengthInch !== null &&
    model.widthInch !== null &&
    model.heightInch !== null &&
    model.quantity
  ) {
    // if (lengthInch && widthInch && heightInch && weight && quantity) {
    const adjustedCubicFeet = calculateAdjustedCubicFeet(model);
    const unitsAdjustment = model.uom === 'kgs' ? 2.2 : 1;
    const density =
      (Number(model.weight) * unitsAdjustment) /
      adjustedCubicFeet /
      model.quantity;
    return !isFinite(density) ? 0 : density.toFixed(4);
  }
  return '0';
};

export const getTotalWeight = (lineItems?: LTLLoadItem[]) => {
  if (lineItems === undefined) return 0;
  let total = 0;
  lineItems.forEach((item) => {
    total += Number(item.weight ?? 0);
  });
  return total;
};

export const singleWeightTooMuch = (item: LTLLoadItem) => {
  return (
    item.quantity >= 1 &&
    Number(item.weight ?? 0) / item.quantity > LTLWeightLimit.Single
  );
};

export const recommendClassForLoadItem = (item: LTLLoadItem) => {
  const density = calculateDensity(item);
  item.density = density.toString();
  if (!Boolean(density) || density === '0') return;

  const thresholds = [
    { class: 500, maxDensity: 1 },
    { class: 400, maxDensity: 2 },
    { class: 300, maxDensity: 3 },
    { class: 250, maxDensity: 4 },
    { class: 200, maxDensity: 5 },
    { class: 175, maxDensity: 6 },
    { class: 150, maxDensity: 7 },
    { class: 125, maxDensity: 8 },
    { class: 110, maxDensity: 9 },
    { class: 100, maxDensity: 10.5 },
    { class: 92.5, maxDensity: 12 },
    { class: 85, maxDensity: 13.5 },
    { class: 77.5, maxDensity: 15 },
    { class: 70, maxDensity: 22.5 },
    { class: 65, maxDensity: 30 },
    { class: 60, maxDensity: 35 },
    { class: 55, maxDensity: 50 },
    { class: 50, maxDensity: Infinity },
  ];
  const theClass = thresholds
    .find((threshold) => Number(density) < threshold.maxDensity)
    ?.class.toString();

  if (Boolean(theClass) && item.class !== theClass) return theClass;
  return 0;
};

export const remappedCountryCode = (country: string | undefined | null) => {
  if (!Boolean(country)) return '';
  const newCountry: { [key: string]: string } = {
    US: 'USA',
    USA: 'USA',
    CA: 'CAN',
    MX: 'MEX',
    Canada: 'CAN',
    Mexico: 'MEX',
  };
  return newCountry[country!];
};
