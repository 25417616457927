import { Notification } from '@components/Notification';
import { closeModal, openModal } from '@store/modals';
import { orderStore } from '@store/orders/store';
import { convertUtcToLocalAndFormat } from '@utils/dateFormat';

import { CommentCount } from './CommentCount';
import { NeedsApprovalModal } from './components/models/NeedsApprovalModal';

export const RenderNeedApprovalModal = () => {
  if (orderStore.order.needsApprovalFlag) {
    return (
      <div class="mb-3 relative">
        <Notification
          type={orderStore.order.needsApprovalFlag.flagType}
          commentLength={CommentCount(
            orderStore.order.needsApprovalFlag.commentCount,
          )}
          getCustomStyle={() => {
            if (!Boolean(orderStore.order.needsApprovalFlag?.cleared)) {
              return 'border-[#8BD0FF] bg-[#2989D8]/10 cursor-pointer';
            }
          }}
          text={`Needs Approval - ${orderStore.order.needsApprovalFlag.description}`}
          subText={`${
            orderStore.order.needsApprovalFlag.setBy
          } - ${convertUtcToLocalAndFormat(
            orderStore.order.needsApprovalFlag.setDate as string,
            'MMM dd, yyyy h:mm a',
          )}`}
          onClick={() => openModal('needs-approval-modal')}
          cleared={orderStore.order.needsApprovalFlag.cleared}
          disputed={
            (orderStore.order.needsApprovalFlag.disputedOrderFlags ?? []).find(
              (f) => {
                return f.clearedDate === undefined || f.clearedDate === null;
              },
            ) !== undefined
          }
          needsApprovalFlag
        />
        <NeedsApprovalModal
          modalId="needs-approval-modal"
          onClose={() => closeModal('needs-approval-modal')}
          flagData={orderStore.order.needsApprovalFlag}
        />
      </div>
    );
  }
};
