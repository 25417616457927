import { lazy } from 'solid-js';

const UserDetails = lazy(() => import('./UserDetails'));
import { UserDetailsHeader } from './UserDetailsHeader';

const UserDetailsRoute = {
  path: '/user/details/:id?',
  component: UserDetails,
};

export { UserDetailsRoute, UserDetailsHeader };
