import { getClaimDataApi } from './services';
import { setClaimDataStore } from './store';

export const getClaimDataAction = async (id: number) => {
  const data = await getClaimDataApi(id);
  if (data) {
    setClaimDataStore('claimDetails', data);
  }
};

export const deleteCommentInClaimStore = (commentId: number) => {
  setClaimDataStore('claimDetails', 'comments', (comments) =>
    comments.filter((c) => c.id !== commentId),
  );
};
