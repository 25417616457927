import { lazy } from 'solid-js';

const NewProspectPage = lazy(() => import('./NewProspectPage'));
const ProspectPage = lazy(() => import('./ProspectPage'));

export const NewProspectRoute = {
  path: '/prospect/Create',
  component: NewProspectPage,
};

export const ProspectRoute = {
  path: '/prospect',
  component: ProspectPage,
};
