import { apiClient } from '@api/apiClient';
import { ToastType } from '@components';
import { handleToast } from '@utils/utils';

import { ILTLCustomerData } from './types';
import { V4ApiResponse } from '../types';
import { setCustomerLTLSettings } from './store';

export const fetchCustomerLTLData = async (customerId: string) => {
  try {
    const response: V4ApiResponse<ILTLCustomerData> = await apiClient.get(
      `LTLSettings/${customerId}`,
    );
    if (response.isSuccess) {
      return response.value;
    }
  } catch (error: unknown) {
    handleToast(ToastType.Error, 'Failed to fetch customer LTL Data');
    throw new Error(`'Failed to fetch customer LTL Data:  ${error}`);
  }
};

const handleError = (error: unknown, toastMessage: string) => {
  if (Array.isArray(error)) {
    const errorMessages = error.map((v) => {
      if (typeof v === 'string') {
        return v;
      } else if (typeof v === 'object' && v !== null && 'errorMessage' in v) {
        return (v as { errorMessage: string }).errorMessage;
      }
      return 'Unspecified/unknown error';
    });
    setCustomerLTLSettings('errorMessage', errorMessages.join(', '));
  }
  handleToast(ToastType.Error, toastMessage);
};

export const updateCustomerLTLData = async (payload: ILTLCustomerData) => {
  try {
    const response: V4ApiResponse<ILTLCustomerData> = await apiClient.put(
      'LTLSettings',
      payload,
    );
    handleToast(ToastType.Success, 'LTL Setting saved Successfully');
    if (response.isSuccess) {
      return response.value;
    }
  } catch (error) {
    handleError(error, 'Failed to save customer LTL Data');
    throw new Error('Failed to save customer LTL Data');
  }
};
