//TODO: Tech Debt: MERGE THIS FILE WITH FLAGPOPUP.TSX AND MAKE FLAG POPUP GENERIC
import { BasicModal, Button, Select, ToastType, Typography } from '@components';
import {
  flagImgs,
  getOptionsByRole,
  infoMsgObj,
} from '@components/FlagPopup/constants';
import { LoadComment } from '@components/FlagPopup/LoadComment';
import { TextAreaField, TextInput } from '@components/forms';
import {
  addFlagCommentV4,
  CommentViewModel,
  deleteFlag,
  deleteFlagComment,
  disputeFlag,
  FlagType,
  FlagViewModel,
  orderStore,
  updateFlag,
  updateOrderState,
} from '@store/orders';
import { userStore } from '@store/user';
import { Check, DoDisturb, InfoOutlined, Settings } from '@suid/icons-material';
import { Box, Grid, Stack } from '@suid/material';
import { handleToast, isAdmin } from '@utils/utils';
import { isEmpty } from 'lodash';
import { createSignal } from 'solid-js';
import { createStore } from 'solid-js/store';
import * as yup from 'yup';

type InitialFormVals = {
  message: string;
  selectedFlag: string;
  emailId: string;
  description: string;
};

const schema = yup.object().shape({
  message: yup
    .string()
    .required('Comment with length greater than 0 is required to Approve.'),
  emailId: yup
    .string()
    .email('Invalid email')
    .required('Email is required')
    .max(40, 'Email must be at most 40 characters'),
  description: yup
    .string()
    .required('Description is required')
    .max(500, 'Description must be at most 500 characters'),
});

// eslint-disable-next-line complexity
export const NeedsApprovalModal = (props: {
  onClose: () => void;
  modalId: string;
  flagData: FlagViewModel;
}) => {
  const initialCommentCount = Boolean(props.flagData.commentCount)
    ? props.flagData.commentCount
    : 0;

  const [formValues, setFormValues] = createStore<InitialFormVals>({
    message: '',
    selectedFlag: props.flagData.flagType,
    emailId: '',
    description: props.flagData.description ?? '',
  });

  const [errorValues, setErrorValues] = createStore({
    message: '',
    emailId: '',
    description: '',
  });

  const [isLoading, setIsLoading] = createSignal(false);

  const validateField = (field: string, value: string) => {
    try {
      schema.validateSyncAt(field, { [field]: value });
      setErrorValues({ ...errorValues, [field]: '' });
    } catch (e: unknown) {
      if (e instanceof yup.ValidationError) {
        setErrorValues({ ...errorValues, [field]: e.message });
      }
    }
  };

  const handleDeleteFlagComment = async (id: number) => {
    await deleteFlagComment(id);
    const comments = [...(props.flagData.comments ?? [])].filter(
      (comment) => comment.id !== id,
    );
    updateOrderState({
      needsApprovalFlag: {
        ...props.flagData,
        comments,
        commentCount: comments.length,
      },
    });
  };

  const handleAddFlagComment = async (comment: string) => {
    const resp = (await addFlagCommentV4(
      props.flagData.id ?? 0,
      comment.trim(),
    )) as unknown as CommentViewModel;
    const comments = [...(props.flagData.comments ?? [])];
    comments.push(resp as unknown as CommentViewModel);
    updateOrderState({
      needsApprovalFlag: {
        ...props.flagData,
        comments,
        commentCount: comments.length,
      },
    });
    setFormValues('message', '');
  };

  const handleClose = () => {
    setFormValues('message', '');
    setErrorValues({
      message: '',
      emailId: '',
      description: '',
    });
    props.onClose();
  };

  const handleDisputed = async () => {
    const trimmedMessage = formValues.message.trim();
    if (trimmedMessage) {
      await handleAddFlagComment(trimmedMessage);
      await disputeFlag(props.flagData.id as number, userStore.user.name)
        .then((res) => {
          const comments = [...(props.flagData.comments ?? [])];
          comments.unshift({
            timestamp: res?.timestamp,
            comment: `${userStore.user.name} has Disputed the Carrier Invoice.`,
            name: userStore.user.name,
            me: true, // pushed in V3
          });

          //ATG.App.trigger(ATG.Event.APPROVAL_COMMENT, resp.data);
          if (res) {
            const disputedOrderFlags = [
              ...(props.flagData.disputedOrderFlags ?? []),
            ];
            disputedOrderFlags.push({
              clearedBy: undefined,
              clearedDate: undefined,
              disputedDate:
                res.timestamp?.toString() ?? new Date().toISOString(),
              flagId: props.flagData.id as number,
              // The guid below is a temprorary fix because the BE is not giving us the guid of the
              // new needs approval flag's disputed flag. If the operation type changes to anything
              // other than "None" that op will fail because this guid is here just so that we can
              // pass validation.
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              id: '00000000-0000-0000-0000-000000000000',
              operationType: 'None',
            });

            updateOrderState({
              needsApprovalFlag: {
                ...props.flagData,
                comments,
                disputedOrderFlags,
              },
            });
          }
        })
        .catch((error: Error) => {
          handleToast(ToastType.Error, error.message);
        });
      handleClose();
    }
  };

  const handleFlagDelete = async () => {
    await deleteFlag(props.flagData.id as number);
    updateOrderState({
      needsApprovalFlag: { ...props.flagData, cleared: true },
    });
  };

  const handleOkToProcess = async () => {
    const trimmedMessage = formValues.message.trim();
    if (trimmedMessage !== '') {
      await handleAddFlagComment(trimmedMessage);
      await handleFlagDelete();
      handleClose();
    } else {
      validateField('message', formValues.message);
    }
  };

  const handleStillWorking = async () => {
    if (formValues.message.trim() !== '') {
      await handleAddFlagComment(formValues.message);
      setFormValues('message', '');
      handleClose();
    } else {
      handleClose();
    }
  };

  const isNew = () => {
    if (props.flagData.id == null) return true;
    return props.flagData.id <= 0;
  };

  const handleUpdateCreateFlag = async () => {
    if (formValues.selectedFlag === 'Missing Paperwork') {
      if (formValues.emailId.trim() == '') {
        validateField('emailId', formValues.emailId);
        // ATG.App.message('Email Id is required', "Error"); //add this error to error object
        return false;
      }
      if (formValues.description.trim() != '') {
        if (formValues.description.indexOf(';') != -1) {
          setErrorValues({
            ...errorValues,
            description:
              'Semicolons are now allowed in either the Email Id and Description fields',
          });
          return false;
        } else if (formValues.emailId.indexOf(';') != -1) {
          setErrorValues({
            ...errorValues,
            emailId:
              'Semicolons are now allowed in either the Email Id and Description fields',
          });
          return false;
        }
        setFormValues('emailId', formValues.emailId.trim());
        setFormValues('description', formValues.description.trim());
      }
    } else {
      setFormValues('emailId', '');
    }
    //
    const payload = {
      ...props.flagData,
      flagType: formValues.selectedFlag as FlagType,
      description: formValues.description,
      emailId: formValues.emailId,
      me: false,
      mode: 'Queue',
    };

    await updateFlag(payload);
    // TODO to handle updating flag id needed

    handleClose();
  };

  const handleCloseOrDelete = async () => {
    if (!isEmpty(props.flagData.id) && (props.flagData.id ?? 0) > 0) {
      updateOrderState({
        needsApprovalFlag: { ...props.flagData, cleared: true, comments: [] },
      });
      await handleFlagDelete().then(() => {
        handleToast(ToastType.Success, 'Flag has been removed.');
      });
    }
    handleClose();
  };

  const isDisputable = () => {
    const disputedOrderFlags =
      orderStore.order.needsApprovalFlag?.disputedOrderFlags ?? [];

    if (props.flagData.cleared ?? false) {
      return false;
    } else if (disputedOrderFlags.length === 0) {
      return true;
    }

    return disputedOrderFlags.some((flag) => {
      return flag.clearedDate !== undefined && flag.clearedDate !== null;
    });
  };

  const shouldDisableButton = () => {
    const isMessageShort = formValues.message.trim().length < 3;
    let hasNoComments = false;
    if (props.flagData.commentCount !== undefined) {
      hasNoComments = props.flagData.commentCount <= initialCommentCount!;
    }
    return isMessageShort && hasNoComments;
  };

  const isLTL = () => orderStore.order.loads[0].mode === 'LTL';

  return (
    <BasicModal
      id={props.modalId}
      title="Needs Approval"
      width={'1000px'}
      onClose={handleClose}
      footer={false}
    >
      <>
        <Grid>
          <div class="bg-blue-50 p-2 text-xs flex items-center border-2 border-[#468DB5]">
            <InfoOutlined style={{ 'font-size': '24px', color: '#468DB5' }} />
            <span
              class="text-lg ml-3"
              innerHTML={infoMsgObj[formValues.selectedFlag]}
            />
          </div>
          {Boolean(props.flagData.editable) &&
            !['Billing Note', 'Billing Hold'].includes(
              formValues.selectedFlag,
            ) && (
              <Grid container class="my-3">
                <Grid item xs={11} spacing={4} class="align-items">
                  <Select
                    label="Queue"
                    menuItems={getOptionsByRole('admin')}
                    onChange={(e) =>
                      setFormValues('selectedFlag', e.target.value)
                    }
                    value={formValues.selectedFlag}
                  />
                </Grid>
                <Grid item xs={1} class="align-items justify-center flex">
                  <img src={flagImgs[formValues.selectedFlag]} alt="alt flag" />
                </Grid>
              </Grid>
            )}
          {formValues.selectedFlag === FlagType.MissingPaperwork ? (
            <Box class="!mb-3">
              <TextInput
                type="text"
                label="Email ID"
                size="medium"
                placeholder="Add an email address"
                value={formValues.emailId}
                classes="w-full"
                onChange={(emailId: string) => {
                  setFormValues('emailId', emailId);
                }}
                onBlur={() => validateField('emailId', formValues.emailId)}
                error={errorValues.emailId}
              />
            </Box>
          ) : undefined}
          {Boolean(props.flagData.editable) ||
          formValues.selectedFlag == 'Billing Note' ? (
            <TextAreaField
              label="Description"
              placeholder=""
              rows={1}
              value={formValues.description}
              onChange={(value: string) => {
                setFormValues('description', value);
              }}
              error={errorValues.description}
              disabled={
                !isAdmin() && props.flagData.flagType == 'Get Paperwork'
              }
              onBlur={() =>
                validateField('description', formValues.description)
              }
            />
          ) : (
            <div class="my-3">
              <Typography
                class="text-black !text-xl !font-medium"
                variant="h6"
                component="h2"
                sxProps={{ marginBottom: '10px' }}
              >
                Description{' '}
              </Typography>
              {props.flagData.description}
            </div>
          )}
        </Grid>
        {Boolean(props.flagData.commentable) && !isNew() && (
          <Box mt="18px">
            <Box>
              <Typography
                class="text-black !text-xl !font-medium  !leading-8 !tracking-[0.15px]"
                variant="h6"
                component="h2"
                sxProps={{ marginBottom: '10px' }}
              >
                Message History
              </Typography>
              {formValues.selectedFlag === 'Needs Approval' && isLTL() && (
                <p class="color-[#4863A0] mb-3">
                  To Dispute an invoice, please add a reason for the dispute
                  below. Then click on the "Dispute" button at the bottom.
                </p>
              )}
              <TextInput
                value={formValues.message}
                size="medium"
                label="Add a Comment"
                classes="w-full"
                onChange={(value: string) => {
                  setFormValues('message', value);
                  validateField('message', formValues.message);
                }}
                onKeyDown={async (e: KeyboardEvent) => {
                  if (
                    e.key === 'Enter' &&
                    formValues.message.trim() !== '' &&
                    !isLoading()
                  ) {
                    e.preventDefault();
                    setIsLoading(true);
                    await handleAddFlagComment(formValues.message);
                  }
                }}
                disabled={
                  formValues.selectedFlag === 'Get Paperwork' && !isAdmin()
                }
                onBlur={() => validateField('message', formValues.message)}
                error={errorValues.message}
              />
            </Box>
            <Box maxHeight="250px" overflow="auto" class="!my-4">
              <Stack direction="column">
                {props.flagData.comments?.toReversed().map((comment) => {
                  if (!Boolean(comment)) {
                    return <></>;
                  }

                  return (
                    <LoadComment
                      comment={comment}
                      onDelete={() => handleDeleteFlagComment(comment.id)}
                    />
                  );
                })}
              </Stack>
            </Box>
          </Box>
        )}
        <Box class="flex justify-end mt-14" gap={3}>
          {formValues.selectedFlag === 'Needs Approval' && !isNew() ? (
            <>
              <Button
                variant="contained"
                color="success"
                onClick={handleOkToProcess}
                label="Ok To Process"
                startIcon={
                  <Check fontSize="small" sx={{ marginRight: '5px' }} />
                }
              />
              <Button
                variant="contained"
                color="error"
                label="Still Working"
                onClick={handleStillWorking}
                startIcon={
                  <Settings fontSize="small" sx={{ marginRight: '5px' }} />
                }
              />
              {isLTL() ? (
                <Button
                  variant="contained"
                  color="warning"
                  disabled={!isDisputable() || shouldDisableButton()}
                  onClick={handleDisputed}
                  title={`${!isDisputable()} - ${shouldDisableButton()}`}
                  label="Dispute"
                  startIcon={
                    <DoDisturb fontSize="small" sx={{ marginRight: '5px' }} />
                  }
                />
              ) : undefined}
            </>
          ) : null}
          {formValues.selectedFlag !== 'Needs Approval' ||
          ['Billing Note', 'Billing Hold'].includes(formValues.selectedFlag) ? (
            <>
              <Button
                variant="contained"
                onClick={handleUpdateCreateFlag}
                label={
                  props.flagData.id !== undefined ||
                  ['Billing Note', 'Billing Hold'].includes(
                    formValues.selectedFlag,
                  ) ||
                  isNew()
                    ? 'Update Flag'
                    : 'Create Flag'
                }
              />
              {(Boolean(props.flagData.editable) && !isNew()) ||
                (['Billing Note', 'Billing Hold'].includes(
                  formValues.selectedFlag,
                ) && (
                  <Button
                    onClick={handleCloseOrDelete}
                    variant="contained"
                    color="error"
                    label={
                      !Boolean(props.flagData.commentable)
                        ? 'Remove Flag'
                        : 'Cancel'
                    }
                  />
                ))}
            </>
          ) : (
            ''
          )}
          <Button
            onClick={() => {
              setErrorValues({
                message: '',
                emailId: '',
                description: '',
              });
              handleClose();
            }}
            label="Cancel"
          />
        </Box>
      </>
    </BasicModal>
  );
};
