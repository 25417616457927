import { Notification } from '@components';
import {
  Carrier,
  carrierStore,
  LastUsedTopStopModel,
  orderStore,
} from '@store/orders';
import { getEligibleEntries } from '@utils/utils';
import { isEmpty, size } from 'lodash';
import { DateTime } from 'luxon';
import {
  Accessor,
  Component,
  Index,
  Setter,
  createEffect,
  createSignal,
} from 'solid-js';

export type LoadWarningsProps = {
  loadWarnings: Accessor<Array<string>>;
  setLoadWarnings: Setter<Array<string>>;
};

const LoadWarnings: Component<LoadWarningsProps> = (
  props: LoadWarningsProps,
) => {
  const [loadLightWarnings, setLoadLightWarnings] = createSignal<Array<string>>(
    [],
  );

  createEffect(() => {
    if (Boolean(carrierStore.carrier[orderStore.activeTab.index])) {
      const currentCarrier = carrierStore.carrier[orderStore.activeTab.index];
      const currentMode =
        orderStore.order.loads[orderStore.activeTab.index].mode;
      if (!currentCarrier.id) {
        return;
      }
      const warnings = [];
      if (currentCarrier.hasExpiredCargoInsurance)
        warnings.push('Carrier has expired cargo insurance.');

      if (currentCarrier.brokerAuthority)
        warnings.push('Use caution - this carrier has broker authority.');
      if (currentCarrier.hasExpiredLiabilityInsurance)
        warnings.push('Carrier has expired liability insurance.');

      if (currentCarrier.contacts.length == 0)
        warnings.push(
          // eslint-disable-next-line quotes
          "This carrier has no contacts. Rate confirmations can't be generated until you add a contact.",
        );
      else if (!currentCarrier.isActive && status != 'Conditional')
        // eslint-disable-next-line quotes
        warnings.push("Carrier isn't active.");

      if (!currentCarrier.advancesAllowed)
        // eslint-disable-next-line quotes
        warnings.push("This carrier can't receive fuel advances.");

      if (currentCarrier.blacklisted)
        warnings.push('This carrier is blacklisted for your office.');

      if (status == 'Conditional') {
        if (!currentCarrier.carrierActionPlan) {
          warnings.push(
            '( ! )  This carrier has a conditional safety rating and must be reviewed before booking, please contact the carrier team:  carriers@armstrongtransport.com. ',
          );
        } else {
          warnings.push('This carrier has a conditional safety rating.');
        }
      }
      if (!currentCarrier.ltlEnabled && currentMode === 'LTL') {
        warnings.push(
          'This is an LTL load and the carrier is not LTL enabled.',
        );
      }
      const carrierExpirationWarnings =
        carrierExpiredValidation(currentCarrier);
      if (carrierExpirationWarnings && carrierExpirationWarnings.length > 0)
        warnings.push(...carrierExpirationWarnings);
      props.setLoadWarnings(warnings);
    } else {
      props.setLoadWarnings([]);
    }
  });

  createEffect(() => {
    if (Boolean(carrierStore.carrier[orderStore.activeTab.index])) {
      const carrier = carrierStore.carrier[orderStore.activeTab.index];
      const { firstLoad } = carrier;
      const liteWarnings = [];
      if (firstLoad)
        liteWarnings.push(
          // eslint-disable-next-line quotes
          `This is Armstrong's first load with this Carrier. Take extra care before issuing advances of any kind.`,
        );

      setLoadLightWarnings(liteWarnings);
    } else {
      setLoadLightWarnings([]);
    }
  });

  const carrierExpiredValidation = (carrier: Carrier | undefined) => {
    const warnings: string[] = [];
    if (!carrier) return warnings;
    if (
      carrier.id &&
      !['Billed', 'Paid', 'Closed'].includes(
        orderStore.order.loads[orderStore.activeTab.index].status!,
      )
    ) {
      const cargoExpiration = DateTime.fromISO(carrier.cargoExpiration);
      const liabilityExpiration = DateTime.fromISO(carrier.liabilityExpiration);
      const stops = getEligibleEntries<LastUsedTopStopModel>(
        orderStore.order.loads[orderStore.activeTab.index].stops,
      );
      if (size(stops) > 0) {
        const tempArray = stops.map((stop) =>
          DateTime.fromISO(stop.stopDateTime!).plus({ days: 7 }),
        );
        const stopDate =
          size(tempArray) > 0 ? DateTime.max(...tempArray) : null;
        if (!stopDate) return;
        if (
          (!carrier.hasExpiredCargoInsurance && cargoExpiration <= stopDate) ||
          (!carrier.hasExpiredCargoInsurance && liabilityExpiration <= stopDate)
        ) {
          warnings.push(
            // eslint-disable-next-line quotes
            'Please review to ensure insurance date covers your full load. If not, reach out to carrier team to get an updated insurance certificate.',
          );
        } else {
          const warningIndex = warnings.indexOf(
            // eslint-disable-next-line quotes
            'Please review to ensure insurance date covers your full load. If not, reach out to carrier team to get an updated insurance certificate.',
          );
          if (warningIndex > -1) {
            warnings.splice(warningIndex, 1);
          }
        }
      }
    }
    return warnings;
  };

  return (
    <>
      {((props.loadWarnings().length &&
        !isEmpty(carrierStore.carrier[orderStore.activeTab.index])) ||
        loadLightWarnings().length) && (
        <div class="flex flex-col gap-y-2">
          <Index each={props.loadWarnings()}>
            {(item: Accessor<string>) => (
              <Notification type="error" text={item()} />
            )}
          </Index>
          <Index each={loadLightWarnings()}>
            {(item: Accessor<string>) => (
              <Notification type="warning" text={item()} />
            )}
          </Index>
        </div>
      )}
    </>
  );
};

export default LoadWarnings;
