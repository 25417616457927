import { IsFeatureFlagEnabledByName } from '@store/Global/service';
import { createStore, produce } from 'solid-js/store';

import { FeatureFlagState } from './types';

export const [featureFlagStore, setFeatureFlagStore] =
  createStore<FeatureFlagState>({
    isLoading: true,
    isError: false,
    errorMessage: '',
    flags: {
      isReadFromNewLtlTables: {
        enabled: false,
        name: 'ltl_VNEXT-2856_TableRefactor_ReadFromNew',
      },
      isWriteToOldLtlTables: {
        enabled: true,
        name: 'ltl_VNEXT-2856_TableRefactor_WriteToOld',
      },
      // Add other flags here if necessary
    },
    overlay: false,
  });

export const setFlagValue = (
  flagName: string,
  flagProperty: string,
  enabled?: boolean,
) => {
  setFeatureFlagStore(
    produce((draft: FeatureFlagState) => {
      if (flagProperty in draft.flags) {
        draft.flags[flagProperty]!.enabled = enabled;
      } else {
        draft.flags[flagProperty] = {
          enabled: enabled,
          name: flagName,
        };
      }
      draft.isLoading = false;
    }),
  );
};

export const setFailedFeatureFlagValueGet = (flagName: string) => {
  setFeatureFlagStore(
    produce((draft: FeatureFlagState) => {
      draft.isError = true;
      draft.errorMessage = `Failed getting read for feature flag ${flagName}`;
      draft.isLoading = false;
    }),
  );
};

export const getFeatureFlag = async (
  flagName: string,
  flagProperty: string,
) => {
  try {
    setFeatureFlagStore('isLoading', true);
    const resp = (await IsFeatureFlagEnabledByName(flagName)) ?? false;
    setFlagValue(flagName, flagProperty, resp);
  } catch (e) {
    setFailedFeatureFlagValueGet(flagName);
  } finally {
    setFeatureFlagStore('isLoading', false);
  }
};

export const fetchReadFromNewLtlTables = async () => {
  const flagName = 'ltl_VNEXT-2856_TableRefactor_ReadFromNew';
  const flagProperty = 'isReadFromNewLtlTables';
  await getFeatureFlag(flagName, flagProperty);
};

export const fetchWriteToOldLtlTables = async () => {
  const flagName = 'ltl_VNEXT-2856_TableRefactor_WriteToOld';
  const flagProperty = 'isWriteToOldLtlTables';
  await getFeatureFlag(flagName, flagProperty);
};

export const GetStopMileageEnabled = async () => {
  const flagName = 'StopMileage';
  const flagProperty = 'isStopMileage';
  await getFeatureFlag(flagName, flagProperty);
};

export const GetCarrierContactOwnershipSourceEnabled = async () => {
  const flagName = 'CarrierContactOwnershipSource';
  const flagProperty = 'isCarrierContactOwnershipSource';
  await getFeatureFlag(flagName, flagProperty);
};

export const isCarrierContactOwnershipSourceEnabled = () => {
  return Boolean(
    featureFlagStore.flags['isCarrierContactOwnershipSource']?.enabled,
  );
};

export const isReadFromNewLtlTablesEnabled = () => {
  return Boolean(featureFlagStore.flags['isReadFromNewLtlTables']?.enabled);
};

export const isWriteToOldLtlTablesEnabled = () => {
  return Boolean(featureFlagStore.flags['isWriteToOldLtlTables']?.enabled);
};

export const isStopMileageEnabled = () => {
  return Boolean(featureFlagStore.flags['isStopMileage']?.enabled);
};

export const isTrackingFrequencyEnabled = () => {
  return Boolean(featureFlagStore.flags['TrackingFrequency']?.enabled);
};

export const isCarrierMatchingEnabled = () => {
  return Boolean(featureFlagStore.flags['CarrierMatching']?.enabled);
};
