import { ICellRendererParams } from '@ag-grid-community/core';
import { ToastType } from '@components/Toast';
import { CheckboxInput } from '@components/forms';
import { ILoadBoardViewModel } from '@store/loadboard';
import { quickPostLoad } from '@store/orders/actions';
import { userStore } from '@store/user';
import { handleToast } from '@utils/utils';
import { Show } from 'solid-js';

const RenderPostings = (data: ICellRendererParams<ILoadBoardViewModel>) => {
  return (
    <Show when={data.data} fallback={<></>}>
      <div>
        <CheckboxInput
          label=""
          checked={Boolean(data.data?.postingId)}
          onChange={() => {
            if (Boolean(userStore.user.postingGroupId)) {
              void quickPostLoad(
                data.data?.loadId as number,
                data.data?.postingId as number,
              );
            } else {
              handleToast(
                ToastType.Error,
                'Cannot Post, No Posting Group Configured',
              );
            }
          }}
        />
      </div>
    </Show>
  );
};

export default RenderPostings;
