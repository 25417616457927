import { createStore } from 'solid-js/store';

import { OfficeDetailStateType } from './types';

const [officeDetailStore, setOfficeDetailStore] =
  createStore<OfficeDetailStateType>({
    isLoading: true,
    isError: false,
    officeDetailErrors: null,
    officeDetail: null,
    officeDocuments: [],
    Recruiters: [],
    rolesOptions: [],
    permissionsOptions: [],
  });

export { officeDetailStore, setOfficeDetailStore };
