import { ICellRendererParams } from '@ag-grid-community/core';
import { CheckboxInput } from '@components/forms';
import { ILoadBoardViewModel } from '@store/loadboard';
import { Box } from '@suid/material';
import { Show } from 'solid-js';

export const TrackingCellRenderer = (
  data: ICellRendererParams<ILoadBoardViewModel>,
) => {
  return (
    <Show when={data.data} fallback={<></>}>
      <Box displayRaw="flex" justifyContent="center" width="100%" ml="8%">
        <CheckboxInput
          label=""
          disabled
          checked={Boolean(data.data?.hasTracking)}
        />
      </Box>
    </Show>
  );
};
