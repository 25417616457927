import { IManagePostLoad } from './types';

export const mapEditPostLoad = (formValues: IManagePostLoad) => {
  return {
    id: formValues.id,
    loadId: formValues.loadId,
    boardsPostedTo: formValues.boardsPostedTo,
    servicesToPostTo: formValues.servicesToPostTo,
    origin: formValues.origin,
    originCity: formValues.originCity,
    originState: formValues.originState,
    originCountry: formValues.originCountry,
    destination: formValues.destination,
    destinationCity: formValues.destinationCity,
    destinationState: formValues.destinationState,
    destinationCountry: formValues.destinationCountry,
    price: formValues.price,
    pickUp: formValues.pickUp,
    dropOff: formValues.dropOff,
    equipment: formValues.equipment,
    comment: formValues.comment,
    drops: formValues.drops,
    length: formValues.length,
    weight: Math.round(formValues.weight), // Round weight to an integer
    fullTruckload: formValues.fullTruckload,
    internalNote: formValues.internalNote,
    mileage: formValues.mileage,
    tags: formValues.tags,
  };
};
