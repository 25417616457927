import { Button, ToastType } from '@components';
import { TextAreaField } from '@components/forms';
import {
  addCollectionComment,
  customerDetailsStore,
  deleteCollectionComments,
  editCollectionComment,
  setCustomerDetailsStore,
} from '@store/customers/customerDetails';
import { Cancel } from '@suid/icons-material';
import { Box, Grid } from '@suid/material';
import { Comment } from '@store/customers/customerDetails/types';
import { createSignal, Show } from 'solid-js';
import { convertUtcToLocalAndFormat } from '@utils/dateFormat';
import { handleToast, isAdmin, isSuperAdmin } from '@utils/utils';
import AtgLoader from '@components/AtgLoader';
import { userStore } from '@store/user';

export const Collections = () => {
  const [comment, setComment] = createSignal('');
  const [editComment, setEditComment] = createSignal<{
    id: number | null;
    value: string;
  }>({
    id: null,
    value: '',
  });
  const [isProcessing, setIsProcessing] = createSignal(false);

  const validateCommentLength = (comment: string) => {
    if (comment.length > 1500) {
      handleToast(
        ToastType.Error,
        'Collection comment cannot exceed 1500 characters',
      );
      return false;
    }
    return true;
  };

  const handleAddComment = async () => {
    if (!validateCommentLength(comment())) {
      return false;
    }
    const { id } = customerDetailsStore.customer;
    setIsProcessing(true);
    await addCollectionComment(Number(id), comment(), () => {
      setIsProcessing(false);
      setComment('');
    });
  };

  const handleEditKeyDown = async (e: KeyboardEvent, id: number) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (!validateCommentLength(editComment().value)) {
        return false;
      }
      const payload = {
        id,
        comment: editComment().value,
        objectId: Number(customerDetailsStore.customer.id),
        type: 'Collections',
      };
      setIsProcessing(true);
      const resp = await editCollectionComment(payload);
      if (resp && resp.isSuccess) {
        const updatedComments =
          customerDetailsStore.customer.collectionComments?.map((comment) =>
            comment.id === id
              ? { ...comment, comment: editComment().value }
              : comment,
          );
        if (updatedComments) {
          setCustomerDetailsStore(
            'customer',
            'collectionComments',
            updatedComments,
          );
        }
        handleToast(
          ToastType.Success,
          'Collection comment updated successfully',
        );
      }
      setEditComment({ id: null, value: '' });
      setIsProcessing(false);
    }
  };

  const handleDeleteComment = async (commentId: number) => {
    setIsProcessing(true);
    await deleteCollectionComments(commentId, () => {
      setIsProcessing(false);
    });
  };

  const CommentCard = (props: { commentData: Comment }) => {
    return (
      <Box>
        <Box class="flex bg-[#006fa0] text-white p-3 rounded-t-md">
          {props.commentData.name}
          <Box class="ml-auto flex items-center">
            <span class="inline-flex">
              {convertUtcToLocalAndFormat(
                props.commentData.timestamp,
                'MMM d, yyyy h:mm a',
              )}
            </span>
            <Show
              when={
                isSuperAdmin() ||
                (isAdmin() && props.commentData.userId === userStore.user.id)
              }
            >
              <Cancel
                class="cursor-pointer ml-2"
                onClick={() => handleDeleteComment(props.commentData.id)}
              />
            </Show>
          </Box>
        </Box>
        <Box class="p-4 border-2 border-t-0 rounded-b-md flex">
          <Box class="flex flex-col w-full">
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{
                  marginTop: '5px',
                  marginLeft: '5px',
                  minHeight: '100px',
                  maxHeight: '150px',
                  overflow: 'auto',
                }}
              >
                {editComment().id === props.commentData.id ? (
                  <TextAreaField
                    rows={3}
                    value={editComment().value}
                    onChange={(value: string) =>
                      setEditComment({
                        id: props.commentData.id,
                        value,
                      })
                    }
                    onKeyPress={(e) =>
                      handleEditKeyDown(e, props.commentData.id)
                    }
                    onFocus={() => setComment('')}
                    disabled={isProcessing()}
                  />
                ) : (
                  <div
                    onClick={() =>
                      setEditComment({
                        id: props.commentData.id,
                        value: props.commentData.comment,
                      })
                    }
                  >
                    {props.commentData.comment.split('\n').map((line) => (
                      <span class="break-all">
                        {line}
                        <br />
                      </span>
                    ))}
                  </div>
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <div class="min-h-[400px] h-[calc(100vh-210px)] overflow-auto p-[10px]">
      <Show when={isProcessing()}>
        <AtgLoader />
      </Show>
      <TextAreaField
        label="Comment"
        value={comment()}
        onChange={(value) => setComment(value)}
        disabled={isProcessing()}
        onFocus={() => {
          setComment('');
          setEditComment({ id: null, value: '' });
        }}
      />
      <Button
        onClick={handleAddComment}
        label="Submit"
        class="!mt-3"
        disabled={isProcessing() || comment().length === 0}
      />
      <Grid container spacing={2} class="!mt-2">
        {customerDetailsStore.customer.collectionComments?.map((comment) => (
          <Grid item xs={4}>
            <CommentCard commentData={comment} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
