import {
  carrierDetailsStore,
  Contact,
  setCarrierDetailsStore,
} from '@store/carriers';
import {
  hasPermission,
  isAdmin,
  isSuperAdmin,
  userHasRole,
} from '@utils/utils';
import { ValidationError } from 'yup';
import { Comments } from '@store/orders';
import { userStore } from '@store/user';
import { unwrap } from 'solid-js/store';

import { carrierDetailsSchema } from './components/validation';
import { CarrierContactVerificationStatuses } from '../constants';

export const isCarrierDetailsReadOnly = () => {
  return (
    !isAdmin() &&
    carrierDetailsStore.carrierDetails?.id !== undefined &&
    carrierDetailsStore.carrierDetails.id > 0
  );
};

export const isCarrierDetailsReadOnlyForSuperAdmin = () => {
  return (
    carrierDetailsStore.carrierDetails?.id !== undefined &&
    carrierDetailsStore.carrierDetails.id != 0 &&
    !isSuperAdmin()
  );
};

export const carrierDetailsCanEdit = () => {
  return userHasRole('Carrier Relations') || isSuperAdmin();
};

export const vendorHoldDisabled = () => {
  if (isAdmin() && !Boolean(carrierDetailsStore.carrierDetails?.vendorHold))
    return false;
  if (isSuperAdmin()) return false;
  return true;
};

export const handleCarrierDetailsValidation = () => {
  try {
    setCarrierDetailsStore('carrierDetailsErrors', null);
    carrierDetailsSchema.validateSync(carrierDetailsStore.carrierDetails, {
      abortEarly: false,
    });
    return true;
  } catch (validationError: unknown) {
    const errors: Record<string, string[]> = {};
    if (validationError instanceof ValidationError) {
      validationError.inner.forEach((error: ValidationError) => {
        if (error.path !== undefined) {
          errors[error.path] = error.errors;
        }
      });
      setCarrierDetailsStore('carrierDetailsErrors', errors);
      return false;
    }
  }
};

export const validateFieldForCarrier = async (
  validatePath: string,
  errorPath: string,
) => {
  const updateErrors = (error: unknown) => {
    const updatedErrors = {
      ...(unwrap(carrierDetailsStore.carrierDetailsErrors) || {}),
      [errorPath]: error,
    };
    if (error === undefined || error === null) delete updatedErrors[errorPath];
    setCarrierDetailsStore(() => ({
      carrierDetailsErrors: updatedErrors as Record<string, string[]>,
    }));
  };
  try {
    await carrierDetailsSchema.validateAt(
      validatePath,
      carrierDetailsStore.carrierDetails,
    );
    updateErrors(undefined);
  } catch (validationError: unknown) {
    if (validationError instanceof ValidationError) {
      updateErrors(validationError.errors);
    }
  }
};

export const canCarrierDeleteContact = (contact: Contact) => {
  if (
    contact.verificationStatus ==
    CarrierContactVerificationStatuses.VendorVerified
  ) {
    return false;
  } else if (
    contact.verificationStatus ==
      CarrierContactVerificationStatuses.UserVerified &&
    userHasRole('Carrier Relations Admin')
  ) {
    return true;
  } else if (
    contact.verificationStatus ==
      CarrierContactVerificationStatuses.Unverified &&
    Boolean(hasPermission('Edit Carrier Contacts'))
  ) {
    return true;
  }

  return false;
};

export const canCarrierEditContact = (contact: Contact) => {
  if (
    contact.verificationStatus ==
    CarrierContactVerificationStatuses.VendorVerified
  ) {
    return false;
  } else if (
    contact.verificationStatus ==
      CarrierContactVerificationStatuses.UserVerified &&
    Boolean(hasPermission('Edit Carrier Contacts')) &&
    userHasRole('Carrier Relations')
  ) {
    return true;
  } else if (
    contact.verificationStatus ==
      CarrierContactVerificationStatuses.Unverified &&
    Boolean(hasPermission('Edit Carrier Contacts'))
  ) {
    return true;
  }

  return false;
};

export const canDeleteCarrierComment = (comment: Comments) => {
  if (
    carrierDetailsStore.carrierDetails &&
    carrierDetailsStore.carrierDetails.ratings.filter(
      (x) => x.commentId === comment.id,
    ).length > 0
  )
    return false;
  if (isAdminOrOriginalAuthor(comment)) return true;
  return false;
};

export const isAdminOrOriginalAuthor = (comment: Comments) => {
  if (isAdmin()) return true;
  if (isSuperAdmin()) return true;
  if (isOriginalAuthor(comment)) return true;
  return false;
};

export const isOriginalAuthor = (comment: Comments) => {
  return comment.userId === userStore.user.id;
};

export const preventDashFn = (e: KeyboardEvent) => {
  if (e.key === '-') {
    e.preventDefault();
  }
};
