import { BasicTable } from '@components/BasicTable';
import { Button } from '@components/Button';
import { Popover } from '@components/Popover';
import { Typography } from '@components/Typography';
import {
  CheckboxInput,
  SelectField,
  TextAreaField,
  TextInput,
} from '@components/forms';
import { createForm } from '@felte/solid';
import { validator } from '@felte/validator-yup';
import {
  AddProductDetails,
  addProductDetails,
  deleteProductDetails,
  editProductDetails,
  fetchProductById,
} from '@store/customers/customerDetails';
import { LTLCatalogs } from '@store/orders';
import { Edit } from '@suid/icons-material';
import { Box, Divider, Grid, Stack, TableCell, TableRow } from '@suid/material';
import { freightClasses, unitTypes } from '@views/ltl';
import { HazardousMaterial } from '@views/order/components/carrier/stops/stopsEditor/HazardousMaterial';
import {
  Accessor,
  Component,
  Setter,
  Show,
  createSignal,
  onMount,
} from 'solid-js';
import * as yup from 'yup';
import { handleToast } from '@utils/utils';
import { ToastType } from '@components/Toast';

import { ProductFormSkeleton } from './ProductFormSkeleton';

const tableCellStyles = {
  borderBottom: 'none',
  minWidth: '100px',
  maxWidth: '100px',
  'word-break': 'break-all',
};

export type AddEditProductDetailsProps = {
  edit: Accessor<boolean>;
  setEdit: Setter<boolean>;
  customerId: number;
  selectedRow: Accessor<LTLCatalogs | null>;
  onModalClose: () => void;
  setSelectedRow: Setter<LTLCatalogs | null>;
  triggerAddUpdateFn: Accessor<'add' | 'update' | 'delete' | 'none'>;
  setTriggerAddUpdateFn: Setter<'add' | 'update' | 'delete' | 'none'>;
};

function formatDimension(feet: number | null, inches: number | null) {
  const feetPart = feet !== null ? `${feet}'` : '';
  const inchesPart = inches !== null ? ` ${inches}"` : '';
  return `${feetPart}${inchesPart}`;
}

export const AddEditProductDetails: Component<AddEditProductDetailsProps> = (
  props,
) => {
  const [isLoading, setIsLoading] = createSignal(false);
  const [isDeleteLoading, setIsDeleteLoading] = createSignal(false);

  const { form, setFields, data, errors, isSubmitting, setIsSubmitting } =
    createForm<AddProductDetails>({
      initialValues: {
        // id: '',
        unitType: 'Pallets',
        name: '',
        class: null,
        description: '',
        nmfc: '',
        hazmat: false,
        ltlCatalogItemHazmatDetail: {
          unNumber: '',
          packingGroup: '',
          hazardClass: '',
          piece: '',
          contactNumber: '',
          // lTLCatalogId: '',
          contactName: '',
          createdDate: null,
          createdBy: null,
          modifiedDate: null,
          historyContext: null,
          modifiedBy: null,
          lTLCatalog: null,
        },
        lengthFeet: null,
        lengthInches: null,
        widthFeet: null,
        widthInches: null,
        heightFeet: null,
        heightInches: null,
        note: '',
        customerId: props.customerId,
      },
      extend: validator({
        schema: yup.object().shape({
          name: yup.string().required('Product Name is required.'),
          description: yup.string().required('Product Description is required'),
        }),
      }),
      onSubmit: async (values) => {
        setIsSubmitting(true);
        const id = props.selectedRow()?.id;
        if (props.edit() && Boolean(id)) {
          const res = await editProductDetails({ ...values, id });
          if (Boolean(res)) {
            props.setSelectedRow({
              ...res,
              nMFC: res ? res.nmfc : '',
            } as unknown as LTLCatalogs);
            props.setTriggerAddUpdateFn('update');
            handleToast(ToastType.Success, 'Product Added Successfully');
            props.onModalClose();
          }
        } else {
          const res = await addProductDetails(values);
          if (Boolean(res)) {
            props.setSelectedRow({
              ...res,
              nMFC: res ? res.nmfc : '',
            } as unknown as LTLCatalogs);
            props.setTriggerAddUpdateFn('add');
            handleToast(ToastType.Success, 'Product Updated Successfully');
            props.onModalClose();
          }
        }
        setIsSubmitting(false);
      },
    });

  const getProductDetailsById = async (id: string) => {
    setIsLoading(true);
    const res = await fetchProductById(id);
    if (Boolean(res)) {
      setFields('name', res.name);
      setFields('description', res.description);
      setFields('unitType', res.unitType);
      setFields('class', Number(res.class));
      setFields('nmfc', res.nmfc);
      setFields('hazmat', res.hazmat);
      setFields('lengthFeet', Math.floor(res.lengthInches! / 12));
      setFields('lengthInches', Math.floor(res.lengthInches! % 12));
      setFields('widthFeet', Math.floor(res.widthInches! / 12));
      setFields('widthInches', Math.floor(res.widthInches! % 12));
      setFields('heightFeet', Math.floor(res.heightInches! / 12));
      setFields('heightInches', Math.floor(res.heightInches! % 12));
      setFields('note', res.note);
      setFields('ltlCatalogItemHazmatDetail', res.ltlCatalogItemHazmatDetail);
    }
    setIsLoading(false);
  };

  const deleteProduct = async () => {
    setIsDeleteLoading(true);
    const id = props.selectedRow()?.id;
    if (Boolean(id)) {
      const res = await deleteProductDetails(id!);
      if (res) {
        props.setTriggerAddUpdateFn('delete');
        props.onModalClose();
      }
    }
    setIsDeleteLoading(false);
  };

  onMount(async () => {
    const id = props.selectedRow()?.id;
    if (props.edit() && Boolean(id)) {
      await getProductDetailsById(id!);
    }
  });

  const columns = [
    {
      name: 'Dimensions',
      key: 'dimensions',
    },
    {
      name: 'Feet',
      key: 'feet',
    },
    {
      name: 'Inches',
      key: 'inches',
    },
  ];

  const preventingNonNumeric = (e: KeyboardEvent) => {
    if (e.key === 'e' || e.key === '-' || e.key === '+') {
      e.preventDefault();
    }
  };

  return (
    <form ref={form} class="pt-2">
      <Show when={!isLoading()} fallback={<ProductFormSkeleton />}>
        <>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Stack spacing={2}>
                  <TextInput
                    label="Product Name"
                    value={data().name}
                    fullWidth
                    required
                    onChange={(e: string) => setFields('name', e)}
                    error={errors().name}
                    maxLength={60}
                  />
                  <TextInput
                    label="Product Description"
                    rows={3}
                    multiline
                    required
                    value={data().description}
                    onChange={(e: string) => setFields('description', e)}
                    error={errors().description}
                    maxLength={150}
                  />
                  <SelectField
                    label="Unit Type"
                    menuItems={unitTypes}
                    placeholder="Select Unit Type"
                    value={data().unitType || ''}
                    onChange={(e) => setFields('unitType', e.target.value)}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Stack spacing={2}>
                  <SelectField
                    label="Class"
                    menuItems={freightClasses}
                    value={data().class?.toString()}
                    onChange={(e) => setFields('class', Number(e.target.value))}
                  />
                  <TextInput
                    label="NMFC"
                    fullWidth
                    value={data().nmfc}
                    onChange={(e: string) => setFields('nmfc', e)}
                    maxLength={10}
                  />
                  <Divider />
                  <Popover
                    forceClosePopover={!data().hazmat}
                    eleName={
                      <Box displayRaw="flex" gap={1} alignItems="center">
                        <CheckboxInput
                          label="Hazmat"
                          checked={data().hazmat}
                          onChange={(e) => {
                            setFields('hazmat', e);
                            if (e === false) {
                              setFields('ltlCatalogItemHazmatDetail', null);
                            }
                          }}
                        />
                        <Edit
                          sx={{
                            color: data().hazmat ? 'text-[#026EA1]' : 'gray',
                          }}
                        />
                      </Box>
                    }
                  >
                    {(closePopover) => (
                      <HazardousMaterial
                        fromSidebar={true}
                        loadItemHazmatDetail={{
                          ...data().ltlCatalogItemHazmatDetail,
                          unNumber: data().ltlCatalogItemHazmatDetail?.unNumber,
                        }}
                        onSave={(hazmatDetail) => {
                          setFields('ltlCatalogItemHazmatDetail', {
                            unNumber: hazmatDetail.unNumber,
                            packingGroup: hazmatDetail.packingGroup,
                            hazardClass: hazmatDetail.hazardClass,
                            piece: hazmatDetail.piece!,
                            contactNumber: hazmatDetail.contactNumber,
                            contactName: hazmatDetail.contactName,
                            ltlCatalogId:
                              data().ltlCatalogItemHazmatDetail?.ltlCatalogId,
                            createdDate: null,
                            createdBy: null,
                            modifiedDate: null,
                            historyContext: null,
                            modifiedBy: null,
                            lTLCatalog: null,
                          });
                          setFields('hazmat', true);

                          closePopover();
                        }}
                        onClose={closePopover}
                      />
                    )}
                  </Popover>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={2}>
                  <Divider />
                  <BasicTable columns={columns}>
                    <TableRow>
                      <TableCell sx={tableCellStyles}>Length</TableCell>
                      <TableCell sx={tableCellStyles}>
                        <TextInput
                          type="number"
                          value={data().lengthFeet ?? ''}
                          maxLength={3}
                          onChange={(e) => setFields('lengthFeet', e as number)}
                          onKeyPress={preventingNonNumeric}
                        />
                      </TableCell>
                      <TableCell sx={tableCellStyles}>
                        <TextInput
                          type="number"
                          maxLength={3}
                          value={data().lengthInches ?? ''}
                          onChange={(e) =>
                            setFields('lengthInches', e as number)
                          }
                          onKeyPress={preventingNonNumeric}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={tableCellStyles}>Width</TableCell>
                      <TableCell sx={tableCellStyles}>
                        <TextInput
                          type="number"
                          maxLength={3}
                          value={data().widthFeet ?? ''}
                          onChange={(e) => setFields('widthFeet', e as number)}
                          onKeyPress={preventingNonNumeric}
                        />
                      </TableCell>
                      <TableCell sx={tableCellStyles}>
                        <TextInput
                          type="number"
                          maxLength={3}
                          value={data().widthInches ?? ''}
                          onChange={(e) =>
                            setFields('widthInches', e as number)
                          }
                          onKeyPress={preventingNonNumeric}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={tableCellStyles}>Height</TableCell>
                      <TableCell sx={tableCellStyles}>
                        <TextInput
                          type="number"
                          value={data().heightFeet ?? ''}
                          maxLength={3}
                          onChange={(e) => setFields('heightFeet', e as number)}
                          onKeyPress={preventingNonNumeric}
                        />
                      </TableCell>
                      <TableCell sx={tableCellStyles}>
                        <TextInput
                          type="number"
                          maxLength={3}
                          value={data().heightInches ?? ''}
                          onChange={(e) =>
                            setFields('heightInches', e as number)
                          }
                          onKeyPress={preventingNonNumeric}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        backgroundColor: '#DDE2EB',
                        height: '3.5rem',
                      }}
                    >
                      <TableCell sx={tableCellStyles}></TableCell>
                      <TableCell colspan={2} sx={tableCellStyles}>
                        <Typography variant="body1" component="p">
                          {formatDimension(
                            data().lengthFeet,
                            data().lengthInches,
                          )}
                          {data().widthFeet !== null ||
                          data().widthInches !== null
                            ? ' x '
                            : ''}
                          {formatDimension(
                            data().widthFeet,
                            data().widthInches,
                          )}
                          {data().heightFeet !== null ||
                          data().heightInches !== null
                            ? ' x '
                            : ''}
                          {formatDimension(
                            data().heightFeet,
                            data().heightInches,
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </BasicTable>
                  <Divider />
                  <TextAreaField
                    label="Note"
                    rows={3}
                    value={data().note}
                    onChange={(e) => setFields('note', e)}
                    maxLength={2000}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <Box displayRaw="flex" justifyContent="flex-end" mt={2} gap={2}>
            <Button
              disabled={isSubmitting() || isDeleteLoading()}
              isLoading={isSubmitting()}
              variant="contained"
              color="primary"
              label={props.edit() ? 'Update Product' : 'Add Product'}
              type="submit"
            />
            {props.edit() && (
              <Button
                disabled={isDeleteLoading() || isSubmitting()}
                isLoading={isDeleteLoading()}
                variant="contained"
                color="secondary"
                label="Delete Product"
                type="button"
                class="!bg-[#B00020]"
                onClick={deleteProduct}
              />
            )}
          </Box>
        </>
      </Show>
    </form>
  );
};
