import { Tab, TabsComponent, Typography } from '@components';
import { SavedQuotesGridLegacy } from '@components/Grid/SavedQuotesGridLegacy';
import { savedQuoteStore, setLtlQuoteState } from '@store/ltl/store';
import { Paper, Stack } from '@suid/material';
import { Match, Switch, createSignal, onMount } from 'solid-js';
import { HistoryQuotesGrid } from '@components/Grid/HistoryQuotesGrid';
import { SavedQuoteInfo } from '@store/ltl';
import { fetchSavedQuotes } from '@store/ltl/actions';
import {
  customerSavedQuotesStore,
  fetchSavedQuotesByCustomerId,
} from '@store/customers/customerDetails';
import { useParams } from '@solidjs/router';
import { getEffectiveIsEnabledForUserByFeatureName } from '@store/Global/service';

import { saveQuoteClasses as cls } from './classes';

type Props = {
  isCustomerSaveQuote?: boolean;
};

export const SavedQuotes = (props: Props) => {
  const params = useParams();
  const isCustomerSaveQuote = props.isCustomerSaveQuote ?? false;
  const [activeTab, setActiveTab] = createSignal(0);
  const [quoteItems, setQuoteItems] = createSignal<SavedQuoteInfo[]>([]);
  const [quoteHistoryItems, setQuoteHistoryItems] = createSignal<
    SavedQuoteInfo[]
  >([]);
  const [isUseShortQuoteGroupEnabled, setIsUseShortQuoteGroupEnabled] =
    createSignal<boolean | undefined>();

  onMount(async () => {
    const resp = await getEffectiveIsEnabledForUserByFeatureName('Falvey');
    const getUseShortQuoteGroupEnabled =
      await getEffectiveIsEnabledForUserByFeatureName(
        'UseShortQuoteGroupLocator',
      );
    setLtlQuoteState('isFalveyInsuranceFlow', Boolean(resp));
    setIsUseShortQuoteGroupEnabled(getUseShortQuoteGroupEnabled);
    isCustomerSaveQuote
      ? await fetchSavedQuotesByCustomerId(params.id)
      : await fetchSavedQuotes();

    const activeQuotes: SavedQuoteInfo[] = [];
    const nonActiveQuotes: SavedQuoteInfo[] = [];

    const savedQuote = isCustomerSaveQuote
      ? customerSavedQuotesStore
      : savedQuoteStore;

    savedQuote.items.forEach((item) => {
      if (item.quoteStatus === 'Active') {
        activeQuotes.push(item);
      } else {
        nonActiveQuotes.push(item);
      }
    });

    setQuoteItems(activeQuotes);
    setQuoteHistoryItems(nonActiveQuotes);
  });

  return (
    <Stack
      spacing={1.4}
      p={2}
      bgcolor="#A5C6DA"
      height={isCustomerSaveQuote ? '100%' : '100vh'}
      class={`${isCustomerSaveQuote ? '!overflow-y-auto' : ''}`}
    >
      {(props.isCustomerSaveQuote === undefined ||
        !props.isCustomerSaveQuote) && (
        <Typography variant="h1" class={cls.heading}>
          LTL Saved Quotes
        </Typography>
      )}
      <TabsComponent
        activeTab={activeTab()}
        onTabClick={(tabIndex) => setActiveTab(tabIndex)}
        tabs={['Saved Quotes', 'History'].map((tab) => (
          <Tab>{tab}</Tab>
        ))}
      />
      <Paper elevation={3}>
        <Stack
          p={1.4}
          bgcolor="white"
          class={cls.tabContainer}
          height={'calc(100vh - 130px)'}
        >
          <Switch>
            <Match when={activeTab() === 0}>
              <SavedQuotesGridLegacy
                items={quoteItems}
                setQuoteItems={setQuoteItems}
                isCustomerSaveQuote={isCustomerSaveQuote}
                isUseShortQuoteGroupEnabled={isUseShortQuoteGroupEnabled}
              />
            </Match>
            <Match when={activeTab() === 1}>
              <HistoryQuotesGrid
                items={quoteHistoryItems}
                isCustomerSaveQuote={isCustomerSaveQuote}
                isUseShortQuoteGroupEnabled={isUseShortQuoteGroupEnabled}
              />
            </Match>
          </Switch>
        </Stack>
      </Paper>
    </Stack>
  );
};
