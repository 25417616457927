import { EmptyData } from '@components';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@suid/material';
import { SxProps } from '@suid/system';
import { For, JSXElement, mergeProps, Show } from 'solid-js';

import basicTableClasses from './classes';
import DragDropTable, { CustomTableCell } from './DragAndDropTable';

export type Column<T> = {
  name: string | JSXElement;
  key?: keyof T | string;
  renderCell?: (arg: T, index: number) => string | JSXElement;
  minWidth?: number | string;
  maxWidth?: number | string;
  textAlign?:
    | 'start'
    | 'end'
    | 'left'
    | 'right'
    | 'center'
    | 'justify'
    | 'match-parent';
  cellColor?: string;
  headerClasses?: string;
};

export type FooterType<T> = {
  key: keyof T | string;
  renderCell?: (arg?: T) => string | JSXElement;
  textAlign?:
    | 'start'
    | 'end'
    | 'left'
    | 'right'
    | 'center'
    | 'justify'
    | 'match-parent';
  minWidth?: number;
  maxWidth?: number;
};

export interface Props<T> {
  columns: Column<T>[];
  rows?: T[];
  footerRow?: FooterType<T>[];
  footerBorder?: boolean;
  hideHeader?: boolean;
  sxTable?: SxProps;
  onRowClick?: (rowData: T) => void;
  children?: JSXElement;
  tableBodyClasses?: string;
  tableFooterClasses?: string;
  tableBodyRowClasses?: string;
  tableFooterRowClasses?: string;
  dragDropEnable?: boolean;
  onDragEnd?: (updatedItems: T[]) => void;
  columnNoWrap?: boolean;
  cellClasses?: string;
  noEmptyRender?: boolean;
  tableHeaderClasses?: string;
  tableContainerClasses?: string;
  tableEvenRowClasses?: string;
}

export const BasicTable = <T,>(props: Props<T>) => {
  props = mergeProps(
    {
      tableBodyClasses: 'w-full',
      dragDropEnable: false,
    },
    props,
  );

  const headers = (array: Column<T>[]) => (
    <For each={array}>
      {(item) => (
        <TableCell
          class={`${basicTableClasses.headerCellSx} ${props.cellClasses} ${item.headerClasses}`}
          sx={{
            textWrap: Boolean(props.columnNoWrap) ? 'nowrap' : 'wrap',
            minWidth: item.minWidth ?? 'fit-content',
            maxWidth: item.maxWidth ?? 'fit-content',
          }}
        >
          {item.name}
        </TableCell>
      )}
    </For>
  );

  const footers = (footerArray: FooterType<T>[]) => (
    <For each={footerArray}>
      {(item) => (
        <TableCell
          class={`${basicTableClasses.tableFooterCellStyle} ${props.cellClasses}`}
        >
          {item.renderCell ? item.renderCell(item as T) : ''}
        </TableCell>
      )}
    </For>
  );

  const hasOperationType = (item: T) => {
    return 'operationType' in item;
  };

  return (
    <TableContainer component={Paper} class={props.tableContainerClasses}>
      <Table aria-label="simple table" sx={props.sxTable}>
        {!Boolean(props.hideHeader) && (
          <TableHead class={`head ${props.tableHeaderClasses}`}>
            <TableRow>{headers(props.columns)}</TableRow>
          </TableHead>
        )}
        {Boolean(props.children) ? (
          <TableBody>{props.children}</TableBody>
        ) : (
          <>
            <Show
              when={props.rows?.length === 0 && !Boolean(props.noEmptyRender)}
            >
              <TableBody class={props.tableBodyClasses}>
                <TableRow>
                  <TableCell
                    colspan={props.columns.length}
                    style={{ border: 0 }}
                  >
                    <EmptyData />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Show>
            <Show when={props.rows && props.rows.length > 0}>
              <TableBody class={props.tableBodyClasses}>
                {Boolean(props.dragDropEnable) ? (
                  <DragDropTable<T>
                    rows={props.rows || []}
                    columns={props.columns}
                    onRowClick={props.onRowClick}
                    tableBodyRowClasses={props.tableBodyRowClasses!}
                    onDragEnd={props.onDragEnd!}
                    cellClasses={props.cellClasses}
                  />
                ) : (
                  <For each={props.rows}>
                    {(rowItem, index) => (
                      <TableRow
                        class={`${basicTableClasses.tableRowSx} ${
                          props.tableBodyRowClasses
                        } ${index() % 2 == 0 ? props.tableEvenRowClasses : ''}`}
                        onClick={() =>
                          props.onRowClick && props.onRowClick(rowItem)
                        }
                        sx={{
                          visibility:
                            hasOperationType(rowItem) &&
                            rowItem.operationType === 'Delete'
                              ? 'hidden'
                              : 'visible',
                        }}
                      >
                        <For each={props.columns}>
                          {(columnItem) => (
                            <CustomTableCell
                              columnItem={columnItem}
                              rowItem={rowItem}
                              index={index()}
                              cellClasses={props.cellClasses}
                            />
                          )}
                        </For>
                      </TableRow>
                    )}
                  </For>
                )}
              </TableBody>
            </Show>
          </>
        )}
        {props.footerRow && (
          <TableFooter class={props.tableFooterClasses}>
            <TableRow class={props.tableFooterRowClasses}>
              {footers(props.footerRow)}
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
};
