import { BasicTable } from '@components';
import { CheckboxInput, TextInput } from '@components/forms';
import { useNavigate } from '@solidjs/router';
import { officeDetailStore, User } from '@store/office/officeDetails';
import {
  updateUserRoleAction,
  userActive,
} from '@store/office/officeDetails/actions';
import { Box, Stack, TableCell, TableRow } from '@suid/material';
import { routeCarrierDetails } from '@utils/routeCarrierDetails';
import { createSignal, For } from 'solid-js';

export const UserDetails = () => {
  const [filters, setFilters] = createSignal<{ [key: string]: boolean }>({
    Manager: false,
    Supervisor: false,
    Active: true,
  });
  const [searchTerm, setSearchTerm] = createSignal('');
  const navigate = useNavigate();

  const handleSelectedFilter = (role: string) => (checked: boolean) => {
    setFilters((prevFilters) => ({ ...prevFilters, [role]: checked }));
  };

  const handleUpdateUserRole = async (
    checked: boolean,
    userId: number,
    role: string,
  ) => {
    if (role === 'Active') {
      await userActive(userId, checked);
    } else {
      await updateUserRoleAction(checked, userId, role);
    }
  };

  const filteredUsers = () => {
    const selectedRoles = Object.entries(filters())
      .filter(([_, isSelected]) => isSelected)
      .map(([role]) => role);

    const users =
      officeDetailStore.officeDetail?.users.filter(
        (user) =>
          (searchTerm().length === 0 ||
            user.name.toLowerCase().includes(searchTerm().toLowerCase())) &&
          (selectedRoles.length === 0 ||
            selectedRoles.every(
              (role) =>
                (role === 'Active' && user.active) ||
                (role !== 'Active' && user.roles.includes(role)),
            )),
      ) || [];
    return users.sort((a, b) => a.name.localeCompare(b.name));
  };

  const UserColumns = [
    {
      name: 'Name',
      key: 'name',
    },
    {
      name: (
        <CheckboxInput
          label="Manager"
          checked={filters().Manager}
          onChange={handleSelectedFilter('Manager')}
        />
      ),
      key: 'manager',
    },
    {
      name: (
        <CheckboxInput
          label="Supervisor"
          checked={filters().Supervisor}
          onChange={handleSelectedFilter('Supervisor')}
        />
      ),
      key: 'supervisor',
    },
    {
      name: (
        <CheckboxInput
          label="Active"
          checked={filters().Active}
          onChange={handleSelectedFilter('Active')}
        />
      ),
      key: 'active',
    },
  ];

  const handleRedirectToUserDetail = (id: number) => {
    const isNative = routeCarrierDetails(id);
    if (isNative) {
      navigate(`/user/details/${id}`);
    }
  };
  return (
    <div>
      <TextInput
        label="Find a User"
        variant="outlined"
        onInput={(e: Event) => {
          const target = e.target as HTMLInputElement | null;
          if (target) {
            setSearchTerm(target.value);
          }
        }}
        sxProps={{ marginBottom: '16px' }}
      />
      <BasicTable columns={UserColumns}>
        <For each={filteredUsers()}>
          {(user: User) => (
            <TableRow
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                backgroundColor: user.active ? '' : '#f2dede',
              }}
            >
              <TableCell>
                <Box
                  onClick={() => handleRedirectToUserDetail(user.id)}
                  class="cursor-pointer"
                >
                  <Stack spacing={1}>
                    <strong>{user.name}</strong>
                    <div>{user.email}</div>
                  </Stack>
                </Box>
              </TableCell>
              <TableCell align="left">
                <CheckboxInput
                  label=""
                  checked={user.roles.includes('Manager')}
                  onChange={(e) => handleUpdateUserRole(e, user.id, 'Manager')}
                />
              </TableCell>
              <TableCell align="left">
                <CheckboxInput
                  label=""
                  checked={user.roles.includes('Supervisor')}
                  onChange={(e) =>
                    handleUpdateUserRole(e, user.id, 'Supervisor')
                  }
                />
              </TableCell>
              <TableCell align="left">
                <CheckboxInput
                  label=""
                  checked={user.active}
                  onChange={(e) => handleUpdateUserRole(e, user.id, 'Active')}
                />
              </TableCell>
            </TableRow>
          )}
        </For>
      </BasicTable>
    </div>
  );
};
