import { produce } from 'solid-js/store';
import { handleToast, isAdmin, isSuperAdmin } from '@utils/utils';
import { ToastType } from '@components';

import {
  addCollectionCommentCall,
  createEdiRequestCall,
  creditIncreaseCall,
  deleteCollectionCommentsCall,
  fetchCustomerData,
  fetchCustomerOfficeGroupsCall,
  fetchCustomerSavedQuotes,
  fetchEDIConfigIsTenderAutoResponseAuto990Decline,
  fetchEdiConfiguration,
  fetchUploadedAutoAcceptDeclineSheet,
  sendEdiConfigurationData,
  updateCustomerDetailsCall,
} from './services';
import {
  customerDetailsStore,
  setCreditRequestStore,
  setCustomerDetailsStore,
  setCustomerSavedQuotesStore,
  setEdiConfigurationStore,
} from './store';
import {
  CustomerSavedQuotesState,
  ICustomerDetails,
  ediConfigurationType,
  Comment,
  ICreditRequest,
  CreditRequestState,
  CreateEditRequestPayload,
} from './types';

export const getCustomerData = async (customerId: string) => {
  setCustomerDetailsStore('isLoading', true);
  try {
    const resp = await fetchCustomerData(customerId);
    if (resp) {
      setCustomerDetailsStore(
        produce((draft) => {
          draft.customer = resp;
          draft.isError = false;
          draft.errorMessage = '';
        }),
      );
      await fetchCustomerOfficeGroups(
        customerDetailsStore.customer.accountManagerOfficeId,
      );
    }
  } catch (error) {
    setCustomerDetailsStore(
      produce((draft) => {
        draft.isError = true;
      }),
    );
    if (error instanceof Error) {
      setCustomerDetailsStore('errorMessage', error.message);
      throw new Error(`Failed to customer Data: ${error.message}`);
    }
  } finally {
    setCustomerDetailsStore('isLoading', false);
  }
};

export const updateCustomerDetailsState = (
  updates: Partial<ICustomerDetails>,
) => {
  Object.entries(updates).forEach(([key, value]) => {
    setCustomerDetailsStore('customer', key as keyof ICustomerDetails, value);
  });
};

export const getEDIConfiguerationsForCustomer = async (customerId: string) => {
  setEdiConfigurationStore(
    produce((draft) => {
      draft.isLoading = true;
    }),
  );
  const response = await fetchEdiConfiguration(customerId);
  if (response && response.isSuccess)
    setEdiConfigurationStore(
      produce((draft) => {
        draft.ediData = response.value;
        draft.isError = false;
        draft.errorMessage = '';
        draft.isLoading = false;
      }),
    );
  else {
    setEdiConfigurationStore(
      produce((draft) => {
        draft.isLoading = false;
        draft.isError = true;
        draft.ediData = {} as ediConfigurationType;
      }),
    );
  }
};

export const updateEdiState = (updates: Partial<ediConfigurationType>) => {
  Object.entries(updates).forEach(([key, value]) => {
    setEdiConfigurationStore(
      'ediData',
      key as keyof ediConfigurationType,
      value,
    );
  });
};

export const saveEdiConfig = async (data: unknown) => {
  setEdiConfigurationStore('isSaveLoading', true);
  const response = await sendEdiConfigurationData(data, () => {
    setEdiConfigurationStore('isSaveLoading', false);
  });
  if (response && response.isSuccess) {
    setEdiConfigurationStore('ediData', response.value);
    handleToast(ToastType.Success, 'Edi Configuration Saved Successfully');
  }
};

export const fetchSavedQuotesByCustomerId = async (customerId: string) => {
  try {
    setCustomerSavedQuotesStore('isLoading', true);
    const resp = await fetchCustomerSavedQuotes(customerId);
    setCustomerSavedQuotesStore(
      produce((draft: CustomerSavedQuotesState) => {
        draft.items = resp;
        draft.isLoading = false;
      }),
    );
  } catch (e) {
    setCustomerSavedQuotesStore(
      produce((draft: CustomerSavedQuotesState) => {
        draft.isError = true;
        draft.errorMessage = 'Failed loading saved quotes';
        draft.isLoading = false;
      }),
    );
  } finally {
    setCustomerSavedQuotesStore('isLoading', false);
  }
};
export const deleteCollectionComments = async (
  commentId: number,
  cb?: () => void,
) => {
  const resp = await deleteCollectionCommentsCall(commentId);
  const collectionComments = [
    ...(customerDetailsStore.customer.collectionComments || []),
  ].filter((c) => c.id !== commentId);
  if (Boolean(resp))
    setCustomerDetailsStore(
      'customer',
      'collectionComments',
      collectionComments,
    );
  cb && cb();
};

export const addCollectionComment = async (
  commentId: number,
  comment: string,
  cb?: () => void,
) => {
  const resp = await addCollectionCommentCall(commentId, comment);
  if (Boolean(resp)) {
    const collectionComments = [
      ...customerDetailsStore.customer.collectionComments,
    ];
    collectionComments.unshift(resp as Comment);
    setCustomerDetailsStore(
      'customer',
      'collectionComments',
      collectionComments,
    );
  }
  cb && cb();
};

export const creditIncrease = async (
  payload: ICreditRequest,
  cb?: () => void,
) => {
  setCreditRequestStore('isLoading', true);
  const res = await creditIncreaseCall(payload);
  if (res && res.isSuccess) {
    cb && cb();
    setCreditRequestStore(
      produce((draft: CreditRequestState) => {
        draft.isError = false;
        draft.errorMessage = '';
        draft.isLoading = false;
      }),
    );
  } else {
    setCreditRequestStore(
      produce((draft: CreditRequestState) => {
        draft.isError = true;
        draft.errorMessage = 'Failed to request credit';
        draft.isLoading = false;
        draft.creditRequest = {} as ICreditRequest;
      }),
    );
  }
};

export const updateCustomerDetails = async (payload: ICustomerDetails) => {
  setCustomerDetailsStore('isLoading', true);
  const customerResp = await updateCustomerDetailsCall(payload);
  if (Boolean(customerResp)) {
    setCustomerDetailsStore(
      produce((draft) => {
        draft.customer = customerResp as ICustomerDetails;
        draft.isError = false;
        draft.errorMessage = '';
      }),
    );
  } else {
    setCustomerDetailsStore(
      produce((draft) => {
        draft.isError = true;
        draft.isLoading = false;
      }),
    );
  }
  setCustomerDetailsStore('isLoading', false);
};

export const createEdiRequest = async (payload: CreateEditRequestPayload) => {
  try {
    return await createEdiRequestCall(payload);
  } catch (error) {
    handleToast(ToastType.Error, 'Failed to create edi request');
    throw new Error('Failed to create edi request');
  }
};

export const fetchCustomerOfficeGroups = async (
  officeId: number,
  accountId?: number,
) => {
  const res = await fetchCustomerOfficeGroupsCall(officeId);

  if (res && res.isSuccess) {
    if (
      officeId ==
        (accountId ?? customerDetailsStore.customer.accountManagerOfficeId) ||
      officeId == customerDetailsStore.customer.salesManagerOfficeId
    ) {
      res.value = res.value.filter(
        (x) => x.name != 'Managers' && x.name != 'Admin',
      );
    }
    if (!isAdmin() && !isSuperAdmin()) {
      res.value = res.value.filter((x) => x.name !== 'The Office');
    }
    const assignOfficeGroupRes = res.value.map((item) => {
      return {
        ...item,
        label: `${item.name as string} (${item.office})`,
        value: item.id,
      };
    });
    setCustomerDetailsStore('assignOfficeGroup', assignOfficeGroupRes);
  }
};

export const getEDIConfigIsTenderAutoResponseAuto990Decline = async (
  customerId: string,
) => {
  try {
    const res =
      await fetchEDIConfigIsTenderAutoResponseAuto990Decline(customerId);
    if (res && res.isSuccess) {
      return res;
    }
  } catch (error) {
    return undefined;
  }
};

export const getUploadedAutoAcceptDeclineSheet = async (customerId: string) => {
  try {
    const res = await fetchUploadedAutoAcceptDeclineSheet(customerId);
    if (res) {
      return res.value;
    }
  } catch (error) {
    return undefined;
  }
};
