export const LTlFormStyles = {
  hazmatCheckBox: {
    marginRight: '0',
  },
  hazmatPopOver: {
    backgroundColor: 'white',
    borderRadius: '0',
    textDecoration: 'underline !important',
  },
  tableFooterRow: { borderTop: '1px solid rgba(224.4, 224.4, 224.4, 1)' },
  totalWeightCell: { textAlign: 'center', border: 0, maxWidth: '100px' },
  totalShipmentPCF: { border: 0, maxWidth: '70px' },
};
