import { apiClient, v3Client } from '@api/apiClient';
import { handleToast } from '@utils/utils';
import { ToastType } from '@components';
import { createError } from '@utils/errorUtils';
import { V4ApiResponse } from '@store/customers/customerDetails';

import { ClaimCommentType, ClaimDataType } from './types';

export const getClaimDataApi = async (id: number) => {
  try {
    const data: V4ApiResponse<ClaimDataType> = await apiClient.get(
      `/Claim/${id}`,
    );
    if ('value' in data) {
      return data.value;
    }
  } catch (error) {
    const e = createError(error, 'Unable to fetch Claim data');
    handleToast(ToastType.Error, e.message);
  }
};

export const saveClaimApi = async (payload: ClaimDataType) => {
  try {
    const response: V4ApiResponse<ClaimDataType> = await apiClient.post(
      '/Claim',
      payload,
    );
    if (response.isSuccess) {
      handleToast(ToastType.Success, 'Claim was saved');
    }
  } catch (e: unknown) {
    const error = createError(e, 'Unable to save claim');
    handleToast(ToastType.Error, error.message);
  }
};

export const addClaimCommentApi = async (payload: ClaimCommentType) => {
  try {
    const url = `/Comment/Order/${payload.id}`;
    const response = await v3Client.post(url, payload);
    const v4Response = {
      errors: [{ code: '', message: '' }],
      isFailure: false,
      isSuccess: true,
      value: response.data,
    };
    return v4Response;
  } catch (e: unknown) {
    const error = createError(e, 'Adding carrier comments failed');
    handleToast(ToastType.Error, error.message);
  }
};

export const removeClaimCommentApi = async (id: number) => {
  try {
    const url = `/Comment/Order/${id}`;
    const response: unknown = await v3Client.delete(url);
    const status = (response as { success: boolean }).success;
    return status;
  } catch (e: unknown) {
    const error = createError(e, 'Removing carrier comments failed');
    handleToast(ToastType.Error, error.message);
  }
};
