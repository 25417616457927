import { apiClient } from '@api/apiClient';
import { V4ApiResponse } from '@store/customers/customerDetails';
import { createError } from '@utils/errorUtils';
import { handleToast } from '@utils/utils';
import { ToastType } from '@components';

import { ClaimsInfo } from './types';

export async function getClaims() {
  try {
    const response: V4ApiResponse<ClaimsInfo[]> = await apiClient.get('/claim');
    if ('value' in response) {
      return response.value;
    }
  } catch (e: unknown) {
    const error = createError(e, 'Fetching claims failed');
    handleToast(ToastType.Error, error.message);
  }
}
