import { ColDef, GridApi } from '@ag-grid-community/core';
import { Grid as MuiGrid, Stack } from '@suid/material';
import AgGridSolid from '@ag-grid-community/solid';
import { agGridCellStyle } from '@components/Grid/AgGridCellStyle';
import { getPendingCarrierDataApi } from '@store/Global/service';
import { CarrierType } from '@store/Global/types';
import { createSignal } from 'solid-js';
import { useNavigate } from '@solidjs/router';
import { dateComparator } from '@store/loadboard/utils';
import { atgLogo } from '@views/ltl';
import { routeCarrierDetails } from '@utils/routeCarrierDetails';

export const Carriers = () => {
  const navigate = useNavigate();
  const [rowData, setRowdata] = createSignal<CarrierType[]>([]);

  const onGridReady = async (gridApi: GridApi) => {
    gridApi.showLoadingOverlay();
    const data = await getPendingCarrierDataApi();
    setRowdata(data ?? []);
    gridApi.hideOverlay();
  };

  const columnDefs: ColDef[] = [
    {
      field: 'name',
      headerName: 'Carrier',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'MC/DOT',
      filter: 'agTextColumnFilter',
      valueGetter: (params) => {
        const data = params.data as CarrierType;
        const mc = data.mC;
        const dot = data.dOT;
        return `${mc}/${dot}`;
      },
    },
    {
      headerName: 'Date',
      field: 'created',
      sort: 'asc',
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: dateComparator,
      },
      valueFormatter: (params) => {
        const date = new Date(params.value as string);
        return date.toLocaleDateString();
      },
    },
  ];

  return (
    <Stack spacing={2} class="h-[60vh]">
      <MuiGrid class="ag-theme-alpine !flex-1">
        <AgGridSolid
          overlayLoadingTemplate={atgLogo}
          onGridReady={(gridApi) => onGridReady(gridApi.api)}
          rowData={rowData()}
          columnDefs={columnDefs}
          gridOptions={{
            pagination: true,
            suppressPaginationPanel: false,
            defaultColDef: {
              flex: 1,
              autoHeight: true,
              cellStyle: agGridCellStyle,
              filter: true,
            },
          }}
          onRowClicked={(e) => {
            const id = (e.data as CarrierType).id;
            const isNative = routeCarrierDetails(id);
            if (isNative) {
              navigate(`/Carrier/Details/${id}`);
            }
          }}
        />
      </MuiGrid>
    </Stack>
  );
};
