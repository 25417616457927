import { lazy } from 'solid-js';

const Claim = lazy(() => import('./Claim'));

const ClaimRoute = {
  path: '/claim/:id?',
  component: Claim,
};

export { ClaimRoute };
