import { Tab, TabsComponent } from '@components';
import { Box } from '@suid/material';
import { Component, createSignal, Match, Switch } from 'solid-js';
import { FactoringCompanyEditHistory } from '@views/factoringCompany/factoringCompanyEditHistory';
import { factoringCompanyDetailsStore } from '@store/factoringCompany/factoringCompanyDetails';

import { FactoringCompanyDetailsTab } from './FactoringCompanyDetailsTab';
import { FactoringCompanyDetailsCarriersTab } from './FactoringCompanyDetailsCarriersTab';
import { FactoringCompanyDetailsLoadTab } from './FactoringCompanyDetailsLoadTab';

export const FactoringCompanyDetailsTabPanel: Component = () => {
  const [activeTab, setActiveTab] = createSignal(0);

  const tabs = ['DETAILS', 'CARRIERS', 'LOADS', 'EDIT HISTORY'];
  const createTabs = ['DETAILS'];

  const tabsToRender = () => {
    return factoringCompanyDetailsStore.creationMode ? createTabs : tabs;
  };

  return (
    <>
      <TabsComponent
        tabs={tabsToRender().map((item) => (
          <Tab>{item}</Tab>
        ))}
        activeTab={activeTab()}
        onTabClick={setActiveTab}
        border={true}
        tabStyleClasses="rounded-t-lg"
        tabContainerStyles="ml-4 items-center"
        activeStyling="bg-[#ffe8b7]"
      />
      <Box p={2}>
        <Switch>
          <Match when={activeTab() === 0}>
            <FactoringCompanyDetailsTab />
          </Match>
          <Match when={activeTab() === 1}>
            <FactoringCompanyDetailsCarriersTab />
          </Match>
          <Match when={activeTab() === 2}>
            <FactoringCompanyDetailsLoadTab />
          </Match>
          <Match when={activeTab() === 3}>
            <FactoringCompanyEditHistory />
          </Match>
        </Switch>
      </Box>
    </>
  );
};
