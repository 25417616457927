import { ReassignTypeEnum } from '@common/types';
import { ToastType } from '@components';
import { softDeleteFlagTypes } from '@components/FlagPopup/constants';
import { SpecialFlagTypeEnum } from '@components/FlagPopup/types';
import { closeModal } from '@store/modals';
import {
  addFlag,
  Carrier,
  CarrierState,
  carrierStore,
  deleteFlag,
  fetchUserInfoUserModel,
  FlagType,
  FlagViewModel,
  ILoadEditViewModel,
  LTLReferenceNumberCollection,
  orderStore,
  putFalloutCount,
  setCarrierStore,
  setOrderStore,
  updateFlag,
  updateLoadPropertyAtIndex,
  updateOrderState,
  UserInfoModel,
} from '@store/orders';
import { userStore } from '@store/user';
import { handleToast, printError } from '@utils/utils';
import { isEmpty } from 'lodash';
import { produce } from 'solid-js/store';
import { LTLReferenceNumberTypes } from '@store/ltl/types';

import PointyCheck from '../../../../../../assets/PointyCheck.svg';
import PointyX from '../../../../../../assets/PointyX.svg';
import UserPointyCheck from '../../../../../../assets/UserPointyCheck.svg';
import { loadInfoStore, setLoadInfoStore } from './store';

export const handleReassignedUser = async (id: number, type: string) => {
  try {
    setLoadInfoStore('isLoading', true);
    const response: UserInfoModel = await fetchUserInfoUserModel(id);

    if (type === ReassignTypeEnum.CoveredBy) {
      setLoadInfoStore('coveredBy', response);
      updateLoadPropertyAtIndex({
        coveredBy: response.name,
        coveredById: response.id,
      });
    } else if (type === ReassignTypeEnum.AssignedTo) {
      setLoadInfoStore('assignedTo', response);
      updateLoadPropertyAtIndex({
        assignedToUser: response.name,
        assignedToUserId: response.id,
      });
    }
  } catch (err) {
    handleToast(ToastType.Error, 'Failed to reassign user');
  } finally {
    setLoadInfoStore('isLoading', false);
  }
};

export const incrementFallout = async (carrierId: number, tabIndex: number) => {
  await putFalloutCount(carrierId);
  setCarrierStore(
    produce((s: CarrierState) => {
      s.carrier[tabIndex] = {} as Carrier;
    }),
  );
  updateLoadPropertyAtIndex({
    driver: undefined,
    driverPhoneNumber: undefined,
    truckNumber: undefined,
    trailerNumber: undefined,
    carrierId: undefined,
    carrierName: undefined,
    carrierContactId: undefined,
    status: 'Unassigned',
  });
};

export const getCarrierContact = (
  load: ILoadEditViewModel,
  tabIndex: number,
) => {
  if (!isEmpty(carrierStore.carrier[tabIndex])) {
    return carrierStore.carrier[tabIndex]?.contacts?.find(
      (v) => v.id === load.carrierContactId,
    );
  }
};

export const getContactPhoneVerificationIcon = (
  load: ILoadEditViewModel,
  tabIndex: number,
) => {
  const carrierContact = getCarrierContact(load, tabIndex);

  if (
    carrierContact === undefined ||
    (carrierContact.phone1 || '').trim() === ''
  )
    return '';

  if (carrierContact.ownershipSource === 1) {
    return PointyCheck;
  } else if (carrierContact.phoneVerified === true) {
    return UserPointyCheck;
  }

  return PointyX;
};

export const getContactEmailVerificationIcon = (
  load: ILoadEditViewModel,
  tabIndex: number,
) => {
  const carrierContact = getCarrierContact(load, tabIndex);

  if (
    carrierContact === undefined ||
    carrierContact.email == undefined ||
    carrierContact.email.trim() === ''
  )
    return '';

  if (carrierContact.ownershipSource === 1) {
    return PointyCheck;
  } else if (carrierContact.emailVerified === true) {
    return UserPointyCheck;
  }

  return PointyX;
};

export const canOverrideCarrierHistory = () => {
  if (
    userStore.user.officeSettings &&
    userStore.user.officeSettings.length > 0
  ) {
    const c = userStore.user.officeSettings.find((obj) => {
      return obj.key == 'AllowCarrierHistoryOverride';
    });
    if (c && c.value) return c.value === 'true';
  }
  return false;
};

export const getTotalWeightAndUnit = (load: ILoadEditViewModel) => {
  if (load.items && load.items.length > 0) {
    const weightUnit = load.items[0].uom || 'lbs';
    const totalWeight = load.items.reduce((total, item) => {
      return item.operationType !== 'Delete'
        ? Number(total) + Number(item.weight)
        : Number(total);
    }, 0);

    return `${totalWeight.toLocaleString()} ${
      weightUnit === 'kg' ? 'kgs' : weightUnit
    }.`;
  }
  return `${0} lbs.`;
};

export function updateReferenceNumber(
  type: LTLReferenceNumberTypes,
  value: string,
  referenceNumbers: LTLReferenceNumberCollection | undefined,
): LTLReferenceNumberCollection {
  const updatedReferenceNumbers: LTLReferenceNumberCollection = {
    operationType: 'Update',
    referenceNumbers: referenceNumbers
      ? [...referenceNumbers.referenceNumbers]
      : [],
  };

  updatedReferenceNumbers.referenceNumbers =
    updatedReferenceNumbers.referenceNumbers.some(
      (referenceNumber) => referenceNumber.type === type,
    )
      ? updatedReferenceNumbers.referenceNumbers.map((refNumber) =>
          refNumber.type === type ? { ...refNumber, type, value } : refNumber,
        )
      : [...updatedReferenceNumbers.referenceNumbers];

  return updatedReferenceNumbers;
}

export const onFlagChange = async (
  flag: FlagViewModel,
  action: 'update' | 'create' | 'delete',
  load: ILoadEditViewModel,
  tabIndex: number,
  editFlagModalId: string,
) => {
  if (flag.description != null && flag.description.trim() === '') {
    flag.description = undefined;
  }
  setLoadInfoStore('isLoading', true);
  closeModal(editFlagModalId);

  const loads = [...orderStore.order.loads];
  // we just need these printlogs to make stuff happy
  const flagsForLoad = [...(loads[tabIndex].flags ?? [])];
  const currentIndex = loadInfoStore.flagIndex;

  try {
    switch (action) {
      case 'delete': {
        if (currentIndex === null) {
          return;
        }

        const response = await deleteFlag(flag.id as number);

        if (response) {
          loads[tabIndex] = { ...loads[tabIndex] };

          if (
            softDeleteFlagTypes[
              load.flags?.[currentIndex]
                .flagType as unknown as SpecialFlagTypeEnum
            ]
          ) {
            flagsForLoad[currentIndex] = {
              ...flagsForLoad[currentIndex],
              cleared: true,
            };
          } else {
            flagsForLoad.splice(currentIndex, 1);
          }

          loads[tabIndex].flags = flagsForLoad;

          updateOrderState({
            ...orderStore.order,
            loads: loads,
          });
          handleToast(ToastType.Success, 'The Flag was cleared.');
        }

        setLoadInfoStore('flagIndex', null);
        setLoadInfoStore('flag', undefined);
        break;
      }
      case 'update': {
        if (currentIndex === null) {
          return;
        }
        const response = await updateFlag(flag);
        loads[tabIndex] = { ...loads[tabIndex] };

        if (loads[tabIndex].flags !== undefined) {
          flagsForLoad[currentIndex] = response;
          loads[tabIndex].flags = flagsForLoad;
        }

        setOrderStore(
          'order',
          'loads',
          orderStore.activeTab.index,
          'flags',
          flagsForLoad,
        );

        setLoadInfoStore('flagIndex', null);
        closeModal(editFlagModalId);
        setLoadInfoStore('flag', undefined);

        break;
      }
      case 'create': {
        flag.orderId = orderStore.order.id;
        flag.loadId = load.id;
        flag.setBy = userStore.user.name;

        const response = await addFlag(flag);
        flagsForLoad.push(response);
        loads[tabIndex] = {
          ...loads[tabIndex],
          flags: flagsForLoad,
        };

        setOrderStore(
          'order',
          'loads',
          orderStore.activeTab.index,
          'flags',
          flagsForLoad,
        );

        if (response.flagType === FlagType.NeedsApproval) {
          updateOrderState({
            needsApprovalFlag: { ...response },
          });
        }

        closeModal(editFlagModalId);
        setLoadInfoStore('flag', undefined);

        break;
      }
    }
  } catch (error) {
    printError(error);
    handleToast(ToastType.Error, `Failed to ${action} flag`);
  }

  setLoadInfoStore('isLoading', false);
};
