import { Coordinate } from '@components/Map/Map';
import { DocumentModel } from '@store/documents';
import { LTLRateQuoteRequest, LTLRateQuoteResponse } from '@store/ltl';
import { LTLReferenceNumber } from '@store/ltl/types';

import { Customer } from './v3Types';

export type loadStatus = {
  id: number;
  status: string;
};

export type Comments = {
  operationType: OperationTypeV4;
  id: number;
  objectId: number;
  userId: number;
  name?: string | undefined;
  type?: string | undefined;
  comment?: string | undefined;
  timestamp?: string | undefined;
  thumbnail?: string | undefined;
  createdBy: number;
};

export type ILatLon = {
  Coords: {
    Lat: string;
    Lon: string;
  };
  Errors: {
    Code: number;
    Description: string;
    LegacyErrorCode: number;
    Type: number;
  }[];
};

export type LoadModeTypes =
  | 'TL'
  | 'LTL'
  | 'Partial'
  | 'Intermodal'
  | 'Air'
  | 'Ocean';

export type UserData = {
  id: number;
  name: string;
  createdDateUTC?: Date | undefined;
};

export type UserDataViewLocksComposite = {
  lockedBy: UserData | undefined | null;
  viewedBy: UserData[];
};

export type OrderState = {
  // activeCarrierTab: number;
  order: IOrderViewModel;
  orderIdUrl?: string;
  quoteIdUrl?: string;
  quoteId?: string;
  loading: boolean;
  isError: boolean;
  activeTab: TabState;
  topStops: LastUsedTopStopModel[];
  lastStops: LastUsedTopStopModel[];
  mode: string;
  mapCords: Coordinate[];
  orderFormError: Record<string, string[] | undefined> | null;
  orderFormErrorPerSection: {
    customerInformation: number;
    loads: number;
    instructionsAndCosts: number;
  };
  availableServices: availableServices[];
  managePostLoad: IManagePostLoad;
  isSaving: boolean;
  isReadOnly: boolean;
  nearbyCarriers: NearbyCarriersReturn[];
  isCreate: boolean;
  firstRender: boolean; // TODO - Revist This As it is Added just to Check the First Render So ShipmentsModal can be opened Once
  isStopsEdited: boolean;
  mappedErrorSection: Record<string, string> | null;
  orderTemplates: OrderTemplate[];
  carrierContactLockdownEnabled: boolean | undefined;
  isTemplateMode: boolean;
  matched: boolean;
  vendorFalveyInsuranceError: string;
  copiedOrderIdUrl?: string;
  copiedOrderId?: string;
  orderLockEnabled: boolean | undefined;
  forceUnlockOrderEnabled: boolean | undefined;
  isSmallScreen: boolean;
  viewedBy: UserData[];
  lockedBy?: UserData | null;
};

export type TabState = {
  index: number;
  type: TabType;
};

export enum TabType {
  load = 'load',
  vendor = 'vendor',
}

export type CustomerState = {
  customer: CustomerDetails;
  loading: boolean;
};

export type FeatureFlagCustomerState = {
  featureFlagCustomer: FeatureFlagCustomer;
  loading: boolean;
  isError: boolean;
};

export type FeatureFlagCustomer = {
  suggestedClass: boolean;
  CustomerCommentVisibility: boolean;
  EDIDropoffDriverInAuto214: boolean;
  EDIDropoffDriverOutAuto214: boolean;
  EDIDropoffAppointmentAuto214: boolean;
  EDIPickupDriverInAuto214: boolean;
  EDIPickupDriverOutAuto214: boolean;
  EDIPickupAppointmentAuto214: boolean;
};

export type PostLoadState = {
  postLoad: IPostLoad;
  isError: boolean;
  loading: boolean;
};
export type OrderDocumentState = {
  loading: boolean;
  documents: DocumentModel[];
};

export enum OperationType {
  Insert = 0,
  Update = 1,
  Delete = 2,
  None = 3,
}

export type OperationTypeV4 = 'Insert' | 'Update' | 'Delete' | 'None';
export interface MetadataViewModel {
  operationType: OperationTypeV4;
  id: number;
  orderId: number;
  propertyName?: string | undefined;
  value?: string | undefined;
  active: boolean;
}
export interface IOrderViewModel {
  lineItems: OrderLineItem[];
  payments: PaymentViewModel[];
  customerContact?: ICustomerContact;
  operationType: OperationTypeV4;
  id: number;
  customerId: number | null;
  // customerContact?: ContactViewModel | undefined;
  usersViewing?: string[] | undefined;
  chargeCreditCardFee: boolean;
  customerName?: string | undefined;
  customerCredit: number;
  customerContactId?: number | undefined | null;
  availableCredit?: number | undefined;
  referenceNumber?: string | undefined;
  paymentTerms?: string | undefined;
  status?: string | undefined;
  assignedId: number;
  owner?: string | undefined;
  billingHold: boolean;
  agingOut: boolean;
  // lineItems?: LineItemViewModel[] | undefined;
  // payments?: PaymentViewModel[] | undefined;
  adjustments: IAdjustment[];
  loads: ILoadEditViewModel[];
  availableServices: availableServices[];
  // vendors?: VendorContractViewModel[] | undefined;
  comments?: Comments[];
  metadata?: MetadataViewModel[];
  customerPrePayments?: CustomerPrePaymentViewModel[] | undefined;
  // collectionActions?:
  //   | { [key: string]: CollectionActionViewModel[] }
  //   | undefined;
  // customerPrePayments?: CustomerPrePaymentViewModel[] | undefined;
  collectionActions?: CollectionActions;
  // warnings?: string[] | undefined;
  // flags?: FlagViewModel[] | undefined;
  customer?: Customer | undefined;
  invoicedDate?: string | undefined;
  paidDate?: string | undefined;
  editable: boolean;
  role?: string | undefined;
  synched: boolean;
  private: boolean;
  partnerships?: { [key: string]: string } | undefined;
  edi: boolean;
  trackLoads: boolean;
  requiredTrackingService?: number | undefined;
  trackingVisibilityProvider?: number | undefined;
  trackingServiceName?: string | undefined;
  accountingSysId?: string | undefined;
  loadTenderId?: number | undefined | null;
  bidType?: string | null;
  spotId?: string | undefined;
  spotQuote?: number | undefined;
  show214: boolean;
  currencyType?: string | undefined;
  daysPastDue: number;
  canVoidOrder: boolean;
  holdRetraction?: boolean | undefined;
  revisitRetractionDate?: string | undefined;
  exceptionReason?: string | undefined;
  needsApprovalFlag?: FlagViewModel | undefined;
  modifiedDate?: Date | undefined;
  modifiedBy?: number | undefined;
  createdDate: Date | string;
  createdBy: number;
  isDeleted: boolean;
  loadInformation: ILoadInformation | undefined;
  vendors?: VendorContractViewModel[];
  flags?: FlagViewModel[];
  hideCustomerName: boolean;
}

export interface ILoadInformation {
  id: number;
  name: string;
  coveredBy: string;
  assignedTo: string;
  contacts: ICustomerContact[];
  dot: string;
  mc: string;
  address: IAddress;
  rating: number;
  numRatings: number;
  status: string;
  carrierActionPlan: boolean;
  officeRating: number;
  isActive: boolean;
  hasExpiredCargoInsurance: boolean;
  hasExpiredLiabilityInsurance: boolean;
  cargoExpiration: string;
  liabilityExpiration: string;
  advancesAllowed: boolean;
  mode: string;
  blacklisted: boolean;
  firstLoad: boolean;
  corporatePhoneNumber: string | null;
  factors: boolean;
  fallouts: number;
  totalLoads: number;
  ltlEnabled: boolean;
  transfloEnabled: boolean;
  brokerAuthority: boolean;
  currencyOptionEnabled: boolean;
}

export interface ILocationSearch {
  display: string;
  zipcode: string;
  zip: string;
  city: string;
  state: string;
  country: string;
  long: string | null;
  lat: string | null;
}

export interface ICustomerContact {
  id: number;
  objectid: number;
  objecttype?: string;
  name: string;
  phone1: string;
  extension1?: string | null;
  phone2?: string | null;
  extension2?: string | null;
  fax?: string;
  email?: string | null;
  type?: string;
  status?: string;
  createdDate: Date | string | null;
  modifiedDate: Date | string | null;
  mailerEmails: boolean;
  password?: string;
  username?: string;
  phone?: string | null;
  selected?: boolean;
}

export interface IAddress {
  line1: string;
  line2: string | null;
  city: string;
  state: string;
  zip: string;
  name: string | null;
  phone: string | null;
  phoneAreaCode: string | null;
  phoneSevenDigit: string | null;
  code: string | null;
  country: string | null;
  contact: string | null;
  contactLastName: string | null;
  contactFirstName: string | null;
}

export interface CustomerDetails {
  id: number;
  name: string;
  capacityGroupId: number | null;
  capacityGroupLeader: number | null;
  creditLimit: number;
  available: number;
  status: string;
  paymentTerms: string;
  thirdParty: boolean;
  edi: boolean;
  accountManager: string;
  accountManagerId: number;
  salesManager: string;
  salesManagerId: number;
  freightContacts: ICustomerContact[];
  billingContacts: ICustomerContact[];
  specialInstructions: string;
  trackLoads: boolean;
  requiredTrackingService: number;
  trackingVisibilityProvider: unknown; // You may replace 'any' with a specific type if applicable
  chargeCreditCardFee: boolean;
  pcMilerVersion: string; // You may replace 'any' with a specific type if applicable
  metadata: string[]; // You may replace 'any' with a specific type if applicable
  invoiceMethod: string;
  assignAccountManagerAsOrderRep?: boolean;
}

export interface PaymentViewModel {
  operationType: OperationTypeV4;
  id: number;
  type: string;
  description: string;
  paidDate: string;
  amount: number;
  referenceNum: string | number;
  status: string;
  checkNumber: number | null;
}

export interface IAdjustment {
  operationType: OperationTypeV4;
  id: number;
  orderId: number;
  amount: number;
  description: string;
  settledDate: Date | null | string;
  createdDate?: string;
  retraction: number | null;
  order: unknown | null;
}
export interface OrderLineItem {
  operationType: OperationTypeV4;
  id: number;
  type: string;
  description: string;
  settledDate: string | null;
  createdDate: Date | null;
  status: string;
  quantity: number;
  rate: number | null | string;
  estimate: boolean;
  readOnly: boolean;
  synched: boolean;
  adjustment: boolean;
  referenceNumber: string | null;
  stopId?: number;
  descriptionWithParanthesis: string;
  rateDetail: string;
}

export interface VendorLineItem {
  operationType: OperationTypeV4;
  id: number;
  vendorContractId: number;
  type: string;
  description: string;
  settledDate: string | null;
  createdDate: Date | null;
  status: string;
  quantity: number;
  rate: number;
  estimate: boolean;
  readOnly: boolean;
  synched: boolean;
  adjustment: boolean;
  referenceNumber: string;
  stopId?: number;
  descriptionWithParanthesis: string;
  rateDetail: string;
}
export interface LineItemViewModel {
  pickUpId: number | undefined;
  dropOffId: number | undefined;
  operationType: OperationTypeV4;
  id: number;
  orderId?: number | undefined;
  type?: string | undefined;
  description?: string | null;
  settledDate?: string | null;
  createdDate?: string | null;
  status?: string | undefined;
  quantity: number | string;
  rate?: number | undefined | string;
  estimate: boolean;
  readOnly: boolean;
  synched: boolean;
  adjustment: boolean;
  referenceNumber?: string | undefined;
  stopId?: number;
  descriptionWithParanthesis?: string | undefined;
  rateDetail?: string | undefined;
}
export interface ILoadEditViewModel {
  trackingEmail: string | undefined;
  trackingServiceId: number | null;
  quoteID: string | undefined | null;
  newLtlQuoteId: number | undefined | null;
  isCopied?: boolean;
  ltlquoteId: string | undefined | null;
  operationType: OperationTypeV4;
  warnings?: string[];
  id: number;
  editable: boolean;
  orderId: number;
  loadNum?: string;
  status?: string;
  sequence: number;
  type?: string;
  customerReferenceNumber?: string;
  coveredById?: number;
  coveredBy?: string;
  carrierId?: number | null;
  carrierName?: string;
  carrierActionPlan: boolean;
  carrierStatus?: string;
  carrierContactId?: number;
  carrierMatchId?: number;
  equipment?: string;
  mode?: LoadModeTypes;
  teamRequired: boolean;
  hazmatRequired: boolean;
  tarpRequired: boolean;
  hideCustomerName: boolean;
  hasBeenSharedWithCapacity: boolean;
  carrierInstructions?: string;
  terminal?: string;
  practicalMileage?: number;
  shortestMileage?: number;
  driver?: string;
  driverPhoneNumber?: string | null;
  secondDriver?: string;
  secondDriverPhoneNumber?: string;
  truckNumber?: string;
  trailerNumber?: string;
  trackingNumber?: string;
  TrackingFrequency?: string;
  product?: string;
  tempInstructions?: string;
  enableLoadCarrierRestrictionOverride: boolean;
  enableLoadCarrierRestrictionOverrideOffice?: string | null;
  billingHold: boolean;
  billingHoldReason?: string;
  billingHoldDescription?: string;
  billedDate?: string;
  paidDate?: string;
  trackingEnabled: boolean;
  enableTracking: boolean;
  assignedToUserId?: number;
  assignedToGroupId?: number;
  assignedToUser?: string;
  assignedToGroup?: string;
  assignedToOffice?: string;
  legalText?: string;
  profitSplit?: number;
  sharedWithOfficeId?: number;
  od: boolean;
  freightValue?: number;
  nmfc?: string;
  weight: number;
  marketRate?: string;
  class?: string;
  canRate: boolean;
  synched: boolean;
  edi: boolean;
  minPrice?: number;
  maxPrice?: number;
  lastInternalNote?: string;
  postingId: number | null;
  signedRateCon: boolean;
  signedConfirmationDate?: string;
  customerId: number;
  ramps: boolean;
  macropointInterval?: string;
  quoteId?: string | null;
  ltlSubCarrier?: string;
  tenderedToTransfloDate?: Date;
  currencyType?: string;
  canVoidLoad: boolean;
  // cargoValueIsHigh?: boolean;
  cargoValueIsHigh?: string;
  assignedId: number;
  owner?: string;
  // carrier?: CarrierViewModel;
  carrierContact?: CarrierContactT;
  claim: ClaimViewModel | null;
  // customerContact?: ContactViewModel;
  trackingService?: number | null;
  ltlShipmentData?: LTLShipmentDataViewModel | undefined | null;
  referenceNumbers?: LTLReferenceNumberCollection | undefined;
  ltlQuote?: LtlQuoteViewModel;
  ltlQuoteParameters?: LtlQuoteParametersViewModel;
  lineItems?: LineItemViewModel[];
  payments?: ICustomerPayment[];
  stops?: LastUsedTopStopModel[];
  comments?: ILoadComments[];
  flags?: FlagViewModel[];
  items?: PickUpDropOffItem[];
  // equipmentOptions?: EquipmentViewModel[];
  loadInformation: ILoadInformation | undefined;
  loadTracking?: LoadTrackingT[] | null;
  nextCheckCallDateUtc?: string;
  quoteStops?: LastUsedTopStopModel[];
}

export enum SocketEventEnum {
  View = 'Order:View',
  Leave = 'Order:Leave',
  Save = 'Order:Save',
  Lock = 'Order:Lock',
  Unlock = 'Order:Unlock',
}

type FalveyInsurance = {
  id: number;
  shipmentUUID: string;
  ltlQuoteId: string | number;
  loadId: number;
  falveyShipmentID: string;
  shippingSumInsured: number;
  shippingCurrency: string;
  quotePremium: number;
  quoteServiceFee: number;
  certificateNumber: string;
  policyNumber: string;
  waybillAdditional: string;
  certificatePDFLink: string;
  createdDate: string;
  modifiedDate: string | null;
  createdBy: number;
  modifiedBy: number | null;
};

export type LoadTrackingT = {
  operationType: OperationType;
  id: string;
  loadId: number;
  trackingProviderId?: number | undefined;
  latitude?: number | undefined;
  longitude?: number | undefined;
  city?: string | undefined;
  state?: string | undefined;
  locationTime: Date;
};

export interface LTLShipmentDataViewModel {
  operationType: OperationTypeV4;
  loadId: number;
  shipmentId?: string | undefined;
  proNumber?: string | undefined;
  pickupNumber?: string | undefined;
  trackingURL?: string | undefined;
  referenceNumbers?: string[] | undefined;
}

export interface LTLReferenceNumberCollection {
  operationType: OperationTypeV4;
  referenceNumbers: LTLReferenceNumber[];
}

export type ClaimViewModel = {
  orderId: number;
  loadId: number;
  agent: string;
  customer: string;
  loadNumber: string;
  description: string | null;
  status: string | null;
  commodity: string;
  cause: null;
  claimNumber: number | null;
  claimDate: string;
  occurredDate: string;
  occurredDateFormatted: string | null;
  claimDateFormatted: string | null;
  claimValue: number | null;
  closedDate: string;
  closedFormattedDate: string | null;
  carrierPaid: null;
  atgPaid: null;
  agentPaid: null;
  insurancePaid: null;
  amountDeclined: null;
  referenceNumber: null;
  comments: Comments[];
};

export interface LtlQuoteViewModel {
  operationType: OperationTypeV4;
  quoteId?: string;
  id: string;
  quoteDate: string;
  carrierName?: string;
  customerName?: string;
  customerId?: number;
  carrierTotal: number;
  customerTotal?: number;
  pickupAccessorials?: string[];
  deliveryAccessorials?: string[];
  shipmentAccessorials?: string[];
  pickupAccessorialDescription?: string;
  deliveryAccessorialDescription?: string;
  shipmentAccessorialDescription?: string;
  maxNewLiability?: number;
  maxUsedLiability?: number;
  transitDays?: string;
  serviceType?: string;
  logoImageSource?: string;
  ltlQuoteLoadItems?: LtlQuoteLoadItemViewModel[];
  terminalInfo?: LtlTerminalInfoViewModel;
  movementType?: string;
  rateQuote: LTLRateQuoteResponse;
  rateRequest: LTLRateQuoteRequest;
  ltlInsurance: FalveyInsurance | null;
  quoteParametersId?: number;
}

export interface LtlQuoteParametersViewModel {
  value: boolean;
  operationType: OperationTypeV4;
  id: number;
  quoteDate: string;
  customerName?: string;
  customerId?: number;
  originZip: string;
  originCity: string;
  originState: string;
  originCountry: string;
  destinationZip: string;
  destinationCity: string;
  destinationState: string;
  destinationCountry: string;
  movementType?: string;
  linearFeet: number;
  isStackable: boolean;
  isLinearFeetOverridden: boolean;
  createdByName: string;
  provider: string;
  accessorials: LtlQuoteAccessorialViewModel[];
  pickupAccessorials?: string[];
  deliveryAccessorials?: string[];
  shipmentAccessorials?: string[];
  ltlQuotes?: NewLtlQuoteViewModel[];
  ltlQuoteLoadItems?: LtlQuoteLoadItemViewModel[];
  ltlInsuranceQuote: FalveyInsuranceQuote | null;
}

export interface NewLtlQuoteViewModel {
  operationType: OperationTypeV4;
  id: number;
  carrierQuoteId?: string;
  providerTransactionId?: string;
  quoteDate: string;
  carrierName?: string;
  customerId?: number;
  carrierTotal: number;
  customerTotal?: number;
  maxNewLiability?: number;
  maxUsedLiability?: number;
  transitDays?: string;
  serviceType?: string;
  movementType?: string;
  pricingRequestType?: string;
  logoImagePath?: string;
  lineItems?: LtlQuoteLineItemViewModel[];
  terminalInfo?: LtlTerminalInfoViewModel;
  ltlInsurancePolicy: FalveyInsurancePolicy | null;
}

export interface LtlQuoteAccessorialViewModel {
  id: number;
  accessorialId: string;
  quoteParametersId: number;
  accessorialName: string;
  accessorialDescription: string;
  isDelivery: boolean;
  isPickup: boolean;
  isGlobal: boolean;
}

export interface FalveyInsuranceQuote {
  id: number;
  quoteParametersId: number;
  shippingSumInsured: number;
  premium: number;
  serviceFee: number;
  createdDate: string;
  modifiedDate: string | null;
  createdBy: number;
  modifiedBy: number | null;
}

export interface FalveyInsurancePolicy {
  id: number;
  shipmentUUID: string;
  ltlQuoteId: string;
  loadId: number;
  falveyShipmentID: string;
  shippingCurrency: string;
  premium: number;
  serviceFee: number;
  certificateNumber: string;
  policyNumber: string;
  waybillAdditional: string;
  certificatePDFLink: string;
  createdDate: string;
  modifiedDate: string | null;
  createdBy: number;
  modifiedBy: number | null;
}

export interface LtlQuoteLineItemViewModel {
  operationType: OperationTypeV4;
  id: number;
  quoteId: string;
  type: string;
  description: string;
  quantity: number;
  carrierRate: number;
  customerRate?: number;
}

export interface LtlTerminalInfoViewModel {
  operationType: OperationTypeV4;
  pickupTerminal?: LtlTerminalViewModel;
  deliveryTerminal?: LtlTerminalViewModel;
}
export interface LtlQuoteLoadItemViewModel {
  operationType: OperationTypeV4;
  id: string;
  quantity: number;
  pieces?: number;
  weight: number | null;
  class?: string;
  nMFC?: string;
  lengthInches?: number;
  widthInches?: number;
  heightInches?: number;
  hazmat: boolean;
  dimensions?: string;
  displayDimensions?: string;
  suggestedClass?: null | string;
  item: string;
  type?: string;
  description?: string;
  loadItemHazmatDetail?: LtlQuoteHazmatDetailsViewModel;
  charge?: null | number;
  height?: null | number;
  length?: null | number;
  width?: null | number;
  volume?: null | number;
  hazmatItems?: null | string;
  hazmatDescription?: null | string;
  density?: string;
}

export interface LtlQuoteHazmatDetailsViewModel {
  operationType: OperationTypeV4;
  unNumber?: string;
  packingGroup?: string;
  hazardClass?: string;
  piece?: string;
  contactNumber?: string;
  ltlQuoteLoadItemId: string;
  contactName: string;
}

export interface LtlTerminalViewModel {
  operationType: OperationTypeV4;
  terminalName?: string;
  address1?: string;
  address2?: string;
  city?: string;
  postalCode?: string;
  stateOrProvince?: string;
  countryCode?: string;
  contactName?: string;
  contactPhone?: string;
  contactTollFree?: string;
  contactFax?: string;
  contactEmail?: string;
  carrierName?: string;
  scac?: string;
}

export interface ILoadComments {
  city: string | null;
  comment: string;
  id: number;
  latitude: number | null;
  longitude: number | null;
  milesRemaining: number | null;
  name: string;
  objectId: number;
  operationType: OperationType;
  state: string | null;
  temperature: number | null;
  thumbnail: string | null;
  timestamp: string;
  type: string | null;
  userId: number;
  visibleToCustomer: boolean;
}

export interface StopModelFormLoadItemsModel {
  quantity: number | null;
  unitType: string;
  description: string;
  weight: number | null;
  length: number | null;
  width: number | null;
  height: number | null;
  lengthInch: number | null;
  widthInch: number | null;
  heightInch: number | null;
  hazmat: boolean;
  relatedStopId: number | null;
}

export interface StopModelForm {
  locationName: string;
  appointmentDate: string;
  appointmentTime: string;
  appointmentNum: string;
  address1: string;
  address2: string;
  countryCode: string;
  internalNotes: string;
  directions: string;
  instructions: string;
  city: string;
  state: string;
  zip: string;
  contact: string;
  phone: string;
  email: string;
  time: string;
  referenceNum: string;
  stopDateTime: string;
  driverInDate: string;
  driverOutDate: string;
  driverInTime: string;
  driverOutTime: string;
  isPickup: boolean;
  modifiedDate: string; // check this later
  items: StopModelFormLoadItemsModel[];
}

export interface stopModelwithItems extends LastUsedTopStopModel {
  items: PickUpDropOffItem[];
}

export interface EdiUpdate {
  shipmentStatus: string | null;
  appointmentStatus: string | null;
  date: string;
  appointmentNumber: string | null;
  timeZone: string;
  stopId: number;
  statusReason: string;
  loadId: number;
  city: string;
  auto214: boolean;
  temperature: null;
  state: string;
  note: null;
  latitude: null;
  longitude: null;
}
export interface LastUsedTopStopModel {
  operationType: OperationTypeV4;
  timeZoneOffset: number;
  stopType?: string | null;
  id: number;
  customerId: number;
  loadId: number;
  stopDate: string;
  stopDateFormatted?: string | null;
  stopDateTimeFormatted?: string | null;
  description?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  zip: string;
  locationName?: string | null;
  contact?: string | null;
  phone?: string | null;
  pickUp: boolean;
  dropOff: boolean;
  stopOrder?: number | null;
  origin?: boolean | null;
  destination?: boolean | null;
  createdDate: string | null;
  modifiedDate?: string | null;
  referenceNum?: string | null;
  time?: string | null;
  instructions?: string | null;
  directions?: string | null;
  needsAppointment: boolean;
  appointmentDate?: string | null;
  driverInTime?: string | null;
  driverOutTime?: string | null;
  appointmentNum?: string | null;
  email?: string | null;
  stopCode?: string | null;
  countryCode?: string | null;
  arrived: boolean | null;
  lat?: number | null;
  long?: number | null;
  practicalMileageToNextStop?: number | null;
  shortestMileageToNextStop?: number | null;
  stopDateTime?: string | null;
  proprietaryCode?: string | null;
  appointment?: { date: string; time: string };
  driverIn?: { date: string; time: string };
  driverOut?: { date: string; time: string };
  //NOTE: This is the new property added to the model for edi load for updates in the stop
  ediUpdates?: EdiUpdate[];
  shouldSendEdiBooleanVals: {
    shouldSendPickupAppointmentAuto214: boolean;
    shouldSendDropoffAppointmentAuto214: boolean;
    shouldSendDropoffDriverInAuto214: boolean;
    shouldSendPickupDriverInAuto214: boolean;
    shouldSendPickupDriverOutAuto214: boolean;
    shouldSendDropoffDriverOutAuto214: boolean;
  };
}

export interface StopViewModel {
  timeZoneOffset: number;
  stopType?: string | null;
  id: number;
  customerId: number;
  loadId: number;
  stopDate: string;
  stopDateFormatted?: string | null;
  stopDateTimeFormatted?: string | null;
  description?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  zip: string;
  locationName?: string | null;
  contact?: string | null;
  phone?: string | null;
  pickUp: boolean;
  dropOff: boolean;
  stopOrder: number;
  origin?: boolean | null;
  destination?: boolean | null;
  createdDate: string | null;
  modifiedDate?: string | null;
  referenceNum?: string | null;
  time?: string | null;
  instructions?: string | null;
  directions?: string | null;
  needsAppointment: boolean;
  appointmentDate?: string | null;
  driverInTime?: string | null;
  driverOutTime?: string | null;
  appointmentNum?: string | null;
  email?: string | null;
  stopCode?: string | null;
  countryCode?: string | null;
  arrived: boolean | null;
  lat?: number | null;
  long?: number | null;
  practicalMileageToNextStop?: number | null;
  shortestMileageToNextStop?: number | null;
  stopDateTime?: string | null;
  appointment?: { date: string; time: string };
  driverIn?: { date: string; time: string };
  driverOut?: { date: string; time: string };
}

export interface ICustomerPayment {
  operationType: OperationTypeV4;
  amount: number | null;
  description: string;
  paidDate: Date | string | null;
  referenceNum: string;
  checkNumber: string;
  type: string;
  status: string;
  id: number | null;
}

export interface ediLog {
  shipmentId?: string;
  tenderType?: string;
  transactionId?: string;
  type?: string;
  timestamp?: string;
  response?: string;
  event?: {
    description?: string;
    code?: string;
    category?: string;
  };
  status?: {
    description?: string;
    code?: string;
    category?: string;
  };
  address?: null;
  stopNumber?: number;
  eventTime?: string;
  eventTimeZone?: string;
  note?: string;
}

export interface EdiLogState {
  ediLogs: ediLog[];
  loading: boolean;
  isError: boolean;
}

export type HazmatDetails = {
  operationType: OperationTypeV4;
  unNumber: string;
  packingGroup: string;
  hazardClass: string;
  piece: string;
  contactNumber: string;
  loadItemId: number;
  contactName: string;
};

export type PickUpDropOffItem = {
  createdBy: number | null;
  operationType: OperationTypeV4;
  id: number;
  loadId: number;
  nmfc: null | string;
  class: null | string;
  item: string;
  description: string;
  quantity: number;
  charge: null | string;
  pickUpId: number | null;
  dropOffId: number | null;
  weight: string;
  uom: 'lb(s)' | string;
  volume: null | number;
  hazmat: boolean;
  heightInch: null | number;
  lengthInch: null | number;
  widthInch: null | number;
  height: null | string;
  length: number;
  width: null | string;
  pieces: null | string;
  descriptionWithParanthesis: string;
  displayDimensions: string;
  loadItemHazmatDetail?: null | HazmatDetails;
  createdDate: null | string;
  historyContext: null | string;
  modifiedDate: null | string;
  modifiedBy: null | string;
  load: null | string;
  dropOff: null | string;
  pickUp: null | string;
  suggestedClass: null | string;
  hazmatItems: null | string;
  hazmatDescription: null | string;
  type: string | null;
  density: string;
};

export type LoadTenderState = {
  loadTender?: ILoadTender;
  loading: boolean;
  isError: boolean;
};

export interface ILoadTender {
  shipmentId: string;
  customerId: number;
  customer: string;
  rate: string;
  equipment: string;
  transportationMethod: string;
  stops: IStops[];
}

export type CarrierContactT = {
  id: number;
  objectid: number;
  objecttype?: string;
  name: string;
  phone1: string;
  extension1?: string | null;
  phone2?: string;
  extension2?: string | null;
  fax?: string;
  email?: string;
  type?: string;
  status?: string;
  createdDate?: string;
  modifiedDate?: string;
  mailerEmails: boolean;
  password?: string;
  username?: string;
  phone?: string;
  verificationStatus: number;
  emailVerified: boolean;
  phoneVerified: boolean;
  ownershipSource: number;
};

export interface IStops {
  stopNum: number;
  stopId: number;
  weight: number;
  volume: string;
  bol: string;
  ponumber: string;
  transactionNumber?: number;
  requestedDate: string;
  requestedTime?: string;
  earliestRequestedDate: string;
  earliestRequestedTime?: string;
  latestRequestedDate: string;
  latestShipTime?: string;
  pallets: number;
  numItems: number;
  name: string;
  address1: string;
  address2: string;
  phone: string;
  referenceNumber?: string;
  commodity?: string;
  weightUOM?: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  code: string;
  pickUp: boolean;
  dropOff: boolean;
  contact: CarrierContactT;
  appointmentNumber?: number;
  appointmentDate?: string;
  description?: string;
  instructions?: string;
  note?: string;
  reason: string;
  items?: string;
}

export interface ICreditCardPayment {
  emailSettings: {
    to: string[];
    body: string;
    subject: string;
  };
  fee: boolean;
}
export interface ContactInfo {
  ObjectId: number;
  Name: string;
  Phone1: string | undefined;
  Phone2: string | undefined;
  Email: string;
  Type: string;
  Fax: string | undefined;
}
export interface Contact {
  id: number;
  objectId: number;
  objectType: string;
  name: string;
  phone1: string;
  extension1: string;
  phone2: string;
  extension2: string;
  fax: string;
  email: string;
  type: string;
  status: string;
  createdDate: string;
  modifiedDate: string;
  mailerEmails: boolean;
  password: string;
  userName: string;
  phone: string;
}

interface Address {
  line1: string;
  line2: string | null;
  city: string;
  state: string;
  zip: string;
  name: string | null;
  phone: string | null;
  phoneAreaCode: string | null;
  phoneSevenDigit: string | null;
  code: string | null;
  country: string | null;
  contact: string | null;
  contactLastName: string | null;
  contactFirstName: string | null;
}

export interface Carrier {
  id: number;
  name: string;
  contacts: CarrierContactT[];
  dot: string;
  mc: string;
  address: Address;
  rating: number;
  numRatings: number;
  status: string;
  carrierActionPlan: boolean;
  officeRating: number;
  isActive: boolean;
  hasExpiredCargoInsurance: boolean;
  hasExpiredLiabilityInsurance: boolean;
  cargoExpiration: string;
  liabilityExpiration: string;
  warnings: string[];
  advancesAllowed: boolean;
  mode: string;
  blacklisted: boolean;
  firstLoad: boolean;
  corporatePhoneNumber: string;
  factors: boolean;
  fallouts: number;
  totalLoads: number;
  ltlEnabled: boolean;
  transfloEnabled: boolean;
  brokerAuthority: boolean;
  currencyOptionEnabled: boolean;
  requireMacropointTracking: boolean | null;
  shipperVerifyMCDotNumber: boolean | null;
}

export type CheckCarrierConditional = {
  id: number;
  name: string;
  taxId: string;
  mc: string;
  dot: string;
  billingTerms: string;
  remitTo: null | string;
  liabilityInsuranceCompany: string;
  liabilityLimit: number;
  liabilityPolicyNumber: string;
  liabilityExpiration: string;
  cargoInsuranceCompany: string;
  cargoLimit: number;
  cargoPolicyNumber: string;
  cargoExpiration: string;
  contractDocument: null | string;
  contractSignedDate: null | string;
  contractSignedBy: null | string;
  documentsFolder: null | string;
  needs1099: boolean;
  factor: boolean;
  status: string;
  createdDate: string;
  modifiedDate: string;
  falloutCount: number;
  mode: string;
  allowAdvances: boolean;
  accountingSysId: string;
  vendorHold: boolean;
  taxIdVerificationDate: null | string;
  smartWayCertified: boolean;
  hazmatCertified: boolean;
  reeferInsurance: boolean;
  workersCompInsuranceCompany: null | string;
  workersCompLimitEachAccident: null | number;
  workersCompPolicyNumber: null | string;
  workersCompExpiration: null | string;
  workersCompLimitDisease: null | number;
  workersCompLimitPolicy: null | number;
  isVendor: null | boolean;
  ltlEnabled: boolean;
  conditionalActionPlanReceived: boolean;
  corporatePhoneNumber: string;
  notes: null | string;
  ltlTerminalLink: null | string;
  ltlRateNote: null | string;
  transfloEnabled: boolean;
  thirdPartyVerificationDate: string;
  vendorHoldReason: null | string;
  vendorHoldDescription: null | string;
  createdBy: null | number;
  historyContext: null | string;
  modifiedBy: number;
  carrierBlacklists: unknown[]; // Add types when receive value later
  carrierComments: unknown[]; // Add types when receive value later
  carrierContacts: unknown[]; // Add types when receive value later
  carrierHistories: unknown[]; // Add types when receive value later
  carrierRatings: unknown[]; // Add types when receive value later
  loads: unknown[]; // Add types when receive value later
  lanes: unknown[]; // Add types when receive value later
  ltlSettingValues: unknown[]; // Add types when receive value later
  vendorContracts: unknown[]; // Add types when receive value later
  equipments: unknown[]; // Add types when receive value later
  carrierScacs: unknown[]; // Add types when receive value later
  playlists: unknown[]; // Add types when receive value later
  ltlBlockedCarriers: unknown[]; // Add types when receive value later
  ltlCostPlusOverrideCarriers: unknown[]; // Add types when receive value later
  carrierSafetyDetail: null | unknown;
  carrierCertificateDocuments: unknown[]; // Add types when receive value later
  carrierAddresses: unknown[]; // Add types when receive value later
  carrierMaxLiabilities: unknown[]; // Add types when receive value later
};

export type CheckCarrierConditionalApiResponse = {
  success: boolean;
  message: string[];
  data: CheckCarrierConditional;
};

type CarrierInsuranceAddress = {
  id: number;
  enabled: boolean;
  countryCode: string;
  physicalAddress1: null | string;
  physicalAddress2: null | string;
  physicalCity: null | string;
  physicalZip: null | string;
  physicalStateOrProvince: null | string;
  mailingAddress1: null | string;
  mailingAddress2: null | string;
  mailingCity: null | string;
  mailingStateOrProvince: null | string;
  mailingZip: null | string;
  factoringCompanyId: null | string;
  achRouting: null | string;
  achAccount: null | string;
  factoringCompany: null | string;
};

export type CarrierInsuranceDetail = {
  overallMaxLiabilityClass: string;
  id: number;
  currentContactId: number;
  name: string;
  taxId: string;
  mc: string;
  dot: string;
  billingTerms: string;
  remitTo: null | string;
  emailCopy: null | string;
  liabilityInsuranceCompany: string;
  liabilityLimit: number;
  liabilityPolicyNumber: string;
  liabilityExpiration: string;
  cargoInsuranceCompany: string;
  cargoLimit: number;
  cargoPolicyNumber: string;
  cargoExpiration: string;
  workersCompInsuranceCompany: null | string;
  workersCompLimitEachAccident: number;
  workersCompPolicyNumber: null | string;
  workersCompExpiration: null | string;
  workersCompLimitDisease: number;
  workersCompLimitPolicy: number;
  contractDocument: null | string;
  contractSignedDate: null | string;
  contractSignedBy: null | string;
  documents: null | string;
  needs1099: boolean;
  factor: boolean;
  conditionalActionPlanReceived: boolean;
  status: string;
  createdDate: string;
  modifiedDate: string;
  falloutCount: number;
  mode: null | string;
  allowAdvances: boolean;
  alreadySetup: boolean;
  accountingSysId: string;
  vendorHold: boolean;
  taxIdVerificationDate: null | string;
  smartWayCertified: boolean;
  reeferInsurance: boolean;
  hazmatCertified: boolean;
  safetyRating: null | string;
  safetyWarnings: null | string;
  brokerAuthority: boolean;
  commonAuthority: boolean;
  contractAuthority: boolean;
  isFavorite: boolean;
  officeRating: number;
  numRatings: number;
  numberOfLoads: number;
  ltlEnabled: boolean;
  comments: unknown[];
  contacts: unknown[];
  ratings: unknown[];
  equipments: unknown[];
  lanes: unknown[];
  maxLiabilities: unknown[];
  overallMaxUsedLiability: null | unknown;
  overallMaxNewLiability: null | unknown;
  blacklist: null | unknown;
  corporatePhoneNumber: string;
  ltlTerminalLink: null | string;
  ltlRateNote: null | string;
  transfloEnabled: boolean;
  vendorHoldReason: null | string;
  vendorHoldDescription: null | string;
  usaAddress: CarrierInsuranceAddress;
  canAddress: CarrierInsuranceAddress;
  averageRating: number;
  paymentTermDays: number;
  hasExpiredCargoInsurance: boolean;
  hasExpiredLiabilityInsurance: boolean;
};

export type CarrierUpdateInsuranceApiResponse = {
  success: boolean;
  message: string[] | string;
  data: CarrierInsuranceDetail;
};

export type CarrierFeatures = {
  triumphPayCurrency: boolean;
  triumphPayInvoice: boolean;
  triumphPayLoad: boolean;
};

export type CarrierState = {
  carrier: Carrier[];
  featureFlags: CarrierFeatures[];
  playlists: Playlist[];
  loading: boolean;
  isError: boolean;
};

export type CustomerAddress = {
  id?: string;
  customerId: number;
  name?: string | null;
  specialInstructions?: string | null;
  description?: string | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  openTimeFrom?: Date | null;
  openTimeTo?: Date | null;
  contactName?: string | null;
  contactPhone?: string | null;
  createdOn?: Date | null;
  countryCode?: string | null;
  internalNote?: string | null;
  drivingDirections?: string | null;
  isDefault?: string | null;
  isDeleted?: boolean;
  // customer?: Customer | null;
  // customerAddressHistories?: CustomerAddressHistory[] | null;
};

export type CustomerAutoCompleteObject = {
  id: number | null;
  name: string;
  office: string | null;
  accountManager: string | null;
  status: 'Customer';
  defaultOriginZipCode: string | null;
  defaultOriginCity: string | null;
  defaultOriginState: string | null;
  defaultOriginCountryCode: string | null;
};

export type LoadCommentResponse = {
  city: string | null;
  comment: string;
  id: number;
  latitude: number | null;
  longitude: number | null;
  milesRemaining: number | null;
  name: string;
  objectId: number;
  operationType: OperationType;
  state: string | null;
  temperature: number | null;
  thumbnail: string | null;
  timestamp: string;
  type: string | null;
  userId: number;
  visibleToCustomer: boolean;
};

export type ODataApiResponse = {
  '@odata.context': string;
  '@odata.count': number;
  value: LTLCatalogs[];
};

export type ODataApiQueryParams = {
  $top: number;
  $skip: number;
  $orderby: string;
  $format: string;
  $count: boolean;
  $filter?: string;
};

//TODO:Take Types from LTL Quoting Chnage Done by me After Merge
export type LTLCatalogs = {
  pieces: number | null;
  weight: number | null;
  id: string;
  customerId: number;
  name: string;
  description: string;
  class: string | null;
  nMFC: string;
  note: string;
  lengthFeet: null | number;
  lengthInches: number;
  widthFeet: null | number;
  widthInches: number;
  heightFeet: null | number;
  heightInches: number;
  hazmat: boolean;
  unitType: string;
  createdDate: string;
  modifiedDate: string;
  uNNumber: string;
  packingGroup: string;
  hazardClass: string;
  piece: string;
  contactNumber: string;
  contactName: string;
};

export enum FlagType {
  Red = 'Red Flag',
  Blue = 'Blue Flag',
  Green = 'Green Flag',
  Yellow = 'Yellow Flag',
  Orange = 'Orange Flag',
  BillingHold = 'Billing Hold',
  BillingNote = 'Billing Note',
  EFSIssued = 'EFS Issued',
  Flagged = 'Flagged',
  GetPaperwork = 'Get Paperwork',
  Hold = 'Hold',
  HotLoad = 'Hot Load',
  Issue = 'Issue',
  MissingPaperwork = 'Missing Paperwork',
  NeedsApproval = 'Needs Approval',
  NeedsPaperwork = 'Needs Paperwork',
  RefundRequest = 'Refund Request',
  TenderUpdated = 'Tender Updated',
  Uploaded = 'Uploaded',
  None = '',
}

export type DisputedOrderFlagT = {
  clearedBy: string | undefined | null;
  clearedDate: string | undefined | null;
  disputedDate: string;
  flagId: number;
  id: string;
  operationType: string;
};

export interface CommentViewModel {
  operationType: OperationType;
  id: number;
  objectId: number;
  userId: number;
  name?: string | undefined;
  type?: string | undefined;
  comment?: string | undefined;
  timestamp?: Date | string | undefined;
  thumbnail?: string | undefined;
  createdBy?: string | undefined;
}

export interface FlagViewModel {
  operationType?: OperationTypeV4;
  id?: number;
  loadId?: number | undefined;
  orderId?: number;
  setBy?: string | undefined;
  description?: string | undefined;
  emailId?: string | undefined;
  setByUserId?: number;
  setDate?: string;
  flagType: FlagType;
  cleared?: boolean;
  editable?: boolean;
  commentCount?: number;
  snoozeDate?: Date | undefined;
  snoozeTimes?: number;
  commentable?: boolean;
  mode?: string;
  disputedOrderFlags?: DisputedOrderFlagT[];
  comments?: CommentViewModel[];
}

export interface VendorContractViewModel {
  operationType?: OperationTypeV4;
  id?: number | null;
  orderId?: number;
  description?: string;
  invoicedDate?: string | null;
  paidDate?: string | null;
  name?: string;
  mailingAddress1?: string;
  mailingAddress2?: string;
  mailingCity?: string;
  mailingState?: string;
  mailingZip?: string;
  countryCode?: string;
  serviceType?: string;
  serviceTypeText?: string | null;
  currencyType?: string;
  contactName?: string;
  contactPhone?: string;
  status?: string | null;
  synched?: boolean;
  payments?: PaymentViewModel[];
  lineItems?: VendorLineItem[];
  vendorPayDisplay?: number;
  otherServiceType?: boolean;
}

export type flagOptions =
  | 'Red Flag'
  | 'Green Flag'
  | 'Blue Flag'
  | 'Yellow Flag'
  | 'Orange Flag'
  | 'Needs Approval'
  | 'Get Paperwork'
  | 'Refund Request'
  | 'Efs Issued'
  | 'Missing Paperwork'
  | 'Hot Load'
  | '';

type LineItemError = {
  type?: string[];
  description?: string[];
  quantity?: string[];
  rate?: string[];
  estimate?: string[];
};

type FlagError = {
  description?: string[];
  flagType?: string[];
  emailId?: string[];
};

type VendorError = {
  name?: string[];
  contactName?: string[];
  mailingAddress1?: string[];
  mailingCity?: string[];
  mailingState?: string[];
  mailingZip?: string[];
  serviceType?: string[];
  serviceTypeText?: string[];
  description?: string[];
  invoicedDate?: string;
  paidDate?: string;
  mailingAddress2?: string[];
  countryCode?: string[];
  currencyType?: string[];
  contactPhone?: string[];
  status?: string[];
  synched?: boolean[];
  lineItems?: LineItemError[];
  // payments?: PaymentViewModel[];
};

type LoadError = {
  cargoValueIsHigh?: string[];
  freightValue?: string[];
  minPrice?: string[];
  maxPrice?: string[];
  driver?: string[];
  driverPhoneNumber?: string[];
  trailerNumber?: string[];
  truckNumber?: string[];
  lineItems?: LineItemError[];
  flags?: FlagError[];
};

export type OrderFormError = {
  customerId?: string[];
  status?: string[];
  referenceNumber?: string[];
  bidType?: string[];
  spotId?: string[];
  spotQuote?: string[];
  owner?: string[];
  driverPhoneExt?: string[];
  currencyType?: string[];
  carrierInstructions?: string[];
  legalText?: string[];
  lineItems?: LineItemError[];
  loads?: LoadError[];
  vendors?: VendorError[];
  cargoValue?: string[];
  holdRetraction?: string[];
  exceptionReason?: string[];
  revisitRetractionDate?: string[];
};
export interface availableServices {
  id: number;
  name: string;
  quickPostInclude: boolean;
}

export interface IPostLoad {
  pickUp: string | Date;
  equipment: string;
  origin: string;
  destination: string;
  price: number | null;
  originCity: string;
  originState: string;
  originCountry: string;
  mileage: number | undefined;
  destinationCity: string;
  destinationState: string;
  destinationCountry: string;
  dropOff: string | Date;
  drops: number;
  fullTruckload: boolean;
  tags: string[];
  weight: number;
  length: number;
  postingClients?: string[];
  id: null | number;
  loadId: number;
  servicesToPostTo: string[];
  internalNote: string;
  comment: string;
}

export interface IManagePostLoad {
  id: number;
  loadId: number;
  boardsPostedTo: availableServices[];
  isQuickPost?: boolean;
  origin: string;
  originCity: string;
  originState: string;
  originCountry: string;
  destination: string;
  destinationCity: string;
  destinationState: string;
  destinationCountry: string;
  pickUp: string;
  dropOff: string;
  price: number | null;
  equipment: string;
  comment: string;
  drops: number;
  height?: number | null;
  length: number;
  width?: number | null;
  datePosted?: string;
  reference?: string | null;
  volume?: number | null;
  weight: number;
  tags?: string[] | undefined;
  availableServices?: string[] | null;
  servicesToPostTo: availableServices[];
  loadNumber?: string;
  fullTruckload: boolean;
  postedBy?: string;
  mileage: number;
  internalNote: string;
  contactPhone?: string | null;
  originZip?: string | null;
  destinationZip?: string | null;
  boardsPosted?: string;
  tagsList?: string;
  errors: string[];
}
export interface Playlist {
  id: number;
  userId: number;
  name: string;
  user: string;
  carriers: PlaylistCarrier[];
  groups: Group[];
  editable: boolean;
  canEdit: boolean;
}

export interface PlaylistCarrier {
  id: number;
  name: string | null;
  mc: string | null;
  dot: string | null;
  status: string | null;
  operationType: OperationTypeV4;
}

interface Group {
  id: number;
  name: string;
  operationType: OperationTypeV4;
  officeName: string;
}

export type TCarrierContactPayload = {
  name: string;
  type?: string | null;
  phone1?: string | null;
  extension1?: number | null;
  phone2?: string | null;
  extension2?: number | null;
  fax?: string | null;
  email?: string | null;
  objectId: number;
  cpn?: string;
};

export type TrackingState = {
  success: boolean;
  message: string;
};

export type RatePayloadType = {
  rating: number;
  comment: string;
  carrierId: number;
  loadId: number;
};

export type AddFlagCommentPayload = {
  id: number;
  comment?: string;
};

export interface TrackingData {
  loadId: number;
  trackingStartDate: string;
  serviceId: number;
  driver: string | null;
  trackingStopWindows: TrackingStopWindow[];
  driverPhone: string;
  settings: {
    truckNumber: string;
    trailerNumber: string;
    timeZone: string;
    customerRequired: boolean;
    resend: string;
    trackingEmail: string;
    TrackingFrequency: string;
  };
}

export interface TrackingStopWindow {
  Id: number;
  ETA: string;
  EDT: string;
}

export enum TrackingProviders {
  'Macropoint' = 1,
  'TenFourSystems' = 2,
  'Project44' = 3,
  'FourKites' = 4,
  'Transflo' = 5,
}
export interface UserInfoModel {
  id: number;
  name: string;
  email: string;
  office: string;
  officeId: number;
  accountingGroup: string;
  phone1?: string;
  phone1Ext?: number | null;
  altPhone?: string;
  alptPhone?: number;
  fax?: string;
}

export interface NearbyCarriersReturn {
  carrier: string;
  mc: string;
  dot: string;
  dropDate: string;
  dropCity: string;
  dropState: string;
  dropZip: string;
  contact: string;
  contactType: string;
  phone: string;
  email: string;
  truckNum: string;
  trailerNum: string;
  carrierId: number;
  equipment1: string;
}

export interface NearbyCarrierRequestViewModel {
  zip: string;
  startDate: string;
  radius?: number;
  equipment: string;
}

export interface UserAutoComplete {
  id: number;
  office: string;
  firstName: string;
  lastName: string;
  name: string;
  email: string;
  phone: string;
  phone1: string;
  phone1Ext: number;
  phone2: string;
  phone2Ext: number;
  fax: string;
  officeId: number;
  percentage: number;
  active: boolean;
  postingGroupId: number;
  accountingGroup: string;
  thumbnail: string;
}

export type BaseStatusCode = {
  description: string;
  code: string;
  category: string | null;
};

export type EDIStatusCodesApiResponse = {
  statusCodes: BaseStatusCode[];
  statusReasons: BaseStatusCode[];
  appointmentCodes: BaseStatusCode[];
};

export type LoadEdiStatusUpdatePayload = {
  loadId: number;
  shipmentStatus: string;
  appointmentStatus: string | null;
  statusReason: string;
  stopId: number;
  date: string;
  timezone: string;
};
export interface PcMilerError {
  type: number;
  description: string;
  code?: number;
  legacyErrorCode?: number;
}

export interface PcMilerResponse {
  address: {
    zip?: string | null | number;
    state?: string | null;
  };
  errors?: PcMilerError[] | null;
}

export type ShareUnShareCapacityPayload = {
  id: number;
  customerId: number;
  loadId: number;
  orderId: number;
  removeCapacity: boolean;
};

export interface AppointmentReminderModel {
  date: string;
  description: string;
  endTime: string;
  objectIdType: string;
  public: boolean;
  startTime: string;
  time: string;
  title: string;
}

export interface CustomerPrePaymentViewModel {
  id: string;
  orderId: number;
  paidDate: Date;
  paidAmount: number;
  referenceNumber?: string | undefined;
  prePaymentType: number;
  processed: boolean;
  PageHeaderrocessedDate?: Date | undefined;
}

export type CarrierAutoCompleteModel = {
  id: number;
  name: string;
  mc: string;
  dot: string;
  status: string;
  city: string;
  state: string;
  ltlEnabled: boolean;
};

export interface CollectionActionViewModel {
  action: string;
  orderId: number;
  comment: string;
  date: string;
  user: string;
}

export type CollectionActions = {
  [key: string]: CollectionActionViewModel[];
};

export type GeocodeModel = {
  address: string;
  city: string;
  state: string;
  lat?: number;
  lng?: number;
};

export type OrderTemplate = {
  createdDate: string;
  editable: boolean;
  id: number;
  name: string;
  orderId: number;
  owner: string;
  public: boolean;
};

export type SubmitShipmentResult = {
  code: string;
  message: string;
  thirdPartyBilling: string | null;
  trackingNumber: string;
  providerResponse: string;
  proNumber: string | null;
};

export type CommitQuoteResult = {
  bol: string;
  falveyShipmentID: number;
  shipmentDate: string;
  shippingSumInsured: string;
  shippingSumInsuredWords: string;
  shippingCurrency: string;
  premium: number;
  providerFee: number;
  serviceFee: number;
  certificateNumber: string;
  policyNumber: string;
  certificatePDF: string;
  waybillAdditional: string;
  invoiceNum: string;
  certificatePDFLink: string;
  certificatePDFQRCode: string;
  errors?: string[];
};

export type ShareCapacityResult = {
  assignedId: number;
  assignedName: string;
};

export type LoadSearch = {
  loadId: number;
  orderId: number;
  loadNumber: string;
  customerId: number;
  customer: string;
  status: string;
  carrier: string;
  mode: string;
  equipment: string;
  agent: string;
  loadType: string | null;
  coveredBy: string;
  accountManager: string;
  salesManager: string;
  office: string;
  officeId: number;
  originCity: string;
  originState: string;
  destinationCity: string;
  destinationState: string;
  pickup: string;
  pickupTime: string;
  dropoff: string;
  dropoffTime: string;
  trackingNumber: string | null;
  trailerNumber: string | null;
  referenceNum: string | null;
  carrierPay: number;
  customerPay: number | null;
  loadCreatedDate: string;
  loadBilledDate: string | null;
  claim: boolean;
  orderStatus: string;
  carrierContactName: string;
  carrierContactType: string;
  carrierContactPhone: string | null;
  carrierContactEmail: string;
  weight: number;
  pieces: number;
  product: string | null;
};

export interface RevenueSearchData {
  orderId: number;
  numLoads: number;
  numLoadsCovered: number;
  numVendors: number;
  mode: string;
  referenceNumber: string;
  customerId: number;
  customer: string;
  customerBusinessType: string | null;
  customerCurrencyType: string;
  status: string;
  owner: string;
  agentId: number;
  accountManager: string;
  salesManager: string;
  carrier: string;
  groups: string[];
  customerGroups: string[];
  coveredBy: string;
  carriers: string[];
  office: string;
  officeId: number;
  originCity: string;
  originState: string;
  destinationCity: string;
  destinationState: string;
  pickup: string;
  dropoff: string;
  carrierPay: number;
  customerPay: number;
  adjustments: number;
  vendorPay: number;
  createdDate: string;
  invoicedDate: string;
  paidDate: string | null;
  profit: number;
  margin: number;
  origin: string;
  destination: string;
  bidType: string;
  spotId: string | null;
  spotQuote: string | null;
}

export type FetchPricingForPostingPayload = {
  origination: {
    address: string | null;
    city: string | null;
    state: string | null;
    country: string | null;
  };
  destination: {
    address: string | null;
    city: string | null;
    state: string | null;
    country: string | null;
  };
  equipment: string | null;
  pricingService: number;
};

export type FetchPricingForPostingResponse = {
  avgTotal: string;
  highTotal: string;
  lowTotal: string;
  detailsLink: unknown | null;
};

export type AdjustmentT = {
  amount: number;
  createdBy: number;
  createdDate: string;
  description: string;
  id: number;
  modifiedBy: string | null;
  modifiedDate: string | null;
  order: unknown | null;
  orderId: number;
  retraction: unknown;
  settledDate: string | null;
};

export type TrackingResponse = {
  adjustment: AdjustmentT;
  success: boolean;
  trackingId: string;
  error: string;
  errorCode: string;
  message: string;
};
