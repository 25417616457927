import { Card, DatePicker, Notification, Typography } from '@components';
import {
  CheckboxInput,
  SelectField,
  TextAreaField,
  TextInput,
} from '@components/forms';
import {
  Box,
  Divider,
  FormControlLabel,
  FormLabel,
  Grid,
  RadioGroup,
  Stack,
  Switch,
  Radio,
  Skeleton,
} from '@suid/material';
import { For, onMount, Show } from 'solid-js';
import { useParams } from '@solidjs/router';
import {
  customerContractStore,
  setCustomerContract,
  getCustomerContracts,
  ContractTermOption,
  CustomerContract,
  InsuranceOption,
} from '@store/customers/customerDetails/contracts';
import { isReadonly } from '@utils/utils';

import {
  accessorialOptions,
  amountOptions,
  brokerInsuranceOptions,
  brokerResponsibilityOptions,
  cargoOptions,
  carrierBrokerLiabilityOptions,
  carrierInsuranceOptions,
  claimAndConcealedDamageOptions,
  claimAndConcealedDamageRadioOptions,
  claimValueBasisOptions,
  contractPaymentTerms,
  contractTerms,
  countries,
  entities,
  indemnificationOptions,
  otherCarrierRequirementsOptions,
  pollutionOptions,
  temperatureControlledOptions,
  termAndPaymentTextAreaOptions,
  timeFrameOptions,
  workerCompensationOptions,
} from './constant';
import cls from './classes';

const DisclaimerText = (
  <span>
    <b>DISCLAIMER</b>: THE CUSTOMER'S REQUIREMENTS ARE OUTLINED BELOW. IT IS THE
    AGENT'S RESPONSIBILITY TO ENSURE ALL CARRIERS MEET THE COVERAGE LIMITS
    REQUIRED AND THAT THE CUSTOMER’S DEMANDS ARE OTHERWISE MET. THIS DOCUMENT IS
    MEANT TO ASSIST, BUT IS NOT A SUPPLEMENT FOR REVIEWING THE ENTIRE AGREEMENT.
  </span>
);

interface CheckBoxComponentProps {
  label: string;
  key: string;
  optionType: string;
  value: InsuranceOption;
  handleCheckBoxComponentChange: (
    key: keyof CustomerContract,
    value: string | boolean,
    intermediatryVal: keyof InsuranceOption,
  ) => void;
}

const CheckBoxComponent = (props: CheckBoxComponentProps) => {
  if (customerContractStore.customerContract === null) return null;
  let menuItems = amountOptions;
  switch (props.optionType) {
    case 'amount':
      menuItems = amountOptions;
      break;
    case 'pollution':
      menuItems = pollutionOptions;
      break;
    case 'cargo':
      menuItems = cargoOptions;
      break;
    case 'worker':
      menuItems = workerCompensationOptions;
      break;
  }

  return (
    <Grid container class="mb-4">
      <Grid sm={6}>
        <CheckboxInput
          label={props.label}
          checked={props.value.enabled}
          onChange={(val) => {
            props.handleCheckBoxComponentChange(
              props.key as keyof CustomerContract,
              val,
              'enabled',
            );
            if (!val) {
              props.handleCheckBoxComponentChange(
                props.key as keyof CustomerContract,
                '',
                'optionsValue',
              );
              props.handleCheckBoxComponentChange(
                props.key as keyof CustomerContract,
                '',
                'otherValue',
              );
            }
          }}
          disabled={isReadonly()}
        />
      </Grid>
      <Grid sm={6}>
        <Stack spacing={1}>
          <SelectField
            label=""
            renderValue={() => {
              const selectedItem = menuItems.find(
                (item: { value: string; label: string }) =>
                  item.value === String(props.value.optionsValue),
              );
              return props.value.enabled
                ? selectedItem !== undefined
                  ? selectedItem.label
                  : 'Select Amount'
                : 'Select Amount';
            }}
            placeholder="Select Amount"
            value={String(props.value.optionsValue) || ''}
            onChange={(e) => {
              props.handleCheckBoxComponentChange(
                props.key as keyof CustomerContract,
                e.target.value,
                'optionsValue',
              );
            }}
            menuItems={menuItems}
            size="small"
            disabled={!Boolean(props.value.enabled) || isReadonly()}
          />
          <div
            class={`${
              props.value.enabled
                ? props.value.optionsValue == '-1' ||
                  props.value.optionsValue == '_other'
                  ? 'block'
                  : 'hidden'
                : 'hidden'
            }`}
          >
            <TextInput
              label=""
              showInCurrencyFormat
              type="number"
              value={props.value.otherValue ?? ''}
              onChange={(val) => {
                props.handleCheckBoxComponentChange(
                  props.key as keyof CustomerContract,
                  val as string,
                  'otherValue',
                );
              }}
              maxLength={16}
              disabled={isReadonly()}
            />
          </div>
        </Stack>
      </Grid>
    </Grid>
  );
};

interface RadioComponentProps {
  label: string;
  key: string;
  value: boolean | null;
  onRadioChange: (val: string, key: keyof CustomerContract) => void;
}

const RadioComponent = (props: RadioComponentProps) => {
  return (
    <Grid container class="mb-2 items-center">
      <Grid item xs={6}>
        <Box class={cls.labelClass}>{props.label}</Box>
      </Grid>
      <Grid item xs={6} class="!flex">
        <RadioGroup
          name="radio-buttons-group"
          class="!flex-row !ml-auto"
          value={props.value}
          onChange={(e) => {
            if (!isReadonly()) {
              props.onRadioChange(
                e.target.value,
                props.key as keyof CustomerContract,
              );
            }
          }}
          defaultValue={null}
          aria-disabled={isReadonly()}
        >
          <FormControlLabel value={true} control={<Radio />} label="Yes" />
          <FormControlLabel value={false} control={<Radio />} label="No" />
          <FormControlLabel
            value={null}
            control={<Radio />}
            label="Not Specified"
          />
        </RadioGroup>
      </Grid>
    </Grid>
  );
};

interface SelectComponentProps {
  label: string;
  key: string;
  value: ContractTermOption | string;
  onlySelect?: boolean;
  handleChange: (
    value: ContractTermOption | string,
    key: keyof CustomerContract,
  ) => void;
}

const SelectComponetWithLabel = (props: SelectComponentProps) => {
  const menuItems = Boolean(props.onlySelect)
    ? claimValueBasisOptions
    : timeFrameOptions;

  const handleOnChange = (value: string) => {
    if (Boolean(props.onlySelect)) {
      props.handleChange(value, props.key as keyof CustomerContract);
    } else {
      props.handleChange(
        { optionsValue: value, otherValue: null },
        props.key as keyof CustomerContract,
      );
    }
  };
  return (
    <FormLabel>
      <Box class={`${cls.labelClass} mb-2`}>{props.label}</Box>
      <Stack>
        <SelectField
          label=""
          value={
            Boolean(props.onlySelect)
              ? (props.value as string)
              : (props.value as ContractTermOption).optionsValue ?? ''
          }
          onChange={(e) => {
            handleOnChange(e.target.value);
            if (e.target.value !== '_other') {
              props.handleChange(
                { optionsValue: e.target.value, otherValue: '' },
                props.key as keyof CustomerContract,
              );
            }
          }}
          menuItems={menuItems}
          size="small"
          sxProps={{ width: '60%' }}
          placeholder={
            Boolean(props.onlySelect) ? 'Select Value' : 'Select Timeframe'
          }
          disabled={isReadonly()}
          renderValue={() => {
            const selectedItem = menuItems.find(
              (item) =>
                (Boolean(props.onlySelect)
                  ? props.value
                  : (props.value as ContractTermOption).optionsValue) ===
                item.value,
            );
            return selectedItem !== undefined
              ? selectedItem.label
              : Boolean(props.onlySelect)
                ? 'Select Value'
                : 'Select Timeframe';
          }}
        />
        <Show
          when={
            Boolean(props.value) &&
            ((props.value as ContractTermOption).optionsValue ?? '') ===
              '_other'
          }
        >
          <TextInput
            value={(props.value as ContractTermOption).otherValue ?? ''}
            onChange={(val) => {
              props.handleChange(
                { optionsValue: '_other', otherValue: val as string },
                props.key as keyof CustomerContract,
              );
            }}
            size="small"
            sxProps={{ width: '60%' }}
            disabled={isReadonly()}
          />
        </Show>
      </Stack>
    </FormLabel>
  );
};

interface TextOnlyComponentProps {
  label: string;
  key1: keyof CustomerContract;
  key2: keyof CustomerContract;
  value1: boolean;
  value2: string;
  handleTextOnlyComponentChange: (
    key: keyof CustomerContract,
    value: boolean | string,
  ) => void;
}

const TextOnlyComponent = (props: TextOnlyComponentProps) => {
  return (
    <Grid container class="mb-4">
      <Grid sm={6}>
        <CheckboxInput
          label={props.label}
          checked={props.value1 || false}
          onChange={(val) => {
            props.handleTextOnlyComponentChange(
              props.key1 as keyof CustomerContract,
              val,
            );
            if (!val) {
              props.handleTextOnlyComponentChange(
                props.key2 as keyof CustomerContract,
                '',
              );
            }
          }}
          disabled={isReadonly()}
        />
      </Grid>
      <Grid sm={6}>
        <TextInput
          showInCurrencyFormat={props.label === 'Other'}
          size="small"
          label=""
          type="number"
          value={Boolean(props.value1) ? props.value2 : ''}
          placeholder="Enter Amount"
          onChange={(val) => {
            props.handleTextOnlyComponentChange(
              props.key2 as keyof CustomerContract,
              val as string,
            );
          }}
          maxLength={16}
          disabled={!props.value1 || isReadonly()}
        />
      </Grid>
    </Grid>
  );
};

export const Contracts = () => {
  onMount(async () => {
    const params = useParams();
    if (params.id) {
      setCustomerContract('isLoading', true);
      await getCustomerContracts(params.id);
    }
  });

  const getContractsValuesFromKey = (key: keyof CustomerContract) => {
    return customerContractStore.customerContract
      ? customerContractStore.customerContract[key]
      : null;
  };

  const handleSelectComponentChange = (
    value: ContractTermOption | string,
    key: keyof CustomerContract,
  ) => {
    setCustomerContract('customerContract', key, value);
  };

  const renderConcealedDamageOptions = () => {
    if (!customerContractStore.customerContract) return null;
    const leftSide = claimAndConcealedDamageOptions
      .slice(0, 3)
      .map((itm) => (
        <SelectComponetWithLabel
          {...itm}
          value={
            getContractsValuesFromKey(
              itm.key as keyof CustomerContract,
            ) as ContractTermOption
          }
          handleChange={handleSelectComponentChange}
        />
      ));
    const rightSide = claimAndConcealedDamageOptions
      .slice(3, 6)
      .map((itm) => (
        <SelectComponetWithLabel
          {...itm}
          value={
            getContractsValuesFromKey(
              itm.key as keyof CustomerContract,
            ) as ContractTermOption
          }
          handleChange={handleSelectComponentChange}
        />
      ));

    return (
      <Grid container spacing={2} class="!mb-3">
        <Grid item sm={6}>
          <Stack spacing={2}>{leftSide}</Stack>
        </Grid>
        <Grid item sm={6}>
          <Stack spacing={2}>{rightSide}</Stack>
        </Grid>
      </Grid>
    );
  };

  const handleRadioChange = (val: string, key: keyof CustomerContract) => {
    const value = val === 'true' ? true : val === 'false' ? false : null;
    setCustomerContract('customerContract', key, value);
  };

  const onCheckBoxComponentChange = (
    key: keyof CustomerContract,
    value: string | boolean,
    intermediatryVal: keyof InsuranceOption,
  ) => {
    setCustomerContract('customerContract', key, intermediatryVal, value);
  };

  return (
    <>
      <Box class="w-full p-3">
        <Show
          when={!customerContractStore.isLoading}
          fallback={
            <Stack spacing={2}>
              <Skeleton variant="rectangular" height={60} />
              <Skeleton variant="rectangular" height={60} />
            </Stack>
          }
        >
          <Notification type="info" text={DisclaimerText} />
          <Box class="p-2 border-[#31708f] border w-full mt-4 rounded-sm flex ">
            <FormLabel class="!pr-[50px] !border-r-2 mr-[50px]">
              <Box class={cls.labelClass}>Agreement Effective Date</Box>
              <DatePicker
                value={
                  (getContractsValuesFromKey('agreementDate') as string) || ''
                }
                handleChange={(val) => {
                  setCustomerContract('customerContract', 'agreementDate', val);
                }}
                size="small"
                disabled={isReadonly()}
              />
            </FormLabel>
            <FormLabel class="!pr-[50px] !border-r-2 mr-[50px]">
              <Box class={cls.labelClass}>Customer Entity Type</Box>
              <Stack>
                <SelectField
                  renderValue={() => {
                    const selectedItem = entities.find(
                      (item: { value: string; label: string }) =>
                        item.value ===
                        customerContractStore.customerContract
                          ?.customerEntityType.optionsValue,
                    );
                    return selectedItem !== undefined
                      ? selectedItem.label
                      : 'Select Entity Type';
                  }}
                  label=""
                  value={
                    customerContractStore.customerContract?.customerEntityType
                      .optionsValue ?? ''
                  }
                  placeholder="Select Entity Type"
                  menuItems={entities}
                  onChange={(e) => {
                    setCustomerContract(
                      'customerContract',
                      'customerEntityType',
                      'optionsValue',
                      e.target.value,
                    );
                    if (e.target.value !== '_other') {
                      setCustomerContract(
                        'customerContract',
                        'customerEntityType',
                        'otherValue',
                        '',
                      );
                    }
                  }}
                  disabled={isReadonly()}
                  size="small"
                />
                <Show
                  when={
                    customerContractStore.customerContract?.customerEntityType
                      .optionsValue === '_other'
                  }
                >
                  <TextInput
                    size="small"
                    label=""
                    value={
                      customerContractStore.customerContract?.customerEntityType
                        .otherValue ?? ''
                    }
                    onChange={(val) => {
                      setCustomerContract(
                        'customerContract',
                        'customerEntityType',
                        'otherValue',
                        val as string,
                      );
                    }}
                    disabled={isReadonly()}
                  />
                </Show>
              </Stack>
            </FormLabel>
            <Box class="!flex !flex-col !pr-[24px]">
              <Box class={cls.labelClass}>Is customer a US entity ?</Box>
              <span>
                No
                <Switch
                  checked={customerContractStore.customerContract?.isUsaEntity}
                  onChange={(_, val) => {
                    setCustomerContract('customerContract', 'isUsaEntity', val);
                  }}
                  disabled={isReadonly()}
                />
                Yes
              </span>
            </Box>
            <FormLabel>
              <Box class={cls.labelClass}>Country of Incorporation</Box>
              <SelectField
                label=""
                renderValue={() => {
                  const selectedItem = countries.find(
                    (item: { value: string; label: string }) =>
                      item.value === getContractsValuesFromKey('countryOption'),
                  );
                  return selectedItem !== undefined
                    ? selectedItem.label
                    : 'Select Country';
                }}
                value={getContractsValuesFromKey('countryOption') as string}
                menuItems={countries}
                size="small"
                placeholder="Select Country"
                onChange={(e) => {
                  setCustomerContract(
                    'customerContract',
                    'countryOption',
                    e.target.value,
                  );
                }}
                disabled={isReadonly()}
              />
            </FormLabel>
          </Box>
        </Show>
        <Grid container spacing={2} class="!mt-2">
          <Grid item sm={12} md={7} class="px-2">
            <Card
              startTitle="Insurance"
              loading={customerContractStore.isLoading}
            >
              <Box>
                <Stack spacing={1}>
                  <Box class={cls.labelClass}>Is shipper self insured?</Box>
                  <span>
                    No
                    <Switch
                      checked={
                        customerContractStore.customerContract
                          ?.isShipperSelfInsured
                      }
                      onChange={(_, val) => {
                        setCustomerContract(
                          'customerContract',
                          'isShipperSelfInsured',
                          val,
                        );
                      }}
                      disabled={isReadonly()}
                    />
                    Yes
                  </span>
                </Stack>
              </Box>
              <Grid container spacing={2}>
                <Grid item sm={6} class="my-3">
                  <Stack spacing={2}>
                    <Box>
                      <Box class={cls.labelClass}>Carrier Insurance</Box>
                      <Grid container class="items-center">
                        <Grid item sm={6}>
                          <Box class={cls.heading}>All-Risk (Required)</Box>
                        </Grid>
                        <Grid item sm={6}>
                          <span>
                            No
                            <Switch
                              checked={
                                customerContractStore.customerContract
                                  ?.carrierAllRiskEnabled
                              }
                              onChange={(_, val) => {
                                setCustomerContract(
                                  'customerContract',
                                  'carrierAllRiskEnabled',
                                  val,
                                );
                              }}
                              disabled={isReadonly()}
                            />
                            Yes
                          </span>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box displayRaw="flex">
                      <Typography
                        variant="body1"
                        component="p"
                        class={`${cls.labelClass} !flex-1`}
                      >
                        Insurance Type
                      </Typography>
                      <Typography
                        variant="body1"
                        component="p"
                        class={`${cls.labelClass} !flex-1`}
                      >
                        Amount
                      </Typography>
                    </Box>
                  </Stack>
                </Grid>
                <Grid item sm={6} class="my-3">
                  <Stack spacing={2}>
                    <Box>
                      <Box class={cls.labelClass}>Broker Insurance</Box>
                      <Grid container class="items-center">
                        <Grid item sm={6}>
                          <Box class={cls.heading}>All-Risk (Required)</Box>
                        </Grid>
                        <Grid item sm={6}>
                          <span>
                            No
                            <Switch
                              checked={
                                customerContractStore.customerContract
                                  ?.brokerAllRiskEnabled
                              }
                              onChange={(_, val) => {
                                setCustomerContract(
                                  'customerContract',
                                  'brokerAllRiskEnabled',
                                  val,
                                );
                              }}
                              disabled={isReadonly()}
                            />
                            Yes
                          </span>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box displayRaw="flex">
                      <Typography
                        variant="body1"
                        component="p"
                        class={`${cls.labelClass} !flex-1`}
                      >
                        Insurance Type
                      </Typography>
                      <Typography
                        variant="body1"
                        component="p"
                        class={`${cls.labelClass} !flex-1`}
                      >
                        Amount
                      </Typography>
                    </Box>
                  </Stack>
                </Grid>
                <Grid item sm={6}>
                  <For
                    each={
                      customerContractStore.customerContract &&
                      carrierInsuranceOptions
                    }
                  >
                    {(item) => (
                      <CheckBoxComponent
                        {...item}
                        value={
                          getContractsValuesFromKey(
                            item.key as keyof CustomerContract,
                          ) as InsuranceOption
                        }
                        handleCheckBoxComponentChange={
                          onCheckBoxComponentChange
                        }
                      />
                    )}
                  </For>
                  <TextOnlyComponent
                    key1="carrierOtherInsuranceEnabled"
                    key2="carrierOtherInsurance"
                    label="Other"
                    value1={
                      customerContractStore.customerContract
                        ?.carrierOtherInsuranceEnabled as boolean
                    }
                    value2={
                      customerContractStore.customerContract
                        ?.carrierOtherInsurance as string
                    }
                    handleTextOnlyComponentChange={(
                      key: keyof CustomerContract,
                      value: string | boolean,
                    ) => {
                      setCustomerContract('customerContract', key, value);
                    }}
                  />
                </Grid>
                <Grid item sm={6}>
                  <For
                    each={
                      customerContractStore.customerContract &&
                      brokerInsuranceOptions
                    }
                  >
                    {(item) => (
                      <CheckBoxComponent
                        {...item}
                        value={
                          getContractsValuesFromKey(
                            item.key as keyof CustomerContract,
                          ) as InsuranceOption
                        }
                        handleCheckBoxComponentChange={
                          onCheckBoxComponentChange
                        }
                      />
                    )}
                  </For>
                  <TextOnlyComponent
                    key1="generalOtherInsuranceEnabled"
                    key2="generalOtherInsurance"
                    label="Other"
                    value1={
                      customerContractStore.customerContract
                        ?.generalOtherInsuranceEnabled as boolean
                    }
                    value2={
                      customerContractStore.customerContract
                        ?.generalOtherInsurance as string
                    }
                    handleTextOnlyComponentChange={(
                      key: keyof CustomerContract,
                      value: string | boolean,
                    ) => {
                      setCustomerContract('customerContract', key, value);
                    }}
                  />
                </Grid>
              </Grid>
            </Card>
            <Card
              startTitle="Term and Payment Terms"
              loading={customerContractStore.isLoading}
            >
              <Box displayRaw="flex" flexWrap="wrap" gap={2} mb={2}>
                <Box flex={1}>
                  <Box class={cls.labelClass}>Contract Term</Box>
                  <SelectField
                    sxProps={{ width: '60%', marginTop: '10px' }}
                    label=""
                    placeholder="Select Contract Term"
                    renderValue={() => {
                      const selectedItem = contractTerms.find(
                        (item) =>
                          item.value ===
                          customerContractStore.customerContract
                            ?.contractTermTimeframe.optionsValue,
                      );
                      return selectedItem !== undefined
                        ? selectedItem.label
                        : 'Select Contract Term';
                    }}
                    menuItems={contractTerms}
                    value={
                      customerContractStore.customerContract
                        ?.contractTermTimeframe.optionsValue ?? ''
                    }
                    onChange={(e) => {
                      setCustomerContract(
                        'customerContract',
                        'contractTermTimeframe',
                        'optionsValue',
                        e.target.value,
                      );
                      if (e.target.value !== '_other') {
                        setCustomerContract(
                          'customerContract',
                          'contractTermTimeframe',
                          'otherValue',
                          '',
                        );
                      }
                    }}
                    disabled={isReadonly()}
                  />
                  <Show
                    when={
                      customerContractStore.customerContract
                        ?.contractTermTimeframe.optionsValue === '_other'
                    }
                  >
                    <TextInput
                      sxProps={{ width: '60%' }}
                      value={
                        customerContractStore.customerContract
                          ?.contractTermTimeframe.otherValue ?? ''
                      }
                      onChange={(val) => {
                        setCustomerContract(
                          'customerContract',
                          'contractTermTimeframe',
                          'otherValue',
                          val as string,
                        );
                      }}
                      size="small"
                      disabled={isReadonly()}
                    />
                  </Show>
                </Box>
                <Box flex={1}>
                  <Box class={cls.labelClass}>Payment Terms</Box>
                  <SelectField
                    placeholder="Select Timeframe"
                    sxProps={{ width: '60%', marginTop: '10px' }}
                    label=""
                    renderValue={() => {
                      const selectedItem = contractPaymentTerms.find(
                        (item) =>
                          item.value ===
                          customerContractStore.customerContract
                            ?.paymentTermOption?.optionsValue,
                      );
                      return selectedItem !== undefined
                        ? selectedItem.label
                        : 'Select Timeframe';
                    }}
                    menuItems={contractPaymentTerms}
                    value={
                      customerContractStore.customerContract?.paymentTermOption
                        ?.optionsValue ?? ''
                    }
                    onChange={(e) => {
                      setCustomerContract(
                        'customerContract',
                        'paymentTermOption',
                        'optionsValue',
                        e.target.value,
                      );
                      if (e.target.value !== '_other') {
                        setCustomerContract(
                          'customerContract',
                          'paymentTermOption',
                          'otherValue',
                          '',
                        );
                      }
                    }}
                    disabled={isReadonly()}
                  />
                  <Show
                    when={
                      customerContractStore.customerContract?.paymentTermOption
                        ?.optionsValue === '_other'
                    }
                  >
                    <TextInput
                      value={
                        customerContractStore.customerContract
                          ?.paymentTermOption?.otherValue ?? ''
                      }
                      onChange={(val) => {
                        setCustomerContract(
                          'customerContract',
                          'paymentTermOption',
                          'otherValue',
                          val as string,
                        );
                      }}
                      size="small"
                      sxProps={{ width: '60%' }}
                      disabled={isReadonly()}
                    />
                  </Show>
                </Box>
              </Box>
            </Card>
            <Card
              startTitle="Claims and Concealed Damages"
              loading={customerContractStore.isLoading}
            >
              {renderConcealedDamageOptions()}
              <For each={claimAndConcealedDamageRadioOptions}>
                {(item) => (
                  <RadioComponent
                    label={item.label}
                    value={
                      getContractsValuesFromKey(
                        item.key as keyof CustomerContract,
                      ) as boolean | null
                    }
                    key={item.key}
                    onRadioChange={handleRadioChange}
                  />
                )}
              </For>
            </Card>
            <Card
              startTitle="Additional Language"
              loading={customerContractStore.isLoading}
            >
              <For each={termAndPaymentTextAreaOptions}>
                {(item) => (
                  <Box class="!mb-3">
                    <FormLabel>
                      <span class={cls.heading}>{item.label}</span>
                      <Divider class="!mb-1" />
                      <TextAreaField
                        value={
                          (getContractsValuesFromKey(
                            item.key as keyof CustomerContract,
                          ) as string) || ''
                        }
                        onChange={(val) =>
                          setCustomerContract(
                            'customerContract',
                            item.key as keyof CustomerContract,
                            val,
                          )
                        }
                        rows={5}
                        placeholder="Add Details"
                        class="!border-[#a5a5a5]"
                        disabled={isReadonly()}
                        maxLength={4000}
                      />
                    </FormLabel>
                  </Box>
                )}
              </For>
            </Card>
          </Grid>
          <Grid item sm={12} md={5}>
            <Card
              startTitle="Temperature Controlled Commodities"
              loading={customerContractStore.isLoading}
            >
              <For each={temperatureControlledOptions}>
                {(item) => (
                  <RadioComponent
                    label={item.label}
                    value={
                      getContractsValuesFromKey(
                        item.key as keyof CustomerContract,
                      ) as boolean | null
                    }
                    key={item.key}
                    onRadioChange={handleRadioChange}
                  />
                )}
              </For>
            </Card>
            <Card
              startTitle="Other Carrier Requirements"
              loading={customerContractStore.isLoading}
            >
              <For each={otherCarrierRequirementsOptions}>
                {(item) => (
                  <RadioComponent
                    label={item.label}
                    value={
                      getContractsValuesFromKey(
                        item.key as keyof CustomerContract,
                      ) as boolean | null
                    }
                    key={item.key}
                    onRadioChange={handleRadioChange}
                  />
                )}
              </For>
            </Card>
            <Card
              startTitle="Carrier/Broker Liability"
              loading={customerContractStore.isLoading}
            >
              <For each={carrierBrokerLiabilityOptions}>
                {(item) => (
                  <RadioComponent
                    label={item.label}
                    value={
                      getContractsValuesFromKey(
                        item.key as keyof CustomerContract,
                      ) as boolean | null
                    }
                    key={item.key}
                    onRadioChange={handleRadioChange}
                  />
                )}
              </For>
            </Card>
            <Card
              startTitle="Broker Responsibilities"
              loading={customerContractStore.isLoading}
            >
              <For each={brokerResponsibilityOptions}>
                {(item) => (
                  <RadioComponent
                    label={item.label}
                    value={
                      getContractsValuesFromKey(
                        item.key as keyof CustomerContract,
                      ) as boolean | null
                    }
                    key={item.key}
                    onRadioChange={handleRadioChange}
                  />
                )}
              </For>
            </Card>
            <Card
              startTitle="Accessorial/Billing Information"
              loading={customerContractStore.isLoading}
            >
              <For each={accessorialOptions}>
                {(item) => (
                  <RadioComponent
                    label={item.label}
                    value={
                      getContractsValuesFromKey(
                        item.key as keyof CustomerContract,
                      ) as boolean | null
                    }
                    key={item.key}
                    onRadioChange={handleRadioChange}
                  />
                )}
              </For>
            </Card>
            <Card
              startTitle="Indemnification"
              loading={customerContractStore.isLoading}
            >
              <For each={indemnificationOptions}>
                {(item) => (
                  <RadioComponent
                    label={item.label}
                    value={
                      getContractsValuesFromKey(
                        item.key as keyof CustomerContract,
                      ) as boolean | null
                    }
                    key={item.key}
                    onRadioChange={handleRadioChange}
                  />
                )}
              </For>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
