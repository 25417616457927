import { Grid } from '@suid/material';
import { JSX } from 'solid-js';

export interface Props {
  title: string | JSX.Element;
  headerControls?: JSX.Element;
  customHeight?: string;
  customTitleColumns?: number;
  headerControlClasses?: string;
}

const PageHeader = (props: Props) => {
  return (
    <Grid
      container
      alignItems="center"
      backgroundColor="white"
      sx={{
        height: Boolean(props.customHeight) ? props.customHeight : '68px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
        position: 'sticky',
      }}
    >
      <Grid
        item
        md={props.customTitleColumns ?? 5}
        sm={12}
        sx={{
          overflow: 'hidden',
        }}
      >
        {props.title}
      </Grid>
      <Grid
        item
        md
        sm={12}
        sx={{
          overflow: 'hidden',
        }}
        class={props.headerControlClasses}
      >
        {props.headerControls}
      </Grid>
    </Grid>
  );
};

export default PageHeader;
