import { CategoryT } from '@common/types';
import { Add, KeyboardArrowDown, KeyboardArrowUp } from '@suid/icons-material';
import { Box, Button, Stack } from '@suid/material';
import { createSignal, For, Show } from 'solid-js';

import { CommonFileUploadFiles } from './CommonFileUpload';
import { FileCard } from './FileCard';

type PropsT = {
  files: CommonFileUploadFiles[];
  category: CategoryT;
  onDelete: (file: CommonFileUploadFiles) => void;
  onUpload: () => void;
};

export const FileCategory = (props: PropsT) => {
  const [isOpen, setIsOpen] = createSignal(true);
  const [buttonRef, setButtonRef] = createSignal<HTMLButtonElement>();

  return (
    <Box mb="10px">
      <Box
        backgroundColor="#d2e2ed"
        padding="4px 8px"
        sx={{
          borderRadius: isOpen() ? undefined : '5px',
          borderTopLeftRadius: '5px',
          borderTopRightRadius: '5px',
          cursor: 'pointer',
        }}
        onClick={(e) => {
          if (
            // @ts-expect-error It doesn't matter if the button ref is undefined.
            e.target.isEqualNode(buttonRef()) ||
            Boolean(buttonRef()?.contains(e.target))
          ) {
            return;
          }

          setIsOpen((prev) => !prev);
        }}
      >
        <Stack direction="row">
          <Box
            flex={1}
            fontSize="16px"
            fontWeight={400}
            lineHeight="28px"
            sx={{
              userSelect: 'none',
            }}
          >
            {props.category.title}
          </Box>
          <Box>
            <Button
              ref={setButtonRef}
              variant="outlined"
              size="small"
              startIcon={<Add />}
              sx={{
                backgroundColor: '#fff',
                '&:hover': {
                  backgroundColor: '#f9f9f9',
                },
              }}
              onClick={props.onUpload}
            >
              Add File
            </Button>
          </Box>
          <Box pl="10px">
            {isOpen() ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </Box>
        </Stack>
      </Box>
      <Show when={isOpen()}>
        <Box
          backgroundColor="#fff"
          sx={{
            borderBottomLeftRadius: '5px',
            borderBottomRightRadius: '5px',
          }}
        >
          <For each={props.files}>
            {(file) => {
              return (
                <FileCard
                  file={file}
                  onDelete={() => {
                    props.onDelete(file);
                  }}
                />
              );
            }}
          </For>
        </Box>
      </Show>
    </Box>
  );
};
