import { createForm } from '@felte/solid';
import { validator } from '@felte/validator-yup';
import { Button } from '@components';
import { PhoneInput, SelectField, TextInput } from '@components/forms';
import {
  shipmentPackagingTypes,
  packingGroups,
  hazardousMaterials,
} from '@common/commonLists';
import { setOrderStore } from '@store/orders';
import { LTLHazmatDetails } from '@store/ltl/types';

import cls from './classes';
import { HazardousMaterialSchema } from './validations';
interface HazmatMaterialProps {
  loadItemHazmatDetail: LTLHazmatDetails;
  onSave: (data: LTLHazmatDetails) => void;
  onClose: () => void;
  fromSidebar: boolean;
}
export const HazardousMaterial = (props: HazmatMaterialProps) => {
  const { form, errors, data, setFields, isDirty, setIsDirty } = createForm({
    initialValues: {
      loadItemId: props.loadItemHazmatDetail.loadItemId,
      unNumber: props.loadItemHazmatDetail.unNumber ?? '',
      packingGroup: props.loadItemHazmatDetail.packingGroup,
      hazardClass: props.loadItemHazmatDetail.hazardClass,
      piece: props.loadItemHazmatDetail.piece,
      contactNumber: props.loadItemHazmatDetail.contactNumber,
      contactName: props.loadItemHazmatDetail.contactName ?? '',
    },
    extend: validator({ schema: HazardousMaterialSchema }),
    onSubmit: () => {
      if (Boolean(props.fromSidebar)) {
        props.onSave(data());
      } else {
        setOrderStore('isStopsEdited', isDirty());
        props.onSave({
          ...data(),
        });
      }
    },
  });

  const setStopsFields = (
    field:
      | 'unNumber'
      | 'packingGroup'
      | 'hazardClass'
      | 'piece'
      | 'contactNumber'
      | 'contactName',
    value: string,
  ) => {
    setIsDirty(true);
    setFields(field, value);
  };

  return (
    <form ref={form} class={cls.popoverClass}>
      <span class={`${cls.modalHeadingLeftAlign}`}>
        Edit Hazardous Material
      </span>
      <div class="p-4">
        <TextInput
          name="unNumber"
          label={
            <span>
              UN Number<sup>*</sup>
            </span>
          }
          placeholder="Enter UN Number*"
          maxLength={7}
          sxProps={{ ...cls.wFull, ...cls.mb16 }}
          value={data().unNumber}
          onChange={(itm) => setStopsFields('unNumber', itm as string)}
          error={errors().unNumber}
        />
        <SelectField
          name="packingGroup"
          label="Packing Group"
          menuItems={packingGroups}
          value={data().packingGroup}
          sxProps={cls.mb16}
          onChange={(e) => setStopsFields('packingGroup', e.target.value)}
          placeholder="Select Packing Group"
        />
        <SelectField
          name="hazardClass"
          label="Hazard Class"
          menuItems={hazardousMaterials}
          value={data().hazardClass}
          sxProps={cls.mb16}
          onChange={(e) => setStopsFields('hazardClass', e.target.value)}
          placeholder="Select Hazard Class"
        />
        <SelectField
          name="piece"
          label="Piece Description"
          menuItems={shipmentPackagingTypes}
          value={data().piece}
          sxProps={cls.mb16}
          onChange={(e) => setStopsFields('piece', e.target.value)}
          placeholder="Select Piece Description"
        />
        <PhoneInput
          label={
            <span>
              Emergency Contact Number<sup>*</sup>
            </span>
          }
          placeholder="Emergency Contact Number*"
          error={errors().contactNumber}
          value={data().contactNumber}
          sxProps={{ marginBottom: '16px' }}
          onChange={(phone) => setStopsFields('contactNumber', phone)}
        />
        <TextInput
          name="contactName"
          label={
            <span>
              Emergency Contact Name<sup>*</sup>
            </span>
          }
          placeholder=""
          maxLength={50}
          sxProps={{ ...cls.wFull, ...cls.mb16 }}
          value={data().contactName}
          onChange={(name) => setStopsFields('contactName', name as string)}
          error={errors().contactName}
        />
        <div class="flex justify-end">
          <Button label="Save" type="submit" sx={{ margin: '0 4px 0 0 ' }} />
          <Button
            label="Cancel"
            sx={{ marginBottom: '0 0 0 4px' }}
            variant="text"
            onClick={props.onClose}
          />
        </div>
      </div>
    </form>
  );
};
