import { ColDef, ITooltipParams } from '@ag-grid-community/core';
import { createSignal } from 'solid-js';
import { render } from 'solid-js/web';
import { FactoringCompany } from '@store/factoringCompany';
import { DateTime } from 'luxon';

import {
  FactoringCompanyContact,
  FactoringCompanyStatus,
} from './FactoringCompanyColumns';

const cellStyle = {
  padding: '10px',
  'word-break': 'break-word',
  'white-space': 'normal',
  'line-height': '1.6 ',
};

const filterParams = {
  filterOptions: [
    'equals',
    'notEqual',
    'contains',
    'startsWith',
    'endsWith',
    'notContains',
    'blank',
    'notBlank',
  ],
  buttons: ['apply', 'reset'],
  suppressAndOrCondition: true,
};

const toolTipVal: { [key: string]: string } = {
  Active: 'Active',
  Inactive: 'Inactive',
  Pending: 'Pending',
  'Out Of Business': 'Out Of Business',
  Incomplete: 'Incomplete',
  Conditional: 'Conditional',
  'Expired Insurance': 'Expired Insurance',
  'Out of Business': 'Out of Business',
};

export const factoringCompanyColumnDefs: ColDef[] = [
  {
    field: 'status',
    headerName: 'Active',
    cellStyle: cellStyle,
    tooltipValueGetter: (p: ITooltipParams): string =>
      toolTipVal[p.value as string],
    cellRenderer: (params: { data: FactoringCompany }) => {
      const container = document.createElement('div');
      const [Component] = createSignal(FactoringCompanyStatus(params.data));
      render(() => <Component />, container);
      return container;
    },
    filter: false,
    minWidth: 100,
    maxWidth: 120,
  },
  {
    field: 'name',
    headerName: 'Name',
    filter: 'agTextColumnFilter',
    cellStyle: cellStyle,
    filterParams,
    autoHeight: true,
    minWidth: 280,
  },
  {
    field: 'mailingCity',
    headerName: 'City',
    filter: 'agTextColumnFilter',
    cellStyle: cellStyle,
    filterParams,
    minWidth: 120,
  },

  {
    field: 'mailingState',
    headerName: 'State',
    filter: 'agTextColumnFilter',
    cellStyle: cellStyle,
    filterParams,
    minWidth: 60,
    maxWidth: 120,
  },
  {
    field: 'primaryContactName',
    headerName: 'Contact',
    filter: false,
    cellStyle: cellStyle,
    filterParams,
    cellRenderer: (params: { data: FactoringCompany }) => {
      const container = document.createElement('div');
      const [Component] = createSignal(FactoringCompanyContact(params.data));
      render(() => <Component />, container);
      return container;
    },
    minWidth: 150,
  },
  {
    field: 'numberOfCarriers',
    headerName: 'Carriers',
    filter: false,
    cellStyle: cellStyle,
    minWidth: 80,
  },
  {
    field: 'modifiedDate',
    headerName: 'Edited',
    cellStyle: cellStyle,
    minWidth: 160,
    filter: 'agDateColumnFilter',
    filterParams,
    valueFormatter: (params) => {
      const date = DateTime.fromISO(params.value as string);
      if (date.isValid) {
        return `${date.setZone('local').toFormat('MM/dd/yyyy h:mm a')}`;
      }
      return '';
    },
  },
];
