import { Typography } from '@components/Typography';
import { Box } from '@suid/material';
import { JSXElement } from 'solid-js';

type TabProps = {
  children?: JSXElement | string;
  onClick?: (index: number) => void;
  size?: 'small' | 'medium';
  customStyle?: string;
};

export const Tab = (props: TabProps) => {
  const tabItemStyle = {
    minWidth: '100%',
    whiteSpace: 'nowrap',
    color: '#0E2C3B',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: props.size === 'small' ? '13px' : '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: props.size === 'small' ? '14px' : '20px',
    letterSpacing: '0.4px',
    textTransform: 'uppercase',
  };

  return (
    <Box class=" flex items-center gap-2" onClick={() => props.onClick}>
      <Box class="flex flex-col items-center">
        <Typography
          component="p"
          variant={props.size === 'small' ? 'body2' : 'body1'}
          sxProps={tabItemStyle}
          class={props.customStyle}
        >
          {props.children}
        </Typography>
      </Box>
    </Box>
  );
};
